import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  listItemHighlight: {
    opacity: "1 !important",
    background: theme.palette.events.bluePrimary,
    "&:hover,&:focus": {
      background: `${theme.palette.events.bluePrimary} !important`,
    },
  },
  listItemHighlightText: {
    fontWeight: 700,
  },
  leftMenuItemDisabled: {
    background: theme.palette.events.blueAccent2,
    color: theme.palette.events.grayTertiary,
    "&:hover,&:focus": {
      background: `${theme.palette.events.blueAccent2} !important`,
    },
  },
}));
