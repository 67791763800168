import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

interface Props {
  isInBroadcast: boolean | null;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  sectionTitle: ({ isInBroadcast }) => ({
    margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
    color: isInBroadcast
      ? theme.palette.white
      : `${theme.palette.events.blueSecondary} !important`,
    opacity: 0.9,
    "&:firstChild": {
      marginTop: 0,
    },
  }),
}));

export default useStyles;
