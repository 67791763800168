import { ReactElement, useState } from "react";
import { useI18n } from "i18n";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { EventPollOption } from "graphql/generated";
import { useStyles } from "./styles";
import Option from "./Option";

interface Props {
  options: EventPollOption[];
  onSubmit: (selectedAnswer?: EventPollOption) => void;
}

const Options = ({ onSubmit, options }: Props): ReactElement => {
  const [selectedOption, setSelectedOption] = useState<
    EventPollOption | undefined
  >(undefined);
  const classes = useStyles();
  const { t } = useI18n(["auth"]);

  return (
    <>
      <div className={classes.optionsDiv}>
        {options.map((option) => (
          <Option
            key={option.option}
            active={selectedOption?.id === option.id}
            onClick={() => setSelectedOption(option)}
          >
            {option.option}
          </Option>
        ))}
      </div>
      <Button
        type="submit"
        size="sm"
        color="blue"
        className={classes.mainButton}
        disabled={!selectedOption}
        onClick={() => {
          if (selectedOption) {
            onSubmit(selectedOption);
          }
        }}
      >
        {t("auth:submit")}
      </Button>
    </>
  );
};

export default Options;
