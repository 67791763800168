import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { Theme } from "@remo-co/ui-core/src/types";

export const useStyles = makeStyles<Theme>((theme) => ({
  hourGlassEmptyIcon: {
    color: theme.palette.gray.disabled,
  },
  blockIcon: {
    color: theme.palette.error.main,
  },
  autoRenewIcon: {
    color: theme.palette.events.bluePrimary,
  },
  highlightOffIcon: {
    color: theme.palette.error.main,
  },
  checkCircleOutline: {
    color: theme.palette.events.successDark,
  },
  draftsIcon: {
    color: theme.palette.events.successDark,
  },
  importContainer: {
    marginTop: "32px",
  },
  buttonWrapper: {
    textTransform: "none",
  },
  dialogTitle: {
    background: theme.palette.events.grayPrimary,
    marginBottom: "20px",
  },
}));
