import { useContext, useMemo, useState } from "react";
import { useI18n } from "i18n";
import { MANAGE_EVENT_CONTEXT, useAttendeeList } from "modules/manageEvent";
import { getMapTemplateByType } from "modules/event/template";
import useFloorsUtils from "modules/manageEvent/hooks/useFloorsUtils";
import { ITheater, IMapTemplate } from "types/theater";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { useFloorNames } from "modules/floors/hooks/useFloorNames";
import { useEventTableSettingPresets } from "modules/assignTables/hooks/useEventTableSettingPresets";
import { computeFloorSeatingInfo, SeatingInfo } from "../../utils";
import { useEventTableSettings } from "../../hooks/useEventTableSettings";
import { FloorInfoCard } from "../FloorInfoCard";
import { TableManagerDialog } from "../TableManagerDialog";
import useStyles from "./styles";

interface Props {
  eventId?: string;
  eventName?: string;
  isEventPublished: boolean;
  theaters?: ITheater[];
}

type FloorSeatingInfo = Record<string, SeatingInfo | number> & {
  totalSeatedUsers: number;
};

const AssignTablesTab = ({
  eventId,
  eventName,
  isEventPublished,
  theaters = [],
}: Props): JSX.Element => {
  const { state } = useContext(MANAGE_EVENT_CONTEXT);
  const { eventData } = state ?? {};
  const floorNames = useFloorNames(eventData?.theaters?.[0]?._id ?? null);
  const { tableSettings, assignedAttendees } = useEventTableSettings(eventId);
  const { presets } = useEventTableSettingPresets(eventId);
  const attendeesQuery = useAttendeeList(eventId);
  const { t } = useI18n(["manageEvent"]);
  const styles = useStyles();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedFloorId, setFloorId] = useState<string | null>(null);

  const { spaces = [], capacity, template, theme } = theaters[0] || {};
  const { invitedAttendees } = attendeesQuery;

  const floorSeatingInfo = useMemo(
    () =>
      spaces.reduce(
        (seatingInfo, current) => {
          const result = computeFloorSeatingInfo(tableSettings, current);

          return {
            ...seatingInfo,
            [current]: result,
            totalSeatedUsers: seatingInfo.totalSeatedUsers + result.totalUsers,
          };
        },
        { totalSeatedUsers: 0 },
      ) as FloorSeatingInfo,
    [tableSettings, spaces],
  );
  const totalUnassignedSeats = useMemo(
    () => Math.max(invitedAttendees.length - assignedAttendees.length, 0),
    [invitedAttendees, assignedAttendees],
  );

  const eventTemplate = getMapTemplateByType(template);
  const { noOfFloors } = useFloorsUtils(capacity, eventTemplate);
  const unsavedCapacityChanges = noOfFloors !== spaces.length;

  const openManageDialog = (floorId: string) => {
    setFloorId(floorId);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setFloorId(null);
  };

  return (
    <>
      <Typography variant="h2">{t("manage.attendees")}</Typography>
      <Typography variant="body2" className={styles.description}>
        {t("land.your.guests")}
      </Typography>
      {unsavedCapacityChanges && (
        <Container flex alignItems="center" className={styles.promptToSave}>
          <Info />
          <Typography variant="body2" className={styles.message}>
            {t("assign.tables.prompt.to.save")}
          </Typography>
        </Container>
      )}
      {!isEventPublished ? (
        <Container flex alignItems="center" className={styles.disabled}>
          <Info />
          <Typography variant="body2" className={styles.message}>
            {t("assign.tables.disabled")}
          </Typography>
        </Container>
      ) : (
        <>
          <Container flex alignItems="center" justifyContent="space-between">
            <Typography className={styles.unassignedCount} variant="body2">
              {t("number.guests.unassigned", {
                count: totalUnassignedSeats,
              })}
            </Typography>
            {presets && presets.length > 0 && (
              <Typography className={styles.unassignedCount} variant="body2">
                {t("manageEvent:in.event.presets.assigned", {
                  number: presets.length,
                })}
              </Typography>
            )}
          </Container>
          {spaces?.map((floorId, index) => (
            <FloorInfoCard
              key={floorId}
              floor={index + 1}
              name={floorNames[floorId]}
              seatingInfo={floorSeatingInfo[floorId] as SeatingInfo}
              disabled={unsavedCapacityChanges}
              onClick={() => {
                openManageDialog(floorId);
              }}
            />
          ))}
        </>
      )}
      {isDialogOpen && eventId && (
        <TableManagerDialog
          eventName={eventName}
          eventId={eventId}
          floors={spaces}
          floorNames={floorNames}
          template={template as IMapTemplate}
          theme={theme}
          preselectedFloorId={selectedFloorId}
          onClose={handleDialogClose}
        />
      )}
    </>
  );
};

export default AssignTablesTab;
