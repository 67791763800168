import { ChangeEvent, useMemo } from "react";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { useI18n } from "i18n";
import {
  useEventTableSettings,
  LockedAttendeeInputPopover,
} from "modules/assignTables";
import { useEventContentSubscription } from "modules/eventContent/hooks/useEventContentSubscription";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useStyles } from "./styles";

interface Props {
  eventId?: string;
  isExpectedAttendanceDisabled: boolean;
  handleCapacity: (e: ChangeEvent<HTMLInputElement>) => void;
  validateChanges: () => void;
  capacity: {
    value: number;
  };
  maxCapacity: number;
  isUnlimitedEvent?: boolean;
}

const ExpectedAttendees = ({
  eventId,
  isExpectedAttendanceDisabled,
  handleCapacity,
  validateChanges,
  capacity,
  maxCapacity,
  isUnlimitedEvent,
}: Props): JSX.Element => {
  const { t } = useI18n(["eventForm"]);
  const { tableSettings } = useEventTableSettings(eventId);
  const { data } = useEventContentSubscription({ eventId });
  const styles = useStyles();

  const { content: contents = [] } =
    data && data?.eventContent.__typename !== "GQLError"
      ? data.eventContent
      : {};
  const hasFloorContent = contents.length > 0;
  const hasAssignedTables = useMemo(
    () => tableSettings.some((setting) => setting.users.length > 0),
    [tableSettings],
  );
  const isInputDisabled =
    hasAssignedTables ||
    hasFloorContent ||
    isExpectedAttendanceDisabled ||
    isUnlimitedEvent;

  return (
    <>
      <Typography variant="h5">{t("eventForm:attendance.expected")}</Typography>
      <Input
        name="eventCapacity"
        size="sm"
        id="et-attendance-input"
        disabled={isInputDisabled}
        description={
          maxCapacity !== -1 && !isUnlimitedEvent
            ? t("eventForm:max.guest.validation", {
                key: `${maxCapacity}`,
              })
            : ""
        }
        value={capacity.value === 0 ? undefined : capacity.value}
        onChange={handleCapacity}
        onBlur={validateChanges}
        inputEndAdornment={
          <LockedAttendeeInputPopover
            hasAssignedTables={hasAssignedTables}
            hasFloorContent={hasFloorContent}
          />
        }
        className={styles.inputField}
        inputProps={{
          max: maxCapacity,
          type: "number",
          min: 1,
          "data-testid": "expected-attendee-input",
        }}
        theme="light"
      />
    </>
  );
};

export default ExpectedAttendees;
