import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  wrap: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 16,
    alignItems: "center",
  },
  header: { marginBottom: theme.spacing(2) },
  textContainer: {
    maxWidth: "64%",
  },
}));
