import type { LiveStreams } from "services/firebaseService/liveStream";
import ActionTypes from "./constants";
import { IBroadcastInfo } from "../types";

type UpdateVideoURLAction = {
  type: ActionTypes.UPDATE_VIDEO_URL;
  payload: {
    videoURL: string | undefined | null;
  };
};

const updateVideoURL = (
  videoURL: string | undefined | null,
): UpdateVideoURLAction => ({
  type: ActionTypes.UPDATE_VIDEO_URL,
  payload: { videoURL },
});

type UpdateVideoShareTimeAction = {
  type: ActionTypes.UPDATE_VIDEO_SHARE_TIME;
  payload: {
    videoShareTime: number | undefined;
  };
};

const updateVideoShareTime = (
  videoShareTime: number | undefined,
): UpdateVideoShareTimeAction => ({
  type: ActionTypes.UPDATE_VIDEO_SHARE_TIME,
  payload: { videoShareTime },
});

type UpdateIsVideoControlsEnabledAction = {
  type: ActionTypes.UPDATE_IS_VIDEO_CONTROLS_ENABLED;
  payload: {
    isVideoControlsEnabled?: boolean;
  };
};

const updateIsVideoControlsEnabled = (
  isVideoControlsEnabled?: boolean,
): UpdateIsVideoControlsEnabledAction => ({
  type: ActionTypes.UPDATE_IS_VIDEO_CONTROLS_ENABLED,
  payload: { isVideoControlsEnabled },
});

export interface IStartBroadcastParams {
  delay: number;
  startedBy: string;
  startedAt: number;
  isTurnOnMicCamWhenStart: boolean;
  streams?: LiveStreams[];
  recordOnEntering?: boolean;
}

type StartBroadcastAction = {
  payload: IStartBroadcastParams;
  type: ActionTypes.START_BROADCAST;
};

const startBroadcast = (data: IStartBroadcastParams) => ({
  type: ActionTypes.START_BROADCAST,
  payload: data,
});

type StopBroadcastAction = {
  type: ActionTypes.STOP_BROADCAST;
};

const stopBroadcast = (): StopBroadcastAction => ({
  type: ActionTypes.STOP_BROADCAST,
});

type UpdateBroadcastInfoAction = {
  type: ActionTypes.UPDATE_BROADCAST_INFO;
  payload?: IBroadcastInfo;
};

const updateBroadcastInfo = (
  broadcastInfo?: IBroadcastInfo,
): UpdateBroadcastInfoAction => ({
  type: ActionTypes.UPDATE_BROADCAST_INFO,
  payload: broadcastInfo,
});

type UpdateBroadcastDelayAction = {
  type: ActionTypes.UPDATE_BROADCAST_DELAY;
  payload: {
    delay?: number;
  };
};

const updateBroadcastDelay = (delay?: number): UpdateBroadcastDelayAction => ({
  type: ActionTypes.UPDATE_BROADCAST_DELAY,
  payload: { delay },
});

type EnterBroadcastAction = {
  type: ActionTypes.ENTER_BROADCAST;
};

const enterBroadcast = (): EnterBroadcastAction => ({
  type: ActionTypes.ENTER_BROADCAST,
});

type LeaveBroadcastAction = {
  type: ActionTypes.LEAVE_BROADCAST;
};

const leaveBroadcast = (): LeaveBroadcastAction => ({
  type: ActionTypes.LEAVE_BROADCAST,
});

type BroadcastTransitionStartedAction = {
  type: ActionTypes.BROADCAST_TRANSITION_STARTED;
};

const broadcastTransitionStarted = (): BroadcastTransitionStartedAction => ({
  type: ActionTypes.BROADCAST_TRANSITION_STARTED,
});

type BroadcastTransitionCompleteAction = {
  type: ActionTypes.BROADCAST_TRANSITION_COMPLETE;
};

const broadcastTransitionComplete = (): BroadcastTransitionCompleteAction => ({
  type: ActionTypes.BROADCAST_TRANSITION_COMPLETE,
});

type ResetAction = {
  type: ActionTypes.RESET;
};

const reset = (): ResetAction => ({
  type: ActionTypes.RESET,
});

export type BroadcastAction =
  | UpdateVideoURLAction
  | UpdateVideoShareTimeAction
  | UpdateIsVideoControlsEnabledAction
  | StartBroadcastAction
  | StopBroadcastAction
  | UpdateBroadcastInfoAction
  | UpdateBroadcastDelayAction
  | EnterBroadcastAction
  | LeaveBroadcastAction
  | BroadcastTransitionStartedAction
  | BroadcastTransitionCompleteAction
  | ResetAction;

export default {
  updateVideoURL,
  updateVideoShareTime,
  updateIsVideoControlsEnabled,
  updateBroadcastInfo,
  updateBroadcastDelay,
  startBroadcast,
  stopBroadcast,
  enterBroadcast,
  leaveBroadcast,
  reset,
  broadcastTransitionStarted,
  broadcastTransitionComplete,
};
