import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  title: {
    textTransform: "capitalize",
  },
  options: {
    paddingLeft: theme.spacing(1),
  },
  option: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  icons: {
    color: theme.palette.gray.disabled,
  },
  deleteIcon: {
    color: theme.palette.events.lightBlack,
  },
}));
