import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  uploaderContainer: {
    color: theme.palette.events.blueSecondary,
    "& .filepond--file-action-button": {
      pointerEvents: "all",
      cursor: "pointer",
    },
  },
  button: {
    height: "auto",
    minHeight: "40px",
  },
  buttonContent: {
    width: "100%",
    position: "relative",
    display: "block",
    padding: "0",
    textTransform: "none",
  },
}));
