import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  dialogContent: {
    padding: "16px !important",
  },
  previewPaper: {
    maxHeight: `calc(100% - 5rem)`, // leave 5rem space for footer
  },
  fpDeleteButton: {
    height: "29px",
  },
}));
