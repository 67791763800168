import { Variables } from "../vars";

export const productionVars: Partial<Record<Variables, string>> = {
  REACT_APP_EDC_AUTHORIZE_URL:
    "https://reg-apisvc.prod.events.com/v2/authorize?response_type=code&client_id=remo-sso&redirect_uri=https%3A%2F%2Flive.remo.co%2Fapi%2Fv1%2Foauth%2Fedc%2Fcallback&scope=openid%20profile%20email%20refreshToken",
  REACT_APP_ANALYTICS_DASHBOARD_URL:
    "https://app.datagol.ai/app/da6f79bf-34b1-4262-93cc-776b341b95fb/page/779a230f-25a2-40b6-b147-ccdbd3086ed1",
  REACT_APP_CLIENT_ORIGINS: "https://live.remo.co,https://virtual.events.com",
  REACT_APP_API_HOST: "https://live.remo.co/api/internal",
  REACT_APP_ASSETS_URL: "https://remo-conference.appspot.com/assets/",
  REACT_APP_AWS_REGION: "us-east-1",
  REACT_APP_CHARGEBEE_SITE: "remoapp",
  REACT_APP_DAILY_API_URL: "https://remo-test.daily.co",
  REACT_APP_DATADOG_CLIENT_TOKEN: "pube1099a2f32a5af9dd1f4d1d204ef53d6",
  REACT_APP_EXIT_PAGE: "https://hi.remo.co/event-exit-page",
  REACT_APP_FIREBASE_API_KEY: "AIzaSyDqtwJQN1lyJsz0Zf2mYDuIhQsRSS1FAqI",
  REACT_APP_FIREBASE_AUTH_DOMAIN: "live.remo.co",
  REACT_APP_FIREBASE_DB_URL: "https://remo-conference.firebaseio.com",
  REACT_APP_FIREBASE_ID: "690398098222",
  REACT_APP_FIREBASE_PROJECT_ID: "remo-conference",
  REACT_APP_FIREBASE_STORAGE_BUCKET: "remo-conference.appspot.com",
  REACT_APP_FRESH_CHAT_HOST: "https://wchat.freshchat.com",
  REACT_APP_FRESH_CHAT_TOKEN: "5fb1046b-e419-4ecb-85fb-2da818cdcae3",
  REACT_APP_GCLOUD_PROJECT_NAME: "remo-conference",
  REACT_APP_GIPHY_SDK_KEY: "DWNqNX0WWZOLTveCkokGdV7y6wqjrlIW",
  REACT_APP_GRAPHQL_HTTP_ENDPOINT: "https://live.remo.co/api/graphql",
  REACT_APP_GRAPHQL_WS_ENDPOINT: "wss://live.remo.co/api/graphql",
  REACT_APP_HEADWAY_ACCOUNT: "JmOXVx",
  REACT_APP_MIRO_CLIENT_ID: "3074457350118269469",
  REACT_APP_PRICING_PAGE_URL: "https://hi.remo.co/live-pricing-page",
  REACT_APP_REMO_CNAME_HOSTS: "custom.remo.co,domain.remo.co",
  REACT_APP_REMO_HOST: "live.remo.co",
  REACT_APP_SEGMENT_WRITE_KEY: "ry3RKvIGRVFa7lEYOpHOhPUGnYEwHEex",
  REACT_APP_SPLIT_IO_API_KEY: "gmvb8jn3tl9bdb8oivoao3q7jtl7cbdk5ior",
  REACT_APP_USERPILOT_APP_TOKEN: "5ix90o6",
  REACT_APP_VPN_DETECTION_API_KEY: "86ff1879952e42c69419ed0e512fc874",
};
