import { useMemo, memo, forwardRef, ComponentProps } from "react";
import { useI18n } from "i18n";
import { getUserPic } from "helpers/appHelper";
import { getGooglePicBySize } from "helpers/profileHelper";
import { Avatar as BaseAvatar } from "modules/avatar";
import { getUserName } from "modules/userProfile";
import { IUser } from "modules/app/types";
import { useSelector } from "react-redux";
import { selectUserConversationEmoji } from "modules/emoji/redux";

interface Props
  extends Pick<
    ComponentProps<typeof BaseAvatar>,
    | "size"
    | "onClick"
    | "className"
    | "style"
    | "isAdmin"
    | "hasMicCamIssues"
    | "alt"
    | "color"
    | "id"
    | "data-automationid"
    | "data-testid"
  > {
  user?: IUser;
  fallbackName?: string;
  fallbackBackgroundColor?: string;
  isCompanyBadgeEdit?: boolean;
  showEmoji?: boolean;
  isCompanyBadgeVisible?: boolean;
}

const Avatar = forwardRef<HTMLElement, Props>(
  (
    {
      user,
      fallbackName,
      isCompanyBadgeEdit,
      isCompanyBadgeVisible,
      showEmoji = false,
      ...props
    },
    ref,
  ) => {
    const { t } = useI18n(["micCamCheck"]);
    const emoji = useSelector(selectUserConversationEmoji(user?.id));

    const isRemoEmployee = useMemo(() => {
      if (!user?.email) {
        return false;
      }

      return ["@riotlysocial.com", "@remo.co"].some((domain) =>
        user.email.endsWith(domain),
      );
    }, [user]);

    const pictureUrl = useMemo(() => {
      if (!user) {
        return undefined;
      }

      const picture = getUserPic(user);

      if (picture === "default") return undefined;

      return picture ? getGooglePicBySize(picture, 160) : undefined;
    }, [user]);

    const userName = useMemo(
      () => getUserName(user, fallbackName),
      [user, fallbackName],
    );

    return (
      <BaseAvatar
        ref={ref}
        {...props}
        name={userName}
        pictureUrl={pictureUrl}
        isRemoEmployee={isRemoEmployee}
        micAndCamIssueLabel={t("micCamCheck:unconnected.camera.mic")}
        remoEmployeeLabel={t("micCamCheck:remo.employee")}
        user={user}
        isCompanyBadgeEdit={isCompanyBadgeEdit}
        isCompanyBadgeVisible={isCompanyBadgeVisible}
        emoji={emoji}
        showEmoji={showEmoji}
      />
    );
  },
);

export default memo(Avatar);
