import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import color from "color";

export const useStyles = makeStyles<
  Theme,
  {
    darkMode: boolean;
    isActive: boolean;
    disabled?: boolean;
    height?: number;
    hasBadgeContent?: number;
  }
>((theme) => ({
  container: {
    borderRadius: 0,
    display: "flex",
    margin: `${theme.spacing(1)} ${theme.spacing(1.25)}`,
    [theme.breakpoints.up("sm")]: {
      margin: `0 ${theme.spacing(1.25)}`,
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    pointerEvents: ({ disabled }) => (disabled ? "none" : "auto"),
  },
  button: {
    width: "46px",
    minWidth: "46px",
    height: "46px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  label: {
    display: "none",
    cursor: "pointer",
    marginTop: "4px",
    textAlign: "center",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("sm")]: {
      display: "unset",
    },
  },
  defaultState: {
    color: ({ isActive, disabled, darkMode, hasBadgeContent }) => {
      if (disabled) return theme.palette.events.grayTertiary;

      if (darkMode && isActive) return theme.palette.green.actionDarkMode;

      if (isActive || hasBadgeContent) return theme.palette.events.bluePrimary;

      if (darkMode) return color(theme.palette.white).fade(0.1).string();

      return theme.palette.events.textPrimary;
    },
    backgroundColor: ({ isActive, disabled, darkMode, hasBadgeContent }) => {
      if (darkMode) {
        if (disabled) return theme.palette.gray.light;

        if (isActive) return theme.palette.green.background;

        return color(theme.palette.white).fade(0.95).string();
      }

      if (isActive || hasBadgeContent) return theme.palette.events.blueAccent4;

      return theme.palette.events.grayPrimary;
    },

    "&:hover,&:focus": {
      backgroundColor: ({ darkMode, isActive }) => {
        if (darkMode && isActive) return "#255A3D";

        if (darkMode) return color(theme.palette.white).fade(0.8).string();

        return theme.palette.events.blueAccent4;
      },
    },
    "&:hover": {
      color: ({ isActive, disabled, darkMode }) => {
        if (disabled) return theme.palette.events.grayTertiary;

        if (darkMode && isActive) return theme.palette.green.actionDarkMode;

        if (isActive) return theme.palette.events.bluePrimary;

        if (darkMode) return color(theme.palette.white).fade(0.1).string();

        return theme.palette.events.textPrimary;
      },
    },
    "&:active": {
      color: ({ darkMode }) => {
        if (darkMode) return theme.palette.green.actionDarkMode;

        return theme.palette.events.bluePrimary;
      },
      backgroundColor: ({ darkMode }) =>
        darkMode
          ? theme.palette.green.hoverDarkMode
          : theme.palette.events.blueAccent4,
    },
  },
  darkModeLabel: {
    color: color(theme.palette.white).fade(0.1).string(),
  },
  activeState: {
    color: ({ darkMode }) =>
      darkMode
        ? theme.palette.green.actionDarkMode
        : theme.palette.events.successDark,
    backgroundColor: ({ darkMode }) =>
      darkMode ? theme.palette.green.background : "#D3FFD6",
    "&:hover,&:active,&:focus": {
      color: ({ darkMode }) =>
        darkMode
          ? theme.palette.green.actionDarkMode
          : theme.palette.green.action,
      backgroundColor: ({ darkMode }) =>
        darkMode ? theme.palette.green.hoverDarkMode : "#C0FFC3",
    },
  },
  warningState: {
    color: theme.palette.red.main,
    backgroundColor: ({ darkMode }) =>
      darkMode
        ? color(theme.palette.white).fade(0.95).string()
        : theme.palette.events.grayPrimary,
    "&:hover,&:active": {
      color: theme.palette.red.main,
      backgroundColor: ({ darkMode }) =>
        darkMode
          ? color(theme.palette.white).fade(0.8).string()
          : theme.palette.events.blueAccent4,
    },
  },
  exitState: {
    color: theme.palette.error.dark,
    backgroundColor: ({ darkMode }) =>
      darkMode
        ? color(theme.palette.white).fade(0.95).string()
        : theme.palette.events.errorLight,
    "&:hover,&:active": {
      color: theme.palette.red.main,
      backgroundColor: ({ darkMode }) =>
        darkMode
          ? color(theme.palette.white).fade(0.8).string()
          : theme.palette.events.blueAccent4,
    },
  },
  content: {
    padding: 0,
    alignItems: "center",
  },
  popoverButton: {
    height: "28px",
    width: "20px",
    minWidth: "20px",
    marginLeft: "4px",
    marginTop: "9px",

    color: ({ darkMode }) =>
      darkMode
        ? color(theme.palette.white).fade(0.1).string()
        : theme.palette.blue.dark,

    "&:hover,&:focus": {
      backgroundColor: ({ darkMode }) =>
        darkMode
          ? color(theme.palette.white).fade(0.95).string()
          : theme.palette.blue.lightShade1,
    },
  },

  smallButton: {
    height: ({ height }) => `${height || 46}px`,
    borderRadius: 0,
    display: "flex",
    alignItems: "center",
    padding: "15px 10px",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "19px",
    justifyContent: "flex-start",
    outline: "none",
    pointerEvents: ({ disabled }) => (disabled ? "none" : undefined),
    border: "none",
    color: ({ isActive, disabled, darkMode }) => {
      if (disabled) return theme.palette.gray.disabled;

      if (darkMode && isActive) return theme.palette.green.actionDarkMode;

      if (isActive) return theme.palette.blue.main;

      if (darkMode) return color(theme.palette.white).fade(0.1).string();

      return theme.palette.blue.dark;
    },
    backgroundColor: ({ isActive, darkMode }) => {
      if (darkMode) return "transparent";

      if (darkMode && isActive) return theme.palette.green.background;

      if (isActive) return theme.palette.blue.lightShade1;

      return theme.palette.white;
    },
    "&:hover,&:focus": {
      backgroundColor: ({ darkMode }) =>
        darkMode
          ? `${theme.palette.events.whiteTertiary}33`
          : theme.palette.events.blueAccent4,
      color: ({ isActive, darkMode }) =>
        isActive && darkMode
          ? theme.palette.green.actionDarkMode
          : theme.palette.events.textPrimary,
    },

    "&:focus-visible": {
      outlineOffset: "0px",
      margin: "2px",
      boxShadow: "none",
    },
    "&:active": {
      color: ({ darkMode }) =>
        darkMode ? theme.palette.green.actionDarkMode : theme.palette.blue.main,
      backgroundColor: ({ darkMode }) =>
        darkMode
          ? color(theme.palette.white).fade(0.95).string()
          : theme.palette.blue.lightShade1,
    },
  },
  smallButtonIcon: {
    margin: 0,
    display: "flex",
    alignItems: "center",
  },
  smallButtonText: {
    fontWeight: 400,
    padding: 0,
    fontSize: "14px",
  },
}));
