import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme, { isBroadcasting: boolean }>((theme) => ({
  container: {
    position: "relative",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    border: ({ isBroadcasting }) => {
      const inputBorderColor = isBroadcasting
        ? "none"
        : theme.palette.gray.medium;

      return `1px solid ${inputBorderColor}`;
    },
    borderRadius: "5px",
    margin: theme.spacing(1),
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2.25),
    marginTop: "auto",
    backgroundColor: theme.palette.textInput.backgroundColor,
  },
  textInput: {
    width: "300px",
    resize: "none",
    border: "none",
    outline: "none",
    boxSizing: "border-box",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: 1.33,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    maxHeight: "200px",
    overflow: "scroll",
    bottom: 0,
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: theme.palette.textInput.backgroundColor,
    "& > div": {
      fontSize: "15px",
      lineHeight: "20px",
    },
    "& > textarea": {
      minHeight: "20px",
      minWidth: "120px",
      lineHeight: "20px",
      "&:disabled": {
        color: "#000",
      },
      "&:empty:before": {
        content: "attr(placeholder)",
        display: "block",
        /* For Firefox */
        color: "rgba(86, 88, 103, 0.3)",
        outline: "none",
      },
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  buttons: {
    display: "flex",
  },
  button: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: "5px",
    borderRadius: "50%",
    "& button": {
      padding: theme.spacing(0.5),
    },
  },
  sendButton: {
    width: `${theme.spacing(3)} !important`,
    height: `${theme.spacing(3)} !important`,
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
