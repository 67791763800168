export const SUBSCRIPTION_IN_TRIAL = "in_trial";

export const SUBSCRIPTION_NON_RENEWING = "non_renewing";

export enum CURRENCY_SYMBOL {
  JPY = "JP ¥",
  USD = "US $",
}

export enum Currency {
  JPY = "JPY",
  USD = "USD",
}

export const BRAND_NAME = "Events.com Virtual (formerly Remo)";
