import { PaletteOptions } from "@mui/material/styles";
import { Colors, EventsColors } from "../colors";

export const lightPalette: PaletteOptions = {
  salmon: {
    main: Colors.SALMON_PASTEL,
  },
  green: {
    main: Colors.GREEN_MAIN,
    background: Colors.GREEN_BACKGROUND,
    action: Colors.GREEN_ACTION,
    actionDarkMode: Colors.GREEN_ACTION_DARK_MODE,
    hoverDarkMode: Colors.GREEN_HOVER_DARK_MODE,
  },
  red: {
    main: Colors.RED_DANGER,
    light: Colors.RED_DANGER_LIGHT,
  },
  black: Colors.BLACK,
  error: {
    main: EventsColors.ERROR_DARK,
  },
  present: {
    greyBackground: Colors.PRESENT_GREY_BG,
  },
  gray: {
    disabled: Colors.GRAY_DISABLED,
    disabledInput: Colors.GRAY_DISABLED_INPUT,
    medium: Colors.GRAY_MEDIUM,
    light: Colors.GRAY_LIGHT,
    lightShade: Colors.GRAY_LIGHT_SHADE,
    main: Colors.GRAY_LIGHT,
    muted1: Colors.GRAY_MUTED1,
    muted2: Colors.GRAY_MUTED2,
  },
  white: Colors.WHITE,
  dark: {
    main: Colors.BLUE_DARK,
    overlay2: Colors.DARK_OVERLAY2,
  },
  blue: {
    main: Colors.BLUE_PRIMARY,
    lightShade1: Colors.BLUE_LIGHTSHADE1,
    lightShade4: Colors.BLUE_LIGHTSHADE4,
    dark: Colors.BLUE_DARK,
    darkShade1: Colors.BLUE_DARKSHADE1,
    darkShade2: Colors.BLUE_DARKSHADE2,
    disabled: Colors.DISABLED,
  },
  darkModeBlue: {
    main: Colors.BLUE_DARK_MODE,
  },
  primary: {
    main: EventsColors.BLUE_SECONDARY,
  },
  secondary: {
    main: Colors.BLUE_DARK,
  },
  text: {
    primary: EventsColors.DARK_GRAY,
    secondary: Colors.BLUE_DARKSHADE1,
  },
  yellow: {
    lightShade1: Colors.YELLOW_LIGHTSHADE_1,
    dark: Colors.YELLOW_DARK,
  },
  status: {
    warning: Colors.WARNING,
    success: Colors.SUCCESS,
    error: Colors.ERROR,
  },
  tour: {
    background: Colors.WHITE,
  },
  textInput: {
    backgroundColor: Colors.WHITE,
  },
  polls: {
    selectedAnswer: EventsColors.BLUE_ACCENT3,
    otherAnswer: EventsColors.GRAY_SECONDARY,
    correctAnswer: Colors.GREEN_LIGHT,
    incorrectAnswer: Colors.RED_DANGER_LIGHT,
    timerBar: Colors.BLUE_LIGHTSHADE5,
    background: Colors.WHITE,
    border: Colors.GRAY_LIGHT,
    ongoing: Colors.BLUE_LIGHTSHADE1,
    draft: Colors.GRAY_LIGHT,
    closed: Colors.GRAY_LIGHT_SHADE,
    selectedBorder: EventsColors.BLUE_PRIMARY,
    unselectedAnswer: EventsColors.GRAY_PRIMARY,
  },
  background: {
    default: Colors.WHITE,
    secondary: Colors.GRAY_LIGHT_SHADE,
    tertiary: EventsColors.BACKGROUND,
  },
  border: {
    default: EventsColors.GRAY_PRIMARY,
  },
  boxShadow: {
    default: EventsColors.BOX_SHADOW,
  },
  events: {
    black: EventsColors.BLACK,
    blueAccent2: EventsColors.BLUE_ACCENT2,
    blueAccent3: EventsColors.BLUE_ACCENT3,
    blueAccent4: EventsColors.BLUE_ACCENT4,
    bluePrimary: EventsColors.BLUE_PRIMARY,
    blueSecondary: EventsColors.BLUE_SECONDARY,
    textPrimary: EventsColors.DARK_GRAY,
    dialogHeaderBackground: EventsColors.GRAY_PRIMARY,
    dialogContentBackground: EventsColors.GRAY_SECONDARY,
    errorDark: EventsColors.ERROR_DARK,
    errorLight: EventsColors.ERROR_LIGHT,
    grayPrimary: EventsColors.GRAY_PRIMARY,
    graySecondary: EventsColors.GRAY_SECONDARY,
    grayTertiary: EventsColors.GRAY_TERTIARY,
    greenAccent1: EventsColors.GREEN_ACCENT1,
    greenAccent2: EventsColors.GREEN_ACCENT2,
    lightBlack: EventsColors.LIGHT_BLACK,
    successDark: EventsColors.SUCCESS_DARK,
    successLight: EventsColors.SUCCESS_LIGHT,
    warningDark: EventsColors.WARNING_DARK,
    warningLight: EventsColors.WARNING_LIGHT,
    whiteTertiary: EventsColors.WHITE_TERTIARY,
  },
};
