import MUISwitch from "@mui/material/Switch";
import classNames from "classnames";
import { useEffect, useState, MouseEvent } from "react";
import { styled } from "@mui/material/styles";
import { useSwitchStyles } from "./styles";
import { SwitchProps } from "./types";

const SliderSwitch = styled(MUISwitch)(({ theme }) => ({
  width: 28,
  height: 15,
  padding: 0,
  display: "flex",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    top: 1,
    left: 1,
    transition: theme.transitions.create(["transform"], { duration: 200 }),
    "&.Mui-checked": {
      transform: "translateX(13px)",
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.events.blueSecondary,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 13,
    height: 13,
    borderRadius: "50%",
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(["transform", "background-color"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 10,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    boxSizing: "border-box",
  },
}));

const Switch = ({
  checked = false,
  onClick,
  disabled = false,
  color = "primary",
  defaultChecked,
  size = "medium",
  required = false,
  darkMode = false,
  controlled = false,
  testId,
  ...rest
}: SwitchProps): JSX.Element => {
  const [checkedState, setCheckedState] = useState<boolean>(checked);
  const classes = useSwitchStyles();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    if (!controlled) {
      setCheckedState(!checkedState);
    }
  };

  useEffect(() => {
    setCheckedState(checked);
  }, [checked]);

  return (
    <SliderSwitch
      checked={checkedState}
      onClick={handleClick}
      // @ts-expect-error testid prop works but not in interface
      inputProps={{ "aria-label": "switch", "data-testid": testId }}
      disabled={disabled}
      color={color}
      defaultChecked={defaultChecked}
      size={size}
      required={required}
      role="switch"
      data-testid="ui-core-switch"
      aria-checked={checkedState}
      className={classNames({ [classes.darkMode]: darkMode })}
      {...rest}
    />
  );
};

export default Switch;
