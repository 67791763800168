import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { useState } from "react";
import { LiveStreamData } from "modules/liveStream/request";
import { LiveStreamForm, LiveStreamItem } from "modules/liveStream/components";
import { useSelector } from "react-redux";

import { Button } from "@remo-co/ui-core/src/components/Button";
import { Link } from "@remo-co/ui-core/src/components/Link";
import { useStyles } from "./styles";
import { selectLiveStreams } from "./redux/selectors";
import { AddLiveStream } from "./components/AddLiveStream";
import { StreamFormData } from "./components/LiveStreamForm";

interface Props {
  eventId: string;
}

const initialState = {
  serverUrl: "",
  streamKey: "",
  playbackUrl: "",
  destinationName: "",
};

export const LiveStream = ({ eventId }: Props): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [streamData, setStreamData] = useState<StreamFormData>(initialState);
  const { t } = useI18n(["manageEvent", "url"]);
  const styles = useStyles();
  const liveStreams = useSelector(selectLiveStreams);

  const closeStreamDialog = () => {
    setIsDialogOpen(false);
    setStreamData(initialState);
  };

  const handleStreamDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleEditForm = (stream: LiveStreamData) => {
    setStreamData(stream);
    setIsDialogOpen(true);
  };

  const showContactSales = liveStreams.length === 1;

  return (
    <>
      <Typography variant="h2" className={styles.heading}>
        {t("manageEvent:live.stream.your.presentation")}
      </Typography>
      <Typography variant="body1">
        {t("manageEvent:stream.description")}
      </Typography>

      <Button<typeof Link>
        className={styles.link}
        href={t("url:live.stream.help")}
        target="_blank"
        rel="noreferrer"
        variant="text"
        theme="light"
      >
        {t("manageEvent:stream.not.sure")}
      </Button>

      <div className="mar-top-40">
        {liveStreams.map((stream) => (
          <LiveStreamItem
            key={stream.id}
            stream={stream}
            eventId={eventId}
            openEditForm={handleEditForm}
          />
        ))}
      </div>
      <AddLiveStream
        showContactSales={showContactSales}
        handleStreamDialog={handleStreamDialog}
      />
      {isDialogOpen && (
        <LiveStreamForm
          handleClose={closeStreamDialog}
          eventId={eventId}
          initialFormData={streamData}
        />
      )}
    </>
  );
};
