import { isEqual } from "lodash";
import { useContext, useEffect, useState, useCallback } from "react";
import { InputLabel } from "@remo-co/ui-core/src/components/InputLabel";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { ValidatedChipInput } from "@remo-co/ui-core/src/components/ValidatedChipInput";
import useEventInviteActions from "modules/eventInvite/hooks/useEventInviteActions";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useI18n } from "i18n";
import { IEvent } from "modules/event/types";
import { isValidEmailAddress } from "@remo-co/email";
import useNotificationActions from "../../../notification/hooks/useNotificationActions";
import { useStyles } from "./styles";

const EMAILS_ADD_LIMIT = 10;

interface Props {
  eventData: IEvent;
  onInvite?: () => void;
}

const SpeakerInviteView = ({ eventData, onInvite }: Props): JSX.Element => {
  const { addSuccessNotification, addErrorNotification } =
    useNotificationActions();
  const { sendSpeakerInvite } = useEventInviteActions();
  const { track } = useContext(TRACKING_CONTEXT);
  const { t } = useI18n(["common", "eventForm", "event", "server"]);
  const { actions, state } = useContext(MANAGE_EVENT_CONTEXT);
  const { pendingSpeakerInvites = [] } = state ?? {};
  const styles = useStyles();

  const [emails, setEmails] = useState<string[]>(
    Array.from(pendingSpeakerInvites),
  );
  const [emailsInvalid, setEmailsInvalid] = useState(false);

  const handleSendEmail = useCallback(async () => {
    if (emailsInvalid) {
      return;
    }

    const theater = eventData?.theaters?.[0]?.id ?? undefined;
    const resp = await sendSpeakerInvite(eventData.id, theater, emails);

    if (resp.isSuccess) {
      addSuccessNotification({
        message: t("eventForm:email.send.success"),
        position: "tr",
        autoDismiss: 5,
      });
      track(Events.SPEAKERS_ADDED, {
        eventId: eventData.id,
        count: emails.length,
      });
      setEmails([]);
      onInvite?.();
    } else {
      addErrorNotification({
        message: resp.message ? t(resp.message) : t("something.wrong"),
        position: "tc",
        autoDismiss: 5,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails]);

  const validateSpeakerView = () => {
    if (emails?.length && eventData.id) {
      return {
        isValid: false,
        title: t("common:are.you.sure"),
        message: t("eventForm:start.invite.speaker"),
        confirmText: t("eventForm:back.invite"),
        cancelText: t("common:no.skip.it"),
        onConfirm: handleSendEmail,
      };
    }

    return { isValid: true };
  };

  useEffect(() => {
    actions?.setTabValidator(validateSpeakerView);

    return () => {
      actions?.setTabValidator(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails]);

  const disableSendEmailButton =
    emails.length === 0 || emails.length > EMAILS_ADD_LIMIT || emailsInvalid;

  const onInputChange = (updatedEmails: string[]) => {
    setEmails(updatedEmails);

    if (
      !eventData?.id &&
      !emailsInvalid &&
      !isEqual(Array.from(pendingSpeakerInvites).sort(), updatedEmails.sort())
    ) {
      actions?.setPendingSpeakerInvites(updatedEmails);
    }
  };

  return (
    <div>
      <InputLabel className={styles.inputLabel}>
        {t("eventForm:email.speaker")}
      </InputLabel>
      <ValidatedChipInput
        value={emails}
        validate={(hasSomeInvalid) => setEmailsInvalid(hasSomeInvalid)}
        onChange={onInputChange}
        limit={EMAILS_ADD_LIMIT}
        placeholder={t("add.email.placeholder")}
        invalidInputsMessage={t("email.invalid")}
        getRemainingMessage={(key) => t("email.remaining", { key })}
        getExceededMessage={(key) => t("email.exceeded", { key })}
        customValidator={isValidEmailAddress}
        theme="light"
      />
      <div style={{ marginTop: 10, textAlign: "right" }}>
        {eventData?.id && (
          <Button
            data-testid="speaker-invite-send-button"
            id="edit-event-modal_primary-cta-send-invites"
            variant="secondary"
            color="blue"
            contentClassName={styles.addSpeakerButtonContent}
            onClick={handleSendEmail}
            disabled={disableSendEmailButton}
            theme="light"
          >
            {t("eventForm:add.speaker.list")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SpeakerInviteView;
