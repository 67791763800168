/* eslint-disable react/destructuring-assignment */
import { useCallback, useMemo, useState } from "react";
import MaterialSelect, { SelectChangeEvent } from "@mui/material/Select";
import classNames from "classnames";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { MenuProps } from "@mui/material/Menu";
import ListSubheader from "@mui/material/ListSubheader";
import Check from "@mui/icons-material/Check";
import { Chevron } from "../../icons/Chevron";
import { ISelectOption, ISelectProps } from "./types";
import { useSelectStyles } from "./styles";

const NO_VALUE = "none";

const Select = (props: ISelectProps) => {
  const {
    value,
    label,
    onChange,
    fullWidth,
    error,
    LabelComponent,
    required,
    size = "medium",
    disabled,
    multiple,
    placeholder,
    isOpen,
    inputTestId = "selector-input",
    renderValue,
    disableOptionMaxWidth,
    inModal,
    ...rest
  } = props;
  const [input, setInput] = useState<unknown>(null);
  const [open, setOpen] = useState<boolean>(false);
  const classes = useSelectStyles({
    ...props,
    open,
    disableOptionMaxWidth,
    width: (input as HTMLSelectElement)?.parentElement?.clientWidth,
  });

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onChange(event.target.value as string & string[], event.target.name);
  };

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const containerClasses = classNames(classes.container, classes[size], {
    [classes.disabled]: disabled,
    [classes.error]: error && !open,
    [classes.value]: value,
  });

  const SelectMenuProps = useMemo(
    (): Partial<MenuProps> => ({
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: 0,
        horizontal: "left",
      },
      style: {
        zIndex: inModal ? 999999 : 1300, // 999999 matches modalZIndex @ client/src/zIndexes.module.scss
      },
      PopoverClasses: {
        root: classes.popover,
        paper: classes.popoverPaper,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // The Select material component doesn't accept placeholder. A workaround is
  // setting a default value when the value is empty and hide the item when the
  // popup is open https://github.com/mui-org/material-ui/issues/11069
  const getValue = useCallback((): string | string[] => {
    if (placeholder && !multiple && !value) return NO_VALUE;

    return value;
  }, [placeholder, multiple, value]);

  const parseMultipleValue = useCallback(
    (selected: unknown): string | undefined =>
      "options" in props
        ? props.options
            .reduce((acc: string[], option: ISelectOption): string[] => {
              if ((selected as string[]).includes(option.value)) {
                acc.push(option.label);
              }

              return acc;
            }, [])
            .join(", ")
        : "",
    [props],
  );

  const getRenderValue = () => {
    if (renderValue) {
      return renderValue;
    }

    return multiple ? parseMultipleValue : undefined;
  };

  const renderOptions = useCallback(
    (renderedOptions: ISelectOption[]) =>
      renderedOptions.map((option) => (
        <MenuItem
          key={option.value}
          disabled={option.disabled}
          value={option.value}
          className={classNames({
            [classes.selectOption]: Boolean(option.rightAdornment),
          })}
        >
          {option.leftAdornment && (
            <ListItemIcon>{option.leftAdornment}</ListItemIcon>
          )}
          <div className={classes.option}>
            <Typography
              variant="inherit"
              className={classes.selectText}
              component="div"
            >
              {option.label}
            </Typography>
            {option.description && (
              <Typography
                variant="caption"
                className={classes.selectText}
                component="div"
              >
                {option.description}
              </Typography>
            )}
          </div>
          {option.rightAdornment}
          {multiple && value?.includes(option.value) && (
            <Check className={classes.checkIcon} />
          )}
        </MenuItem>
      )),
    [
      classes.option,
      classes.selectOption,
      classes.selectText,
      multiple,
      value,
      classes.checkIcon,
    ],
  );

  const renderGroupedOptions = useCallback(
    (groupedOptions: Record<string, ISelectOption[]>) =>
      Object.entries(groupedOptions).map(([groupLabel, values]) => [
        <ListSubheader className={classes.listSubheader}>
          {groupLabel}
        </ListSubheader>,
        ...renderOptions(values),
      ]),
    [classes.listSubheader, renderOptions],
  );

  return (
    <FormControl variant="outlined" fullWidth={fullWidth}>
      <Typography component="div" className={classes.label}>
        {label && required ? `${label}*` : label}
        {LabelComponent && <LabelComponent />}
      </Typography>
      <MaterialSelect
        inputProps={{
          "data-testid": inputTestId,
          "aria-label": props["aria-label"],
        }}
        {...rest}
        ref={(ref) => {
          setInput(ref);
        }}
        value={getValue()}
        multiple={multiple}
        disabled={disabled}
        required={required}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        MenuProps={SelectMenuProps}
        className={containerClasses}
        IconComponent={Chevron}
        open={isOpen}
        renderValue={getRenderValue()}
      >
        {placeholder && !open && (
          <MenuItem disabled value={NO_VALUE}>
            {placeholder}
          </MenuItem>
        )}
        {props.grouped
          ? renderGroupedOptions(props.optionGroups)
          : renderOptions(props.options)}
      </MaterialSelect>
      {error && (
        <FormHelperText className={classes.errorDescription} error={!!error}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
