import React, { ComponentPropsWithRef } from "react";
import { v4 } from "uuid";
import classNames from "classnames";
import logger from "logging/logger";
import { Button } from "@remo-co/ui-core/src/components/Button";
import UploadService, {
  FILE_UPLOAD_STATUS,
  IAfterFileUpload,
} from "../../../../services/uploadService/uploadService";
import { useStyles } from "./styles";

type ButtonProps = Omit<ComponentPropsWithRef<typeof Button>, "onError">;

interface Props extends ButtonProps {
  name: string;
  path: string;
  level: number;
  maxFileSize?: string;
  acceptedFileTypes?: string[];
  useUuidFileNames?: boolean;
  button?: typeof Button;
  onError?: (error: Error) => void;
  onImageUploaded: (imageURL: string, filePath?: string) => void | undefined;
  buttonClass?: string;
}

/**
 *
 * Take note: fn supplied for onImageUploaded will have stale state,
 * because we pass the fn to `new UploadService` instance stored in a ref. (the
 * uploader we use is outside the React lifecycle for some reason)
 *
 * Take the returned URL from onImageUploaded and manage it separately as
 * part of the calling component's state, instead of relying on onImageUploaded
 * to call side-effects such as saving to db etc.
 */
const ImageUploader = ({
  name,
  path,
  level,
  children,
  maxFileSize,
  acceptedFileTypes,
  onImageUploaded,
  useUuidFileNames = false,
  button,
  onError,
  disabled,
  className,
  buttonClass,
  ...rest
}: Props) => {
  const videoImageUploaderRef = React.useRef<UploadService<unknown>>();
  const classes = useStyles();

  const afterImageUpload: IAfterFileUpload<unknown> = (
    err,
    _,
    url,
    file,
    filePath,
    // eslint-disable-next-line max-params
  ) => {
    if (onError && err) {
      onError(err);

      return;
    }

    if (file) {
      if (!url) {
        logger.error(
          `[ImageInput][afterImageUpload] Url not formed for file ${file.name}`,
        );
        throw new Error(`Url not formed for file ${file.name}`);
      }
      onImageUploaded(url, filePath);
    }
  };

  const fileRenameFunction = (file: { name: string; extension: string }) => {
    if (!useUuidFileNames) {
      return file.name;
    }

    return `${v4()}${file.extension}`;
  };

  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    return false;
  };

  React.useLayoutEffect(() => {
    videoImageUploaderRef.current = new UploadService(
      `video-image-uploader-${name}`,
      {
        afterFileUpload: afterImageUpload,
        path,
        level,
        maxFileSize: maxFileSize || "1024KB",
        acceptedFileTypes: acceptedFileTypes || [
          "image/jpeg",
          "image/png",
          "image/gif",
        ],
        fileValidateTypeLabelExpectedTypes: acceptedFileTypes
          ? `Please upload only ${acceptedFileTypes
              .map((type) => type.split("/")[1].toUpperCase())
              .join(", ")}`
          : "Please upload only JPG, PNG, GIF",
        fileRenameFunction,
      },
    );

    return () => {
      if (videoImageUploaderRef.current) {
        // TODO: calling this causes filePond to throw an error
        videoImageUploaderRef.current.destroy();
        videoImageUploaderRef.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, level, maxFileSize, name]);

  const startImageAttachment = (attachmentName: string) => {
    const uploader = videoImageUploaderRef.current;

    if (!uploader) {
      return;
    }

    const fileUploadStatus = uploader.getStatus();

    if (!fileUploadStatus || fileUploadStatus === FILE_UPLOAD_STATUS.END) {
      uploader.browse({
        id: "image",
        name: `${attachmentName}`,
        type: "images",
      });
    }
  };

  const buttonProps: ButtonProps = {
    ...rest,
    disabled,
    onClick: () => {
      if (disabled) {
        return;
      }
      startImageAttachment(name);
    },
    className: `${classes.button} ${buttonClass}`,
    contentClassName: classes.buttonContent,
  };

  const ButtonComponent = button ?? Button;

  return (
    <div className={classNames(classes.uploaderContainer, className)}>
      <ButtonComponent {...buttonProps}>{children}</ButtonComponent>
      <div className="file-uploader-container">
        <input
          onPaste={handleOnPaste}
          id={`video-image-uploader-${name}`}
          type="file"
          name="filepond"
          data-testid="file-uploader-input"
        />
      </div>
    </div>
  );
};

export default ImageUploader;
