import classNames from "classnames";
import { CircularProgress } from "@remo-co/ui-core/src/components/CircularProgress";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { ArrowBack as ArrowBackIcon } from "@remo-co/ui-core/src/icons/ArrowBack";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { SaveAlt as ExportChat } from "@remo-co/ui-core/src/icons/SaveAlt";
import { useI18n } from "i18n";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { selectIsEventManager } from "modules/event/selectors";
import { selectIsBroadcasting } from "modules/broadcast/redux/selectors";
import { useSelector } from "react-redux";
import { ActivityStatusIndicator } from "modules/eventActivityStatus/components/ActivityStatusIndicator";
import useStyles from "./styles";

interface Props {
  newMessagesCount: number;
  title: string;
  showClearButton: boolean;
  isClearInProgress: boolean;
  showExportButton: boolean;
  isExportInProgress: boolean;
  userInConversation: string | null;
  onBackClick: () => void;
  onClear: () => void;
  onExport: () => void;
}

const ChatMessagesHeader = ({
  newMessagesCount,
  title,
  showClearButton,
  isClearInProgress,
  showExportButton,
  isExportInProgress,
  userInConversation,
  onBackClick,
  onClear,
  onExport,
}: Props): JSX.Element => {
  const { myCompanyPlan, isFreePlan, eventCompanyPlan } =
    useCompanyPlanSettings();
  const isBroadcasting = useSelector(selectIsBroadcasting);
  const classes = useStyles({ isBroadcasting });
  const { t } = useI18n(["common", "event", "server", "manageEvent"]);
  const disableExport = isFreePlan(eventCompanyPlan || myCompanyPlan);
  const hasEventManagerAccess = useSelector(selectIsEventManager);
  const disabledContent = hasEventManagerAccess
    ? t("manageEvent:feature.disabled")
    : "";
  const exportTooltipTitle = disableExport ? disabledContent : t("export.chat");

  return (
    <div className={classes.header} data-testid="chat-messages-header">
      {newMessagesCount > 0 && (
        <b
          className={classes.redDot}
          title={t("event:unread.messages", {
            key: newMessagesCount,
          })}
        />
      )}
      <IconButton
        data-testid="back-button"
        className={classes.button}
        onClick={onBackClick}
        disableRipple
        data-dd-action-name="Back Button - Go to Channel's List"
        disableFocusRipple
        size="large"
      >
        <ArrowBackIcon />
      </IconButton>
      <div className={classes.teamName} title={title}>
        <Typography variant="h5">{title}</Typography>
        {userInConversation && (
          <ActivityStatusIndicator userId={userInConversation} small />
        )}
      </div>
      {showClearButton && (
        <Tooltip title={t("clear.general.chat")} placement="top">
          {(isClearInProgress && <CircularProgress size={24} />) || (
            <IconButton
              className={classNames("sch-delete", classes.button)}
              data-testid="delete-chat-button"
              onClick={onClear}
              disableRipple
              disableFocusRipple
              size="large"
            >
              <Delete />
            </IconButton>
          )}
        </Tooltip>
      )}
      {showExportButton && (
        <Tooltip
          title={exportTooltipTitle}
          placement="top"
          data-testid={exportTooltipTitle}
        >
          <div className={classes.exportButton}>
            {(isExportInProgress && (
              <CircularProgress
                data-testid="export-in-progress"
                className={classes.progress}
                size={24}
              />
            )) || (
              <IconButton
                className={classes.button}
                onClick={onExport}
                data-testid="export-chat-button"
                disableRipple
                disableFocusRipple
                disabled={disableExport}
                size="large"
              >
                <ExportChat />
              </IconButton>
            )}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default ChatMessagesHeader;
