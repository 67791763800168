import { useEffect, useMemo, useState } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Grid } from "@remo-co/ui-core/src/components/Grid";
import { useI18n } from "i18n";
import { selectMaxInTable } from "modules/company/redux/selectors";
import { useSelector } from "react-redux";
import { IMapTemplate } from "types/theater";
import { NoFloorPlan } from "modules/customFloorPlan/components/NoFloorPlan";
import { ArrowForward } from "@remo-co/ui-core/src/icons/ArrowForward";
import { ArrowBack } from "@remo-co/ui-core/src/icons/ArrowBack";
import { FloorPlanLayoutItem } from "../FloorPlanLayoutItem";
import LayoutPreview from "../../customizeTheme/LayoutPreview";
import { isClassicTheme, validateTableSeatCount } from "../../utils";
import { useStyles } from "./styles";

interface Props {
  templates: IMapTemplate[];
  isAccountSettings?: boolean;
  displayedTheme?: string;
  onUserSelectLayout?: (template: IMapTemplate, theme?: string) => void;
  setShowSeatWarning?: (showWarning: boolean) => void;
  floorCount: number;
  showDelete?: boolean;
}

const FloorPlanLayout = ({
  templates,
  isAccountSettings,
  displayedTheme,
  onUserSelectLayout,
  setShowSeatWarning,
  floorCount,
  showDelete,
}: Props): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(0);
  const { t } = useI18n();
  const styles = useStyles();
  const [previewTemplate, setPreviewTemplate] = useState<IMapTemplate | null>(
    null,
  );
  const [previewTheme, setPreviewTheme] = useState("");

  const maxInTable = useSelector(selectMaxInTable);
  const maxLayouts = isAccountSettings ? 15 : 8;
  // Convert one dimensional array to 2 dimension, so we can build grids

  useEffect(() => {
    if (currentPage !== 0) {
      // avoid needless re-rendering by using a condition
      setCurrentPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedTheme]);

  const handlePreviewClose = () => {
    setPreviewTemplate(null);
    setPreviewTheme("");
  };

  const onSelectLayout = (template: IMapTemplate, theme?: string) => {
    validateTableSeatCount({ template, maxInTable, setShowSeatWarning });
    if (onUserSelectLayout) onUserSelectLayout(template, theme);
    handlePreviewClose();
  };

  const showLayoutPreview = (template: IMapTemplate, theme?: string) => {
    setPreviewTemplate(template);
    validateTableSeatCount({ template, maxInTable });
    if (theme) {
      setPreviewTheme(theme);
    }
  };

  const onSelectFloorPlan = (template: IMapTemplate, theme?: string) => {
    validateTableSeatCount({ template, maxInTable, setShowSeatWarning });
    if (onUserSelectLayout) onUserSelectLayout(template, theme);
  };

  const renderClassicTemplates = () =>
    templates
      .map((template) => {
        if (template?.code && template?.themes) {
          const { exceedsSeatCap, maxSeats } = validateTableSeatCount({
            template,
            maxInTable,
          });

          return Object.keys(template.themes).map((classicTheme) => (
            <FloorPlanLayoutItem
              key={`${template.code}-${classicTheme}`}
              id={`fp-${template.code}`}
              themeName={classicTheme}
              template={template}
              isAccountSettings={isAccountSettings}
              onSelect={onSelectFloorPlan}
              onClick={showLayoutPreview}
              showWarning={exceedsSeatCap}
              maxSeats={maxSeats}
            />
          ));
        }

        return null;
      })
      .flat();

  const renderTemplates = () =>
    templates
      .map((template) => {
        if (template?.code) {
          const { exceedsSeatCap, maxSeats } = validateTableSeatCount({
            template,
            maxInTable,
          });

          return (
            <FloorPlanLayoutItem
              key={template.code}
              id={`fp-${template.code}`}
              themeName={displayedTheme}
              template={template}
              isAccountSettings={isAccountSettings}
              onSelect={onSelectFloorPlan}
              onClick={showLayoutPreview}
              showWarning={exceedsSeatCap}
              maxSeats={maxSeats}
              showDelete={showDelete}
            />
          );
        }

        return null;
      })
      .flat();

  const themeTemplates = useMemo(
    () =>
      displayedTheme && isClassicTheme(displayedTheme)
        ? renderClassicTemplates()
        : renderTemplates(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displayedTheme, templates, floorCount],
  );

  const paginatedTemplates = useMemo(
    () =>
      themeTemplates.slice(
        currentPage * maxLayouts,
        currentPage * maxLayouts + maxLayouts,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, templates, floorCount],
  );

  const lastPage = Math.ceil(themeTemplates.length / maxLayouts) - 1;

  const onPrev = () => {
    setCurrentPage((page) => Math.max(page - 1, 0));
  };
  const onNext = () => {
    setCurrentPage((page) => Math.min(page + 1, lastPage));
  };

  return (
    <>
      <Grid
        className={styles.container}
        container
        spacing={3}
        data-testid="layouts-container"
      >
        {paginatedTemplates}
      </Grid>
      {paginatedTemplates.length === 0 && <NoFloorPlan />}
      {lastPage > 0 && (
        <Box
          className={
            isAccountSettings ? styles.centeredPagination : styles.pagination
          }
        >
          <Button
            disabled={currentPage === 0}
            data-testid="pagination-previous-btn"
            onClick={onPrev}
            variant="ghost"
            color="dark"
            isIconButton
            className={styles.paginationButton}
            contentClassName={styles.paginationButtonContent}
          >
            <ArrowBack className={styles.buttonIcon} /> {t("button.back")}
          </Button>
          <Button
            disabled={currentPage === lastPage}
            data-testid="pagination-next-btn"
            onClick={onNext}
            variant="ghost"
            color="dark"
            isIconButton
            className={styles.paginationButton}
            contentClassName={styles.paginationButtonContent}
          >
            {t("button.next")}
            <ArrowForward className={styles.buttonIcon} />
          </Button>
        </Box>
      )}
      {previewTemplate && (
        <LayoutPreview
          isHidden={isAccountSettings}
          theme={previewTheme}
          template={previewTemplate}
          onClose={handlePreviewClose}
          onSelect={onSelectLayout}
        />
      )}
    </>
  );
};

export default FloorPlanLayout;
