import { useContext } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import { useI18n } from "i18n";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";

interface Props {
  onSuccessfulSave(): void;
  closeButtonLink: string;
}

const ManageEventActions = ({
  onSuccessfulSave,
  closeButtonLink,
}: Props): JSX.Element => {
  const { saveEvent, state, isLoadingEvent } = useContext(MANAGE_EVENT_CONTEXT);
  const { t } = useI18n(["common", "manageEvent"]);
  const styles = useStyles();

  // eslint-disable-next-line consistent-return
  const handleSaveClick = async () => {
    const eventData = await saveEvent();

    if (eventData) {
      // TODO: Remove this setTimeout and Prompt logic in ManageEventContext after react-router v6 upgrade
      setTimeout(onSuccessfulSave, 50);
    }
  };

  return (
    <div data-testid="manage-event-actions" className="manage-event-actions">
      {!isLoadingEvent && (
        <Button onClick={handleSaveClick} color="dark" size="md" theme="light">
          {state?.eventData?.id ? t("save.close") : t("button.publish.event")}
        </Button>
      )}
      <Button<typeof Link>
        component={Link}
        variant="text"
        color="dark"
        to={closeButtonLink}
        className={styles.closeButton}
        theme="light"
      >
        <Close />
      </Button>
    </div>
  );
};

export default ManageEventActions;
