import { forwardRef } from "react";
import { ButtonLight } from "./ButtonLight";
import { IButtonProps } from "./types";

export const Button = forwardRef(
  (props: IButtonProps, ref: React.Ref<HTMLButtonElement>) => (
    <ButtonLight {...props} ref={ref} />
  ),
) as <T>(
  props: Omit<
    T extends "a"
      ? React.HTMLProps<HTMLAnchorElement>
      : T extends React.ElementType<infer U>
      ? U
      : T,
    keyof IButtonProps
  > &
    IButtonProps & {
      ref?: React.Ref<T extends "a" ? HTMLAnchorElement : HTMLButtonElement>;
    },
) => React.ReactElement;
