import { Types } from "mongoose";

export interface IServicePlanSettings {
  theater: {
    maxCapacity: number;
    maxInTable: number;
    speakers: number;
    speakersOnStage: number;
    availableMaps: number;
    whiteboarding: boolean;
  };
  event: {
    contents: number;
    timeLimit: number;
    concurrent: number;
    sponsors: number;
    exitPage: boolean;
    networkingRecommendations?: boolean;
  };
  company: {
    managers: number;
    assistants?: number; // to confirm
    whiteLabelling: boolean;
    monthlyLimit?: number;
    freeGuestPerMonth?: number;
    directoryEventLimit: number;
    directoryLimit: number;
    allowedOverdueHours?: number;
    guestPerMonth?: number;
    defaultGuestDays?: number; // plan-included guest days e.g. one time basic will have 160 guest days
    agencyWhiteLabelling?: boolean;
    ticketing: boolean;
  };
  unlimitedEvents?: {
    maxCapacity: number;
    contents: number;
    speakers: number;
    concurrent: number;
  };
}

export interface IServicePlan {
  name: string;
  chargebeePlanId: string;
  appsumoPlanId?: string;
  settings: IServicePlanSettings;
  disableUpgrade: boolean; // Used to identify if it is legacy/deprecated plan
  level: number; // Used to identify the level to check if user is upgrading/downgrading
  isAgencyPlan?: boolean;
  isOneTimePlan?: boolean;
  isEnterprisePlan?: boolean;
  guestPrice?: number;
  agencyTier?: number;
}

interface ICancellation {
  feedback: string;
  plan: string;
}

export interface IAddon {
  addon: string;
  quantity: number;
}

export interface ICompanyServicePlan {
  chargebeeSubscriptionId: string;
  chargebeePlanId: string | null;
  servicePlan: string;
  trialStartedAt: Date;
  paymentFailedAt: Date | null;
  createdAt: Date;
  isCancelled: boolean;
  cancellations: ICancellation[];
  addons: IAddon[];
  settings: IServicePlanSettings | null;
  billingStart: Date | null;
  billingEnd: Date | null;
  billingCreated: Date | null;
  coupon?: string;
  isAgencyPlan: boolean;
  // objectids
  company: Types.ObjectId;
}

export enum LoginOptions {
  ANONYMOUS = "anonymous",
  CUSTOM_SSO = "customSSO",
  EDC = "edc",
  EMAIL_LINK = "emailLink",
  EMAIL_WITH_PASSWORD = "emailWithPassword",
  FACEBOOK = "facebook",
  GOOGLE = "google",
  LINKEDIN = "linkedin",
  MICROSOFT = "microsoft",
}

export type LoginOptionsConfiguration = {
  [key in LoginOptions]: boolean;
};

export const DEFAULT_LOGIN_OPTIONS: LoginOptionsConfiguration = {
  [LoginOptions.CUSTOM_SSO]: true,
  [LoginOptions.EMAIL_LINK]: true, // fixed to true
  [LoginOptions.FACEBOOK]: true,
  [LoginOptions.GOOGLE]: true,
  [LoginOptions.LINKEDIN]: false,
  [LoginOptions.MICROSOFT]: false,
  [LoginOptions.ANONYMOUS]: true,
  [LoginOptions.EMAIL_WITH_PASSWORD]: true,
  [LoginOptions.EDC]: true, // fixed to true
};

export const ADD_ON_TYPE = {
  GUEST_COUNT: "guest-count",
};

export * from "./generic";
export * from "./daily/recording";
export * from "./daily/room";
