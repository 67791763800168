import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogCloseButton } from "@remo-co/ui-core/src/components/DialogCloseButton";
import { Paper } from "@remo-co/ui-core/src/components/Paper";
import { CardMedia } from "@remo-co/ui-core/src/components/CardMedia";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { getEmbeddedVideoURL, validateVideoURL } from "helpers/videoHelper";
import { NativeMediaType } from "modules/event/types";
import VideoPlayerIFrame from "modules/videoPlayer/VideoPlayerIFrame";
import "./styles.scss";
import { useStyles } from "./styles";

interface Props {
  mediaType?: NativeMediaType;
  mediaURL: string;
  message: string | undefined;
  defaultWelcomeMessage: { title: string };
  closePreview: () => void;
}

export const WelcomePreviewDialog = ({
  mediaType,
  mediaURL,
  message,
  defaultWelcomeMessage,
  closePreview,
}: Props): JSX.Element => {
  const styles = useStyles();
  const { t } = useI18n(["common"]);

  return (
    <Dialog
      fullWidth
      open
      onClose={closePreview}
      classes={{
        paper: styles.dialog,
      }}
    >
      <DialogCloseButton onClick={closePreview} />
      <DialogContent className={styles.previewContent}>
        <Paper className="ob-summary" data-testid="onboard-summary">
          <Typography variant="h3" className={styles.title}>
            {defaultWelcomeMessage.title}
          </Typography>

          <CardMedia>
            {mediaType === "image" && mediaURL && (
              <div className="ob-image">
                <img src={mediaURL} alt={t("manageEvent:onboarding")} />
              </div>
            )}
            {mediaType === "video" && validateVideoURL(mediaURL) && (
              <VideoPlayerIFrame src={getEmbeddedVideoURL(mediaURL) || ""} />
            )}
          </CardMedia>
          <Typography variant="h5" className={styles.body}>
            {message}
          </Typography>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};
