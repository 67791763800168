import { createTheme } from "@mui/material/styles";
import { TabProps } from "@mui/material/Tab";
import { InputBaseProps } from "@mui/material/InputBase";
import { FormLabelProps } from "@mui/material/FormLabel";
import { Colors } from "./colors";
import { darkPalette } from "./palettes";
import { overrides } from "./overrides";
import { baseTheme } from "./baseTheme";
import { typography } from "./typography";

export const darkTheme = createTheme({
  ...baseTheme,
  palette: darkPalette,
  typography,
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: darkPalette?.background?.default,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          "&:hover": {
            backgroundColor: darkPalette.blue.darkShade1,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          ...(overrides.MuiInputBase?.styleOverrides?.input as InputBaseProps),
          color: darkPalette.white,
          "&::placeholder": {
            color: darkPalette.white,
            opacity: "100%",
          },
        },
        root: {
          ...(overrides.MuiInputBase?.styleOverrides?.root as InputBaseProps),
          backgroundColor: darkPalette.events.lightBlack,
          borderColor: darkPalette.blue.darkShade1,
          "&:hover": {
            borderColor: darkPalette.blue.dark,
          },
          "&.Mui-focused": {
            borderColor: `${darkPalette.blue.main} !important`,
            "&:hover": {
              borderColor: darkPalette.blue.main,
            },
          },
          "&.Mui-disabled": {
            background: darkPalette.gray.disabledInput,
            borderColor: darkPalette.gray.light,
            color: `${darkPalette.blue.darkShade1}70 !important`,

            "&:hover": {
              cursor: "not-allowed",
              background: darkPalette.gray.disabledInput,
              borderColor: darkPalette.gray.light,
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          color: darkPalette.white,
        },
        caption: {
          color: darkPalette.white,
        },
        body1: {
          color: darkPalette.white,
        },
        body2: {
          color: darkPalette.white,
        },
        h3: {
          color: darkPalette.white,
        },
        h4: {
          color: darkPalette.white,
        },
        h5: {
          color: darkPalette.white,
        },
        subtitle2: {
          color: Colors.PRESENT_WHITE,
        },
        root: {
          color: "inherit",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.BLUE_DARKSHADE1,
          color: Colors.WHITE,
        },
        colorPrimary: {
          backgroundColor: Colors.BLUE_DARKSHADE1,
          color: Colors.WHITE,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.PRESENTATION_MODE_BACKGROUND,
          color: Colors.WHITE,
        },
        indicator: {
          backgroundColor: darkPalette.blue.main,
          "&::after": {
            backgroundColor: darkPalette.blue.main,
          },
          height: "0.2rem",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...(overrides.MuiFormLabel?.styleOverrides?.root as FormLabelProps),
          color: darkPalette.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorSecondary: {
          ...(overrides.MuiTab?.styleOverrides?.textColorSecondary as TabProps),
          color: Colors.WHITE,
        },
        textColorInherit: {
          ...(overrides.MuiTab?.styleOverrides?.textColorInherit as TabProps),
          color: Colors.WHITE,
        },
        textColorPrimary: {
          ...(overrides.MuiTab?.styleOverrides?.textColorPrimary as TabProps),
          color: Colors.WHITE,
        },
        root: {
          ...(overrides.MuiTab?.styleOverrides?.root as TabProps),
          color: Colors.WHITE,
        },
      },
    },
  },
});
