import { ChangeEvent } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { FormHelperText } from "@remo-co/ui-core/src/components/FormHelperText";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { InputLabel } from "@remo-co/ui-core/src/components/InputLabel";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { EventContentMediaType } from "graphql/generated";
import { useI18n } from "i18n";
import { validateVideoURL } from "helpers/videoHelper";
import { ImageUploader } from "modules/uploader";
import * as FileStoragePaths from "services/firebaseService/storagePaths";
import SponsorUploadAndPreview from "modules/eventForm/sponsorship/SponsorUploadAndPreview";
import UploadCaption, { MaxUploadSizes } from "modules/uploader/UploadCaption";
import { useStyles } from "./styles";
import { isValidURL } from "../../utils";

interface Props {
  mediaType?: EventContentMediaType;
  media?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleImageMediaChange: (field: string, imageURL?: string) => void;
}

const MEDIA_FIELD = "media";

export const MediaContentField = ({
  mediaType,
  media,
  handleChange,
  handleImageMediaChange,
}: Props) => {
  const { t } = useI18n(["eventForm", "event", "common"]);
  const styles = useStyles();

  switch (mediaType) {
    case EventContentMediaType.Video:
      return (
        <Input
          name="media"
          id="media-video-field"
          fullWidth
          size="sm"
          placeholder={t("eventForm:enter.your.video.link")}
          value={media}
          onChange={handleChange}
          required
          error={
            !!media && !validateVideoURL(media)
              ? t("eventForm:invalid.url.input") // to change
              : false
          }
          description={t("eventForm:supported.video.players")}
          theme="light"
        />
      );

    case EventContentMediaType.Iframe:
      return (
        <>
          <Input
            name="media"
            id="media-iframe-field"
            fullWidth
            size="sm"
            placeholder={t("eventForm:enter.your.website.link")}
            value={media}
            onChange={handleChange}
            required
            error={
              !!media && !isValidURL(media)
                ? `${t("event:embed.content.invalid.url")}`
                : false
            }
            description={t("eventForm:supported.link")}
            theme="light"
          />
          <InputLabel className={styles.label}>{t("event:preview")}</InputLabel>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            className={styles.preview}
          >
            {isValidURL(media) ? (
              <iframe
                src={media}
                frameBorder={0}
                title="URL preview"
                className={styles.previewIframe}
              />
            ) : (
              <Typography variant="subtitle1">
                {t("event:embed.content.preview")}
              </Typography>
            )}
          </Box>
        </>
      );

    case EventContentMediaType.Image:
      return (
        <ImageUploader
          name="media"
          level={FileStoragePaths.UPLOAD_LEVEL.COMPANY}
          path={FileStoragePaths.EVENT_CONTENT}
          maxFileSize="2048KB"
          onImageUploaded={(url) => handleImageMediaChange(MEDIA_FIELD, url)}
          className={styles.imageMediaUploader}
          contentClassName={styles.imageMediaUploaderContent}
          variant="ghost"
          color="dark"
          size="md"
          data-testid="upload-image-button"
        >
          <SponsorUploadAndPreview
            onDelete={() => handleImageMediaChange(MEDIA_FIELD)}
            dataKey="imageMedia"
            uploadedImage={media}
            helpText={
              <FormHelperText>
                <UploadCaption allowGif maxSize={MaxUploadSizes.big} />
              </FormHelperText>
            }
            className={styles.imageMediaUploaderPreview}
          />
        </ImageUploader>
      );

    case EventContentMediaType.Text:
    default:
      return (
        <Input
          name="media"
          id="media-text-field"
          fullWidth
          size="sm"
          placeholder={t("eventForm:event.content.description")}
          value={media}
          onChange={handleChange}
          required
          theme="light"
        />
      );
  }
};
