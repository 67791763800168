import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  label: {
    "& .MuiFormControlLabel-label": {
      paddingRight: "0.5rem",
    },
  },
  container: {
    marginBottom: "20px",
  },
}));
