import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "17px 20px",
    marginTop: 10,
    border: `1px solid ${theme.palette.gray.light}`,
    borderRadius: 3,
    alignItems: "center",
    cursor: "pointer",
  },
  disabled: {
    cursor: "not-allowed",
  },
  description: {
    marginTop: 5,
    color: theme.palette.blue.dark,
  },
  emptyDescription: {
    marginTop: 5,
    color: theme.palette.blue.darkShade1,
  },
  floorNumber: {
    border: `1px solid ${theme.palette.blue.dark}`,
    color: theme.palette.blue.dark,
    padding: "2px 6.5px",
    borderRadius: "200px",
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    marginRight: 10,
    fontWeight: 800,
    height: 20,
    width: 20,
  },
  name: {
    fontSize: 16,
    fontWeight: 800,
    color: theme.palette.blue.dark,
  },
  floorCard: {
    display: "flex",
    flexDirection: "column",
  },
  action: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.events.blueSecondary,
  },
  actionText: {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 700,
  },
  actionIcon: {
    height: "16px",
  },
}));

export default useStyles;
