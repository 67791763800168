import { useContext } from "react";
import { getEventDurationInMinutes } from "helpers/timeHelper";
import { useI18n } from "i18n";
import logger from "logging/logger";
import { IDialogParams } from "modules/dialogNotification/components/DialogNotificationContainer";
import { addErrorNotification } from "modules/notification/redux/notificationSlice";
import companyActions from "modules/companySettings/redux/actions";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { USERPILOT_CONTEXT } from "modules/userpilot/UserpilotContext";
import { createEvent, updateEvent } from "services/apiService/apis";
import { useAppDispatch } from "store/hooks";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { DialogType } from "modules/dialogNotification/constants";
import EventValidationErrorPopup from "modules/eventForm/validationError/EventValidationErrorPopup";
import { useSelector } from "react-redux";
import { selectCompany } from "modules/company/redux/selectors";
import { isTypeOfIMapTemplate, updateTemplateObjByCode } from "../template";
import { IEvent } from "../types";

const { setCompanySettings } = companyActions;

const getEventCapacity = ({ theaters }: Pick<IEvent, "theaters">) => {
  if (theaters && theaters[0] && theaters[0].capacity) {
    return theaters[0].capacity;
  }

  return -1;
};

const checkIfEventIsCustomFloorPlan = ({
  theaters,
}: Pick<IEvent, "theaters">) => {
  if (theaters && theaters[0] && isTypeOfIMapTemplate(theaters[0].template)) {
    return !!theaters[0].template.isPrivate;
  }

  return false;
};

export const useSaveEvent = () => {
  const dispatch = useAppDispatch();
  const { track } = useContext(TRACKING_CONTEXT);
  const { t } = useI18n(["common", "event", "server"]);
  const { trackUserpilotEvent } = useContext(USERPILOT_CONTEXT);
  const { company, companyPlan } = useSelector(selectCompany);
  const companyId = company?._id;

  const saveEvent = async (event: Optional<IEvent, "id">) => {
    try {
      if (!companyId) {
        logger.error("[saveEvent] company must not be null");
        dispatch(
          addErrorNotification({
            message: t("event:something.wrong"),
            position: "tc",
            autoDismiss: 5,
          }),
        );

        return null;
      }

      updateTemplateObjByCode(event);
      const resp = await (event.id
        ? updateEvent(event)
        : createEvent(companyId, event));

      if (resp.isSuccess && resp.event) {
        if (!event.id) {
          const trackingData = {
            eventId: resp.event.id,
            capacity: getEventCapacity(event),
            duration: getEventDurationInMinutes(event),
            customFloor: checkIfEventIsCustomFloorPlan(event),
            eventType: resp.event.eventType,
          };

          track(Events.NEW_EVENT_CREATED, trackingData);
          trackUserpilotEvent(Events.NEW_EVENT_CREATED, trackingData);
        }

        if (resp.companySettings) {
          dispatch(setCompanySettings(resp.companySettings));
        }

        return resp.event;
      }

      const validationMessage = resp.message
        ? t(resp.message, {
            planName: companyPlan?.name,
            concurrent: companyPlan?.settings.event.concurrent,
          })
        : t("event:something.wrong");
      const dialogParams: IDialogParams = {
        title: t("common:error"),
        message: validationMessage,
      };

      // Custom validation errors
      if (resp.data && resp.data.isValidationError) {
        dialogParams.message = "";
        dialogParams.content = (
          <EventValidationErrorPopup
            data={resp.data}
            message={validationMessage}
          />
        );
      }

      dispatch(
        addDialogNotification({
          ...dialogParams,
          hideCloseButton: true,
          hideDismissButton: true,
          confirmText: t("button.ok"),
          onConfirm: () => {},
          type: DialogType.ERROR_MESSAGE,
        }),
      );
    } catch (error) {
      interface errorType extends Error {
        data: { isValidationError: boolean };
      }

      const typedError = error as errorType;

      const validationMessage = t(
        typedError.message || "event:something.wrong",
      );
      const dialogParams: IDialogParams = {
        title: t("common:error"),
        message: validationMessage,
      };

      if (typedError.data && typedError.data.isValidationError) {
        dialogParams.message = "";
        dialogParams.content = (
          <EventValidationErrorPopup
            data={typedError.data}
            message={validationMessage}
          />
        );
      }

      dispatch(
        addDialogNotification({
          ...dialogParams,
          hideCloseButton: true,
          hideDismissButton: true,
          confirmText: t("button.ok"),
          onConfirm: () => {},
          type: DialogType.ERROR_MESSAGE,
        }),
      );
    }

    return null;
  };

  return saveEvent;
};
