import { ComponentProps, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useI18n } from "i18n";
import classNames from "classnames";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { isTrialCompleted } from "modules/companyPlanSettings/utils/trial";
import { ClickAwayListener } from "@remo-co/ui-core/src/components/ClickAwayListener";
import { ArrowForwardIos } from "@remo-co/ui-core/src/icons/ArrowForwardIos";
import { List } from "@remo-co/ui-core/src/components/List";
import { ListItem } from "@remo-co/ui-core/src/components/ListItem";
import { ListItemSecondaryAction } from "@remo-co/ui-core/src/components/ListItemSecondaryAction";
import { ListItemText } from "@remo-co/ui-core/src/components/ListItemText";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import {
  autoUpdate,
  offset,
  flip,
  useFloating,
} from "@floating-ui/react-dom-interactions";
import { useSelector } from "react-redux";
import { selectUnlimitedOngoingAndUpcomingEvents } from "modules/event/selectors";
import { useStyles } from "./styles";

interface Props extends Pick<ComponentProps<typeof Button>, "size"> {
  id?: string;
  label?: string;
  className?: string;
}

const CreateEventButton = ({
  id,
  label,
  size,
  className,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const unlimitedEvents = useSelector(selectUnlimitedOngoingAndUpcomingEvents);
  const { myCompanyPlan, unlimitedEventsSettings } = useCompanyPlanSettings();
  const trialExpired = isTrialCompleted(myCompanyPlan);
  const { t } = useI18n(["common", "event"]);
  const history = useHistory();
  const { track } = useContext(TRACKING_CONTEXT);
  const styles = useStyles();

  const handleCreateEventClick = () => {
    track(Events.NEW_EVENT_BUTTON_CLICKED);
    history.push("/event");
  };

  const handleCreateSpaceClick = () => {
    history.push("/event", { isUnlimitedEvent: true });
  };

  const handleOpenClose = () => {
    if (unlimitedEventsSettings?.concurrent) {
      setOpen(!open);
    } else {
      handleCreateEventClick();
    }
  };

  const { x, y, floating, strategy, reference } =
    useFloating<HTMLButtonElement>({
      placement: "right-start",
      whileElementsMounted: autoUpdate,
      middleware: [
        offset({ mainAxis: 10, crossAxis: 0 }),
        flip({
          fallbackPlacements: ["left-start", "bottom", "top-start"],
        }),
      ],
    });

  return (
    <>
      <Box className={classNames(styles.createEventButtonsDiv, className)}>
        <Container top="sm" fullWidth={false}>
          <Button
            data-testid="create-event-button"
            size={size}
            disabled={trialExpired}
            id={id}
            aria-label={t("add")}
            onClick={handleOpenClose}
            ref={reference}
          >
            {label || t("common:button.create.event")}
          </Button>
        </Container>
      </Box>
      {open && unlimitedEventsSettings?.concurrent && (
        <ClickAwayListener
          onClickAway={handleOpenClose}
          mouseEvent="onMouseDown"
        >
          <div
            ref={floating}
            style={{
              position: strategy,
              top: y ?? "",
              left: x ?? "",
              zIndex: 10,
            }}
            data-testid="create-event-popover"
          >
            <Box className={styles.createButtonPopover}>
              <List dense className={styles.createEventList}>
                <ListItem
                  button
                  className={styles.createEventItem}
                  onClick={handleCreateEventClick}
                  data-testid="create-event-popover-option"
                >
                  <ListItemText>
                    <Typography className={styles.createEventHeading}>
                      {t("event:remo.event")}
                    </Typography>
                    <Typography className={styles.createEventDescription}>
                      {t("event:remo.event.description")}
                    </Typography>
                  </ListItemText>
                  <ListItemSecondaryAction className={styles.arrowButton}>
                    <ArrowForwardIos fontSize="inherit" />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem
                  button
                  className={styles.createEventItem}
                  onClick={handleCreateSpaceClick}
                  data-testid="create-space-popover-option"
                  disabled={
                    unlimitedEvents.length >= unlimitedEventsSettings.concurrent
                  }
                >
                  <ListItemText>
                    <Typography className={styles.createEventHeading}>
                      {t("event:remo.space")} ({unlimitedEvents.length}/
                      {unlimitedEventsSettings.concurrent})
                    </Typography>
                    <Typography className={styles.createEventDescription}>
                      {t("event:remo.space.description")}
                    </Typography>
                  </ListItemText>
                  <ListItemSecondaryAction className={styles.arrowButton}>
                    <ArrowForwardIos
                      fontSize="inherit"
                      color={
                        unlimitedEvents.length >=
                        unlimitedEventsSettings.concurrent
                          ? "disabled"
                          : "inherit"
                      }
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Box>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};

export default CreateEventButton;
