import { useState, useRef, useCallback, memo, MouseEvent } from "react";
import { useSelector } from "react-redux";
import Picker, { IEmojiData } from "emoji-picker-react";
import { ClickAwayListener } from "@remo-co/ui-core/src/components/ClickAwayListener";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { Popper } from "@remo-co/ui-core/src/components/Popper";
import { EmojiEmotions as EmojiEmotionsIcon } from "@remo-co/ui-core/src/icons/EmojiEmotions";
import { selectIsBroadcasting } from "modules/broadcast/redux/selectors";
import useStyles from "./styles";

interface Props {
  onEmojiPicked(emoji: string): void;
}

const EmojiButton = ({ onEmojiPicked }: Props): JSX.Element => {
  const isBroadcasting = useSelector(selectIsBroadcasting);
  const styles = useStyles({ isBroadcasting: Boolean(isBroadcasting) });
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement | null>(null);

  const handleEmojiClick = useCallback(
    (_: MouseEvent, data: IEmojiData) => {
      setOpen(false);
      onEmojiPicked(data.emoji);
    },
    [setOpen, onEmojiPicked],
  );

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={styles.container}>
        <Popper
          open={open}
          keepMounted
          disablePortal
          anchorEl={anchorEl.current}
          placement="top"
          style={{
            zIndex: 1,
          }}
          popperOptions={{
            modifiers: [
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  boundariesElement: "scrollParent",
                },
              },
            ],
          }}
        >
          <Picker native onEmojiClick={handleEmojiClick} />
        </Popper>
        <IconButton
          ref={anchorEl}
          onClick={() => setOpen(!open)}
          disableRipple
          size="large"
          className={styles.iconButton}
        >
          <EmojiEmotionsIcon />
        </IconButton>
      </div>
    </ClickAwayListener>
  );
};

export default memo(EmojiButton);
