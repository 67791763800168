import { ListItemIcon } from "@remo-co/ui-core/src/components/ListItemIcon";
import { MenuItem } from "@remo-co/ui-core/src/components/MenuItem";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useStyles } from "./styles";

interface Props {
  icon?: JSX.Element;
  text: string;
  id: string;
}

const ContextMenuItem = (props: Props): JSX.Element => {
  const { icon, text, id, ...rest } = props;
  const classes = useStyles();

  return (
    <MenuItem
      id={id}
      className={classes.contextMenuItem}
      disableRipple
      disableGutters
      {...rest}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <Typography color="textPrimary" variant="h4" noWrap>
        {text}
      </Typography>
    </MenuItem>
  );
};

export default ContextMenuItem;
