import { Theme } from "@mui/material/styles";
import { StyleRulesCallback } from "@mui/styles/withStyles";
import { FormTextInputProps } from "./FormTextInput";

export const styles: StyleRulesCallback<Theme, FormTextInputProps> = (
  theme: Theme,
) => ({
  formTextInput: {
    fontSize: 14,
    color: theme.palette.blue.dark,
    border: `1px solid ${theme.palette.events.grayPrimary} !important`,
    "&:focus-within": {
      border: `1px solid ${theme.palette.events.bluePrimary} !important`,
    },
    "& input::placeholder": {
      color: theme.palette.events.grayTertiary,
    },
  },
  formTextInputShrinkLabel: {
    fontSize: 14,
    color: "var(--TextDark) !important",
    transform: "translate(4px, -25px) scale(1) !important",
  },
  textField: {
    transition: "border 0.3s ease",
    "&.Mui-disabled": {
      border: `1px solid ${theme.palette.events.grayPrimary}`,
      pointerEvents: "none",
      opacity: 0.6,
    },
    "& fieldset": {
      border: "none",
    },
  },
});
