import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

interface Props {
  isBroadcasting?: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  colorInherit: {
    color: ({ isBroadcasting }) =>
      isBroadcasting
        ? theme.palette.events.blueSecondary
        : theme.palette.events.whiteTertiary,
  },
  errorIcon: {
    verticalAlign: "middle",
  },
  bcuAvatar: {
    width: "28px !important",
    height: "28px !important",
    "&.avatar-initial": { fontSize: "100%" },
    "&.map-av-remo": { left: "38px" },
  },
  raiseHandButton: {
    backgroundColor: "var(--TePapaGreen)",
    width: "40px",
    height: "40px",
    padding: "0",
    borderRadius: "50%",
    border: "none",
    marginRight: "10px",
    "&:hover": { cursor: "pointer", backgroundColor: "#1B3125" },
    "& img": { width: "24px", height: "24px" },
  },
  userName: {
    maxWidth: "160px",
    maxHeight: "45px",
    display: "-webkit-box",
    webkitLineClamp: "2",
    webkitBoxOrient: "vertical",

    "& > span": {
      maxWidth: "160px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    margin: 0,
    marginBottom: "4px",
  },
  listItem: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  buttonBackground: ({ isBroadcasting }) => ({
    background: isBroadcasting
      ? theme.palette.white
      : theme.palette.events.blueSecondary,
    "&:hover": {
      background: isBroadcasting
        ? theme.palette.white
        : theme.palette.events.blueSecondary,
    },
    color: isBroadcasting
      ? theme.palette.events.blueSecondary
      : theme.palette.white,
    "&:disabled": {
      color: theme.palette.events.grayTertiary,
      background: isBroadcasting
        ? theme.palette.white
        : theme.palette.events.grayPrimary,
    },
    marginRight: theme.spacing(1),
  }),
}));
