import { useI18n } from "i18n";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { useMemo } from "react";
import TicketingSVG from "../../assets/ticketing.svg";
import { useStyles } from "./styles";

interface Props {
  onCreateClick: () => void;
  disabled: boolean;
  eventId?: string;
}

export const EmptyState = ({ onCreateClick, disabled, eventId }: Props) => {
  const { t } = useI18n(["common", "eventForm"]);
  const styles = useStyles();

  const toolTipTitle = useMemo(() => {
    if (!eventId) {
      return "eventForm:disabled.add.ticket.button.tooltip";
    }
    if (disabled) {
      return "eventForm:ticket.disabled.not.upcoming";
    }
    return "";
  }, [disabled, eventId]);

  return (
    <div className={styles.container}>
      <img src={TicketingSVG} alt={t("audience.view.toggle")} />
      <Typography variant="body1" className={styles.text}>
        {t("eventForm:no.tickets.added.yet")}
      </Typography>
      <Typography variant="body1" className={styles.text}>
        {t("eventForm:create.first.ticket")}
      </Typography>
      <Tooltip placement="bottom" arrow title={t(toolTipTitle)}>
        <span>
          <Button
            variant="secondary"
            color="blue"
            size="sm"
            disabled={disabled}
            contentClassName={styles.content}
            data-testid="create-ticket-button-empty-state"
            onClick={onCreateClick}
            theme="light"
          >
            {t("eventForm:add.ticket")}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};
