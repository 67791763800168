export const styles = {
  container: {
    cursor: "pointer",
    color: "text.primary",
  },
  textContainer: {
    minWidth: 120,
  },
  button: {
    marginLeft: 3,
  },
  mobileText: {
    marginLeft: 4,
  },
};
