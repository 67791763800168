import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  description: {
    color: theme.palette.events.lightBlack,
  },
  helpLinkButton: {
    marginTop: theme.spacing(2),
    textDecoration: "none",
    fontSize: "16px",
    color: theme.palette.events.blueSecondary,
    "&:hover": {
      color: theme.palette.events.blueAccent2,
    },
  },
  heading: {
    marginBottom: "16px",
  },
}));

export default useStyles;
