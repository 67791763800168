import { useState, ChangeEvent, useEffect, useMemo, useContext } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogActions } from "@remo-co/ui-core/src/components/DialogActions";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import { useI18n } from "i18n";
import {
  ContentWithMapping,
  EventContentMediaType,
  EventContentType,
} from "graphql/generated";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useEventContentActions } from "../../hooks/useEventContentActions";
import {
  ContentMapping,
  ContentWithConsolidatedMapping,
  ContentFormState,
} from "../../types";
import { MediaContentField } from "../MediaContentField";
import {
  getMappingLocation,
  validateContent,
  validateContentMapping,
} from "../../utils";
import { DisplayTypeRadioButtons } from "../DisplayTypeRadioButtons";
import { MediaTypeRadioButtons } from "../MediaTypeRadioButtons";
import { FloorPlanImageField } from "../FloorPlanImageField";
import { CTAButtonConfig } from "../CTAButtonConfig";
import { ContentLocation } from "../ContentLocation";
import { useStyles } from "./styles";
import { ValidatedContentData } from "../../utils/validateContent";
import { HideOpenInNewTabButtonCheckbox } from "../HideOpenInNewTabButtonCheckbox";

interface Props {
  preloadedContent?: ContentWithConsolidatedMapping | null;
  defaultMapping: ContentMapping;
  limit: number;
  existingContent: ContentWithMapping[];
  close: () => void;
}

export const EventContentForm = ({
  preloadedContent,
  defaultMapping,
  limit,
  existingContent,
  close,
}: Props) => {
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const { state } = useContext(MANAGE_EVENT_CONTEXT);

  const { eventData } = state ?? {};
  const theater = eventData?.theaters?.[0];
  const floors = useMemo(() => theater?.spaces ?? [], [theater?.spaces]);

  const { createEventContent, updateEventContent, isLoading } =
    useEventContentActions(eventData?.id);

  const [contentState, setContentState] = useState<Partial<ContentFormState>>({
    mediaType: EventContentMediaType.Video,
    type: EventContentType.EventFloor,
  });
  const [contentMapping, setContentMapping] = useState<ContentMapping>({
    floors: [],
    slot: "0",
  });
  const [contentMappingErrors, setContentMappingErrors] = useState<
    string[] | null
  >(null);
  const { t } = useI18n(["eventForm", "common"]);
  const styles = useStyles();

  useEffect(
    () => {
      if (!preloadedContent) {
        setContentMapping(defaultMapping);

        return;
      }

      const {
        __typename: _,
        mappings: __,
        ...preloadedContentData
      } = preloadedContent;

      setContentState(preloadedContentData);
      setContentMapping(getMappingLocation(preloadedContent, floors));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setContentState({
      ...contentState,
      [event.target.name]: event.target.value,
    });
  };

  const handleHideOpenInNewTabButtonChange = (
    hideOpenInNewTabButton: boolean,
  ) => {
    setContentState({
      ...contentState,
      hideOpenInNewTabButton,
    });
  };

  const handleMediaTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setContentState({
      ...contentState,
      mediaType: event.target.value as EventContentMediaType,
      // reset media everytime type is changed
      media: "",
    });
  };

  const handleImageUploadChange = (field: string, imageURL?: string) => {
    // image uploader is a non-React component for some reason - functional update here intentional
    setContentState((prevState) => ({
      ...prevState,
      [field]: imageURL,
    }));
  };

  const handleDisplayTypeChange = (type: EventContentType) => {
    if (type === EventContentType.EventFloor) {
      setContentState({
        ...contentState,
        type,
        // reset these fields if type is event floor
        floorImage: null,
        ctaLink: null,
        ctaText: null,
      });
    } else {
      setContentState({
        ...contentState,
        type,
      });
    }
  };

  const handleMappingChange = (floors: string[], slot: string) => {
    const mapping = {
      floors,
      slot,
    };
    setContentMapping(mapping);

    const errors = Object.entries(
      validateContentMapping(mapping, limit, existingContent, preloadedContent),
    )
      .filter((entry) => entry[1])
      .map((error) => {
        switch (error[0]) {
          case "exceedsLimit":
            return t("eventForm:mapping.error.insufficient.slots");

          case "slotsAreOccupied":
            return t("eventForm:mapping.error.existing.content");

          default:
            return t("eventForm:content.setting.error");
        }
      });

    setContentMappingErrors(errors.length ? errors : null);
  };

  const resetCTAConfig = () => {
    setContentState({
      ...contentState,
      ctaLink: null,
      ctaText: null,
    });
  };

  const handleSubmit = async () => {
    if (contentMappingErrors) {
      addErrorNotification({
        message: t("eventForm:content.mapping.error"),
      });

      return;
    }

    let content: ValidatedContentData | null = null;

    try {
      content = validateContent(contentState);
    } catch (error) {
      addErrorNotification({
        message: t("eventForm:content.form.error"),
      });
    }

    if (!content) {
      return;
    }

    let status: boolean;

    if (!preloadedContent) {
      status = await createEventContent(content, contentMapping);
    } else {
      status = await updateEventContent(
        preloadedContent.id,
        content,
        contentMapping,
        preloadedContent.mappings,
      );
    }

    if (status) {
      addSuccessNotification({
        message: t(
          preloadedContent
            ? "eventForm:content.updated.successfully"
            : "eventForm:content.created.successfully",
        ),
      });
      close();
    }
  };

  return (
    <Dialog
      data-testid="popup-dialog"
      onClose={close}
      aria-labelledby={t("eventForm:add.content")}
      fullWidth
      open
      classes={{
        paper: styles.root,
      }}
    >
      <DialogTitle className={styles.header}>
        <Typography variant="h3">
          {t(
            preloadedContent
              ? "eventForm:edit.content"
              : "eventForm:add.content",
          )}
        </Typography>
        <IconButtonWithTooltip
          id="close-popup-dialog"
          title={t("common:button.close")}
          data-testid="cancel-button-icon"
          onClick={close}
          size="small"
          className={styles.closeButton}
        >
          <Close />
        </IconButtonWithTooltip>
      </DialogTitle>
      <DialogContent>
        <Input
          size="sm"
          fullWidth
          getRemainingCharsMessage={(key) =>
            t("common:character.remaining", { key })
          }
          label={t("eventForm:content.name")}
          name="name"
          placeholder={t("eventForm:enter.your.content.name")}
          value={contentState.name}
          onChange={handleChange}
          inputProps={{ maxLength: 30, "data-testid": "content-name" }}
          className={styles.contentNameInput}
          theme="light"
        />
        <Typography variant="h5" className={styles.contentTypeLabel}>
          {t("eventForm:content.type")}
        </Typography>
        <MediaTypeRadioButtons
          mediaType={contentState.mediaType}
          handleChange={handleMediaTypeChange}
        />
        <MediaContentField
          media={contentState.media}
          mediaType={contentState.mediaType}
          handleChange={handleChange}
          handleImageMediaChange={handleImageUploadChange}
        />
        <DisplayTypeRadioButtons
          displayType={contentState.type}
          handleChange={handleDisplayTypeChange}
        />
        <HideOpenInNewTabButtonCheckbox
          displayType={contentState.type}
          mediaType={contentState.mediaType}
          checked={!contentState.hideOpenInNewTabButton}
          handleChange={handleHideOpenInNewTabButtonChange}
        />
        {contentState.type !== EventContentType.EventFloor && (
          <>
            <FloorPlanImageField
              floorImage={contentState.floorImage}
              handleFloorPlanImageChange={handleImageUploadChange}
            />
            <CTAButtonConfig
              ctaLink={contentState.ctaLink}
              ctaText={contentState.ctaText}
              handleChange={handleChange}
              resetCTAConfig={resetCTAConfig}
            />
          </>
        )}
        <ContentLocation
          mapping={contentMapping}
          handleChange={handleMappingChange}
          errors={contentMappingErrors}
        />
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button
          variant="secondary"
          color="blue"
          loading={isLoading}
          onClick={handleSubmit}
          theme="light"
        >
          {t("eventForm:save.content")}
        </Button>
        <Button
          variant="tertiary"
          color="blue"
          disabled={isLoading}
          onClick={close}
          theme="light"
        >
          {t("common:button.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
