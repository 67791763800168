import { ReactElement, ReactNode } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import classNames from "classnames";
import { useStyles } from "./styles";

interface Props {
  active?: boolean;
  children: ReactNode;
  onClick: () => void;
}

const Option = ({ active = false, children, onClick }: Props): ReactElement => {
  const classes = useStyles();

  return (
    <Button
      size="md"
      color="gray"
      className={classNames(classes.pollOption, {
        [classes.activePollOption]: active,
      })}
      onClick={onClick}
      contentWrapperClassName={classes.optionContentWrapper}
    >
      <Typography variant="body1">{children}</Typography>
    </Button>
  );
};

export default Option;
