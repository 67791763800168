import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "600px",
    borderRadius: "5px",
  },
  header: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    background: theme.palette.events.grayPrimary,
  },
  closeButton: {
    position: "absolute",
    top: "0px",
    right: theme.spacing(2),
    color: theme.palette.events.blueSecondary,
  },
  dialogDescription: {
    marginTop: theme.spacing(2),
  },
  actions: {
    justifyContent: "center",
    gap: "14px",
    padding: `${theme.spacing(0.5)} ${theme.spacing(2.5)}`,
  },
  button: {
    margin: "8px",
    gap: "10px",
  },
}));
