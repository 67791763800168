import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  description: {
    color: theme.palette.events.lightBlack,
    paddingRight: "32px",
  },
  link: {
    color: theme.palette.events.lightBlack,
    textDecoration: "underline",
  },
  note: {
    marginTop: theme.spacing(2),
    color: theme.palette.events.lightBlack,
  },
  helpLinkButton: {
    marginTop: theme.spacing(2),
    textDecoration: "none",
  },
  helpLinkButtonContent: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20.83px",
    color: theme.palette.events.blueSecondary,
  },
  emptyState: {
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#FAFAFA",
    marginTop: theme.spacing(2),
    maxWidth: "36rem",
    borderRadius: "10px",
  },
  emptyStateText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    maxWidth: "36rem",
    color: "rgba(72, 74, 86, 0.7)",
  },
}));
