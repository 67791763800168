import i18n from "i18next";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "i18n";
import { useAppDispatch } from "store/hooks";
import { selectCurrentEventId } from "modules/event/selectors";
import { DialogType } from "modules/dialogNotification/constants";
import { acceptInviteToTableRequest } from "modules/inviteToTable/request";
import { playInviteSound } from "helpers/audioHelper";
import { IRoomBase } from "types";
import { Errors, trackError } from "modules/monitoring";
import { selectCurrentRoomId } from "modules/room/redux/selectors";
import { selectCurrentSpaceId } from "modules/space/redux/selectors";
import logger from "logging/logger";
import { IUser } from "modules/app/types";
import { getUserName } from "modules/userProfile";
import { useUserData } from "modules/audioVideo/hooks";
import { addErrorNotification } from "modules/notification/redux/notificationSlice";
import { getErrorMessage } from "modules/joinTable/errorHandler";
import { useFloorNames } from "modules/floors/hooks/useFloorNames";
import { JoinRoomStatus, updateRoomStatus } from "store/rooms/roomsSlice";
import {
  selectCurrentTheaterId,
  selectTheaterSpaces,
} from "modules/theater/selectors";
import { sendBrowserNotification } from "services/pushNotificationService/BrowserNotificationsService";
import { useClearFloorDataAndRegisterListeners } from "modules/joinFloor/hooks/useClearFloorDataAndRegisterListeners";
import { selectRooms } from "store/rooms/selectors";
import {
  addDialogNotification,
  closeDialogNotificationsByType,
} from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useStyles } from "./styles";

interface NotifyProps {
  fromUser: IUser;
  targetTable: IRoomBase;
  inviteId: string;
  targetFloorId: string;
  notifyDialogType?: string;
}

interface DialogCopyProps {
  targetTable: IRoomBase;
  fromUser: IUser;
  floorName: string;
  notifyDialogType?: string;
}

interface Dialog {
  dialogType: string;
  message: string;
}

const getDialogCopy = ({
  targetTable,
  fromUser,
  notifyDialogType,
  floorName,
}: DialogCopyProps): Dialog => {
  if (targetTable.code === "stage") {
    return {
      dialogType: DialogType.JOIN_STAGE,
      message: i18n.t("invited.greenroom", {
        who: getUserName(fromUser),
        where: floorName,
      }),
    };
  }

  switch (notifyDialogType) {
    case DialogType.JOIN_ROOM_NETWORKING:
      return {
        dialogType: DialogType.JOIN_ROOM_NETWORKING,
        message: i18n.t("invite.user.to.room", {
          who: getUserName(fromUser),
          where: targetTable.name,
        }),
      };

    default:
      return {
        dialogType: DialogType.JOIN_ROOM,
        message: i18n.t("invited.room", {
          who: getUserName(fromUser),
          where: `${floorName} - ${targetTable.name}`,
        }),
      };
  }
};

export const useAcceptInviteToTable = () => {
  const currentTableId = useSelector(selectCurrentRoomId);
  const eventId = useSelector(selectCurrentEventId);
  const spaces = useSelector(selectTheaterSpaces);
  const currentFloorId = useSelector(selectCurrentSpaceId);
  const currentTheaterId = useSelector(selectCurrentTheaterId);
  const rooms = useSelector(selectRooms);
  const floorNames = useFloorNames(currentTheaterId);
  const dispatch = useAppDispatch();
  const { clearFloorDataAndRegisterListeners } =
    useClearFloorDataAndRegisterListeners();
  const { t } = useI18n(["template"]);
  const styles = useStyles();

  const { setConversationId } = useUserData();

  const acceptInvitationRequest = useCallback(
    async (
      targetTableId: IRoomBase,
      inviteId: string,
      targetFloorId: string,
    ) => {
      if (currentTableId === targetTableId.id) {
        logger.info(
          `[useAcceptInviteToTable] User is already in room ${currentTableId}.`,
        );
        return;
      }
      logger.info(
        `[useAcceptInviteToTable] User accepted invitation to room ${targetTableId.id}.`,
      );

      if (!eventId) {
        trackError(new Error("No Event"), {
          label: Errors.ACCEPT_TABLE_INVITE,
        });
        return;
      }

      if (!currentFloorId) {
        trackError(new Error("No Current Floor"), {
          label: Errors.ACCEPT_TABLE_INVITE,
        });
        return;
      }

      if (!currentTableId) {
        trackError(new Error("No Current Table"), {
          label: Errors.ACCEPT_TABLE_INVITE,
        });
        return;
      }

      try {
        dispatch(updateRoomStatus(JoinRoomStatus.IN_PROGRESS));
        const { tableId } = await acceptInviteToTableRequest({
          eventId,
          inviteId,
          currentFloorId,
          currentTableId,
        });
        setConversationId({ conversationId: tableId });
        if (currentFloorId !== targetFloorId) {
          clearFloorDataAndRegisterListeners(targetFloorId);
        }
        dispatch(updateRoomStatus(JoinRoomStatus.DONE));
      } catch (error) {
        const errorMessage = getErrorMessage((error as Error).message);

        if (errorMessage) {
          dispatch(
            addErrorNotification({
              message: t(errorMessage),
            }),
          );
        }

        trackError(error, {
          label: Errors.ACCEPT_TABLE_INVITE,
        });
      }
    },
    [
      currentTableId,
      eventId,
      currentFloorId,
      dispatch,
      setConversationId,
      clearFloorDataAndRegisterListeners,
      t,
    ],
  );

  const notifyUser = useCallback(
    ({
      fromUser,
      targetTable,
      inviteId,
      targetFloorId,
      notifyDialogType,
    }: NotifyProps) => {
      const room = Object.values(rooms).find(
        ({ code }) => code === targetTable.code,
      );

      if (!room) {
        trackError(new Error(`Room ${targetTable.code} not found.`), {
          label: Errors.ACCEPT_TABLE_INVITE,
        });
        return;
      }

      if (currentTableId === targetTable.id) {
        logger.info(
          `[onReceivingInviteToRoom] user already in room ${currentTableId}`,
        );
        return;
      }

      const floorNumber = targetFloorId
        ? spaces.findIndex((space) => space === targetFloorId) + 1
        : null;

      const { dialogType, message } = getDialogCopy({
        targetTable,
        fromUser,
        floorName:
          floorNames[targetFloorId] ??
          t("event:top.bar.floor", { floor: floorNumber }),
        notifyDialogType,
      });

      playInviteSound();

      dispatch(closeDialogNotificationsByType(dialogType));
      dispatch(
        addDialogNotification({
          type: dialogType,
          message,
          title: t("event:accept.invite"),
          image: fromUser.profile.picture,
          user: fromUser,
          onConfirm: () =>
            acceptInvitationRequest(targetTable, inviteId, targetFloorId),
          confirmText: t("join"),
          hideCloseButton: true,
          contentClassName: styles.content,
        }),
      );

      sendBrowserNotification(message, {
        icon: fromUser.profile.picture?.replace("sz=50", "sz=100"),
      });
    },
    [
      acceptInvitationRequest,
      currentTableId,
      dispatch,
      floorNames,
      rooms,
      spaces,
      styles.content,
      t,
    ],
  );

  return { notifyUser, acceptInvitationRequest };
};
