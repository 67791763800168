import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "2rem !important",
    position: "relative",
  },
  pagination: {
    display: "flex",
    maxWidth: "20%",
    marginLeft: "40%",
    justifyContent: "space-between",
  },
  centeredPagination: {
    display: "flex",
    maxWidth: "40%",
    marginLeft: "25%",
    justifyContent: "space-between",
    marginTop: "-1.75rem",
  },
  paginationButtonContent: {
    alignItems: "center",
    display: "flex",
    color: theme.palette.events.blueSecondary,
    padding: "0 10px",
  },
  buttonIcon: {
    height: "16px",
  },
  paginationButton: {
    borderRadius: "5px",
  },
}));
