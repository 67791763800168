import { useContext } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { ManageEventState } from "modules/manageEvent/hooks/types";
import { useI18n } from "i18n";
import { EventContentSettings } from "modules/eventContent";

export const FloorContentTab = (): JSX.Element => {
  const { t } = useI18n(["manageEvent"]);
  const { state } = useContext(MANAGE_EVENT_CONTEXT);
  const { eventData = null } = state as Partial<ManageEventState>;

  return (
    <Box>
      <Typography variant="h2">{t("manageEvent:floor.content")}</Typography>
      <EventContentSettings eventId={eventData?.id} />
    </Box>
  );
};
