import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: `20px 10px 20px 10px`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(2),
    borderRadius: "10px",
    gap: "20px",
    background: theme.palette.events.graySecondary,
    border: `1px solid ${theme.palette.events.grayPrimary}`,
  },
  text: {
    maxWidth: "36rem",
    color: theme.palette.events.lightBlack,
  },
  button: {
    height: "unset",
    marginTop: theme.spacing(2),
  },
  content: {
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing(1.5)} ${theme.spacing(5)}`,
  },
  icon: {
    fontSize: "14px",
  },
  steps: {},
  stepImages: {
    display: "flex",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
  },
  step1Image: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "150px",
  },
  step2Image: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "150px",
  },
  step3Image: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "120px",
  },
  stepText: {
    marginBottom: theme.spacing(0.5),
    color: theme.palette.black,
  },
}));
