import React from "react";
import { ActionButton } from "modules/actionButton";
import { useI18n } from "i18n";
import { validateURL } from "helpers/urlHelper";
import { getVar } from "config";
import { useSelector } from "react-redux";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import useEnterLeaveEvent from "modules/event/hooks/useEnterLeaveEvent";
import { useIsInEvent } from "modules/event";
import { Actions, trackAction } from "modules/monitoring";
import { selectExitDoorUrl } from "modules/companySettings/redux/selectors";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useAppDispatch } from "store/hooks";
import { ReactComponent as LeaveEventIcon } from "./icons/leave-event-icon.inline.svg";

export const SHOW_MIC_CAM_PAGE = "show-mic-cam-page";

const LeaveEventButton = ({ darkMode = false }) => {
  const { leaveEvent } = useEnterLeaveEvent();
  const dispatch = useAppDispatch();
  const { t } = useI18n();
  const exitURL = useSelector(selectExitDoorUrl);
  const isInBroadcast = useSelector(selectIsInBroadcast);

  const isInEvent = useIsInEvent();

  const exit = React.useCallback(() => {
    sessionStorage.setItem(SHOW_MIC_CAM_PAGE, "false");

    if (isInEvent) {
      leaveEvent();
    }

    if (validateURL(exitURL)) {
      if (!exitURL.startsWith("http")) {
        window.open(`http://${exitURL}`, "_self", "noopener");
      } else {
        window.open(exitURL, "_self", "noopener");
      }
    } else {
      // Open default exit page
      window.open(getVar("REACT_APP_EXIT_PAGE"), "_self", "noopener");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInEvent, exitURL]);

  const handleLeaveEvent = React.useCallback(() => {
    const onDismiss = () => {
      if (isInEvent) {
        leaveEvent();
      }
      trackAction(Actions.EVENT_REJOIN);
      window.location.reload();
    };

    dispatch(
      addDialogNotification({
        hideCloseButton: false,
        message: t("about.to.leave.message"),
        title: t("are.you.sure"),
        confirmBtnId: "quit-confirm",
        dismissBtnId: "quit-dismiss",
        onConfirm: exit,
        confirmText: t("confirm.text.leave.option"),
        dismissText: t("confirm.text.rejoin.option"),
        onDismiss,
        className: isInBroadcast ? "dark" : "",
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInBroadcast, exit]);

  return (
    <ActionButton
      title={t("leave.event")}
      onClick={handleLeaveEvent}
      label={t("quit.event")}
      state={darkMode ? "default" : "exit"}
      icon={<LeaveEventIcon />}
      darkMode={darkMode}
    />
  );
};

export default LeaveEventButton;
