import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
  },
  clickableTab: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.white,
    boxSizing: "border-box",
    margin: `0 ${theme.spacing(0.5)} ${theme.spacing(0.5)} 0`,
    cursor: "pointer",
    height: "36px",
    padding: "6px 20px 6px 20px",
    borderRadius: "100px",
    fontSize: "14px",
    border: `1px solid ${theme.palette.events.bluePrimary}`,
    color: theme.palette.events.bluePrimary,
    whiteSpace: "nowrap",
  },
  clickableTabSelected: {
    color: theme.palette.white,
    background: theme.palette.events.bluePrimary,
  },
  title: {
    marginBottom: "10px",
  },
  description: {
    color: theme.palette.blue.dark,
    fontSize: theme.typography.body1.fontSize,
  },
  publishButtonBox: {
    display: "flex",
    justifyContent: "center",
  },
}));
