import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

type StyleProps = { isCompanyOwner?: boolean };

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  expectedAttendeeWarningContainer: {
    display: "flex",
    position: "absolute",
    backgroundColor: "#FEFFE1",
    width: (props = { isCompanyOwner: false }) =>
      props.isCompanyOwner ? "560px" : "440px",
    borderRadius: "5px",
    boxShadow: "0px 1px 5px grey",
    fontWeight: theme.typography.fontWeightMedium,
    zIndex: 1,
    top: "50px",
    marginLeft: "-20px",
  },
  expectedAttendeeWarningTextDiv: {
    width: "360px",
    padding: "10px",
  },
  expectedAttendeeWarningAddGuestsBtn: {
    height: "30px",
    whiteSpace: "nowrap",
  },
  formHelperText: {
    fontSize: "10px",
    fontWeight: 900,
    color: theme.palette.events.bluePrimary,
    marginTop: "10px",
  },
  selectField: {
    height: "55px",
  },
}));
