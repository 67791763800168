import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const CONTAINER_HEIGHT = 30;

interface Props {
  isChangingTable: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  joinTableDialogContainer: {
    position: "absolute",
    display: "flex",
    top: -CONTAINER_HEIGHT - 10,
    alignItems: "center",
    width: "80%",
    maxWidth: "650px",
    borderRadius: 5,
    backgroundColor: theme.palette.events.blueSecondary,
    color: theme.palette.white,
    fontSize: "14px",
    padding: "0px 0px 0px 10px",
    height: CONTAINER_HEIGHT,
  },
  text: {
    width: "60%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  buttonsContainer: {
    width: "60%",
    display: "flex",
  },
  button: ({ isChangingTable }) => ({
    height: "30px",
    color: theme.palette.events.whiteTertiary,
    minWidth: "25%",
    flex: "1 1 auto",
    borderLeft: `1px solid ${theme.palette.events.whiteTertiary}`,
    cursor: isChangingTable ? "default" : "pointer",
    backgroundColor: isChangingTable
      ? theme.palette.gray.disabled
      : theme.palette.events.blueSecondary,
    "&:hover": {
      backgroundColor: isChangingTable
        ? theme.palette.gray.disabled
        : theme.palette.events.blueSecondary,
      color: theme.palette.events.whiteTertiary,
    },
  }),
  buttonContent: {
    fontWeight: 400,
  },
  rightRadius: {
    borderRadius: "0px 5px 5px 0px",
  },
  noRadius: {
    borderRadius: 0,
  },
}));
