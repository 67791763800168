import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "zIndexes.module.scss";

export const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: zIndexes.modalZIndex,
  },
  container: {
    border: "none",
    maxWidth: "350px",
    width: "292px",
    borderRadius: "5px",
    backgroundColor: theme.palette.white,
    padding: "10px",
  },
  text: {
    cursor: "pointer",
    width: "inherit",
    textAlign: "right",
    fontWeight: 700,
    color: theme.palette.events.blueSecondary,
    paddingRight: theme.spacing(1),
  },
  innerContainer: {
    display: "grid",
    gap: "10px",
  },
  breakdown: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(0.25),
  },
  breakdownText: {
    display: "flex",
    fontSize: "12px",
  },
  noteContainer: {
    border: "none",
    boxShadow: "none",
    padding: `0 ${theme.spacing(1)}`,
  },
  noteTitle: {
    marginBottom: theme.spacing(0.5),
    fontSize: "12px",
  },
  note: {
    display: "flex",
  },
  noteIcon: {
    width: "16px",
    height: "16px",
    marginRight: theme.spacing(0.5),
    color: theme.palette.blue.main,
  },
  noteText: {
    fontSize: "10px",
    lineHeight: "normal",
  },
  indent: {
    marginRight: theme.spacing(1.5),
  },
  indentPlus: {
    marginRight: theme.spacing(0.5),
  },
}));
