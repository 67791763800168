import { Button } from "@remo-co/ui-core/src/components/Button";
import { useI18n } from "i18n";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { useStyles } from "./style";

interface Props {
  handleStreamDialog: () => void;
  showContactSales: boolean;
}

export const AddLiveStream = ({
  handleStreamDialog,
  showContactSales,
}: Props): JSX.Element => {
  const { t } = useI18n(["manageEvent, settings"]);
  const styles = useStyles();
  return (
    <div className="mar-top-40">
      <Tooltip
        placement="bottom"
        arrow
        title={
          showContactSales ? t("manageEvent:need.another.rtmp.description") : ""
        }
      >
        <Button
          variant="secondary"
          color="blue"
          size="md"
          disabled={showContactSales}
          onClick={handleStreamDialog}
          data-testid="add-rtmp-destination"
          className={styles.addStreamButton}
          theme="light"
        >
          {t("manageEvent:add.rtmp.destination")}
        </Button>
      </Tooltip>
    </div>
  );
};
