import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "500px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(0.5),
    right: theme.spacing(4),
  },
  header: {
    padding: `${theme.spacing(2.5)}`,
  },
  actions: {
    padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
    justifyContent: "space-between",
  },
  inputRow: {
    display: "flex",
    gap: theme.spacing(2.5),
  },
  titleInputContainer: {
    flex: "1 1 75%",
  },
  titleInput: {
    width: "313px",
  },
  quantityInputContainer: {
    flex: "1 1 25%",
  },
  quantityInput: {
    width: "117px",
  },
  priceInputAdornment: {
    padding: theme.spacing(2),
    borderRight: `1px solid black`,
  },
  dateTimePickerRow: {
    display: "flex",
  },
  dateTimePicker: {
    paddingBottom: "0",
    marginTop: "5px",
  },
  dateTimePickerInput: {
    border: `1px solid ${theme.palette.blue.darkShade1}`,
    borderRadius: 5,
    height: "16px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  startTime: {
    flex: "1",
    paddingRight: "1.25rem",
  },
  endTime: {
    flex: "1",
  },
  radioGroup: {
    marginLeft: theme.spacing(1),
  },
  radio: {
    padding: theme.spacing(0.5),
  },
  salesScheduleTitle: {
    marginTop: theme.spacing(1.5),
  },
  description: {
    marginTop: theme.spacing(1.5),
  },
}));
