import { forwardRef } from "react";
import { DarkInput, DarkInputProps } from "./DarkInput";
import { LightInput, LightInputProps } from "./LightInput";

export type InputProps = (LightInputProps | DarkInputProps) & {
  theme?: "light" | "dark";
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (inputProps, ref) => {
    const isLightTheme = localStorage.getItem("theme") === "LIGHT";
    const { theme, ...props } = inputProps;

    if (isLightTheme || theme === "light") {
      return <LightInput ref={ref} {...props} />;
    }

    return <DarkInput ref={ref} {...props} />;
  },
);
