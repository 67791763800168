import { useCallback } from "react";
import { useAppDispatch } from "store/hooks";
import * as sliceActions from "../redux/notificationSlice";

const useNotificationActions = () => {
  const dispatch = useAppDispatch();
  const addSuccessNotification = useCallback(
    (notification: sliceActions.NotificationState) => {
      dispatch(sliceActions.addSuccessNotification(notification));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch],
  );
  const addErrorNotification = useCallback(
    (notification: sliceActions.NotificationState) => {
      dispatch(sliceActions.addErrorNotification(notification));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch],
  );
  const addWarningNotification = useCallback(
    (notification: sliceActions.NotificationState) => {
      dispatch(sliceActions.addWarningNotification(notification));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch],
  );
  const addInfoNotification = useCallback(
    (notification: sliceActions.NotificationState) => {
      dispatch(sliceActions.addInfoNotification(notification));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch],
  );
  const removeNotification = useCallback((id: string | number) => {
    dispatch(sliceActions.removeNotification(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const removeAllNotifications = useCallback(() => {
    dispatch(sliceActions.removeAllNotifications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    addSuccessNotification,
    addErrorNotification,
    addWarningNotification,
    addInfoNotification,
    removeNotification,
    removeAllNotifications,
  };
};

export default useNotificationActions;
