import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme, { isBroadcasting: boolean }>((theme) => ({
  container: {
    "& .emoji-picker-react": {
      background: theme.palette.background.default,
      border: ({ isBroadcasting }) => (isBroadcasting ? "none" : ""),
      boxShadow: ({ isBroadcasting }) => (isBroadcasting ? "none" : ""),
      "& .emoji-group::before": {
        background: theme.palette.background.default,
        opacity: 0.95,
        color: theme.palette.text.primary,
      },
      "& .emoji-scroll-wrapper": {
        "&::-webkit-scrollbar": {
          width: "10px",
        },
        "&::-webkit-scrollbar-track": {
          background: theme.palette.background.default,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme.palette.background.secondary,
          borderRadius: "5px",
        },
      },
    },
    "& .emoji-categories": {
      "& button": {
        filter: ({ isBroadcasting }) => (isBroadcasting ? "invert(100%)" : ""),
      },
    },
    "&::after": {
      background: "var(--Mirage)",
    },
    "& .emoji-search": {
      background: "var(--White-op-010)",
      border: "none",
      boxSizing: "border-box",
      color: theme.palette.text.primary,

      "&:focus": {
        border: "none",
      },
    },
  },
  iconButton: ({ isBroadcasting }) => ({
    color: isBroadcasting
      ? theme.palette.white
      : theme.palette.events.blueSecondary,
  }),
}));

export default useStyles;
