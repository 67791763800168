import { Input } from "@remo-co/ui-core/src/components/Input";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { CheckBoxOutlineBlank } from "@remo-co/ui-core/src/icons/CheckBoxOutlineBlank";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { RadioButtonUnchecked } from "@remo-co/ui-core/src/icons/RadioButtonUnchecked";
import { useI18n } from "i18n";
import { useEffect, useState } from "react";
import { useStyles } from "./styles";

export const MAX_OPTION_LIMIT = 21;

interface Props {
  options: string[];
  onOptionsChange: (options: string[]) => void;
  multi?: boolean;
}

export const QuestionFormOptionList = ({
  options,
  onOptionsChange,
  multi,
}: Props) => {
  const { t } = useI18n(["eventForm", "common", "event"]);
  const [inputValues, setInputValues] = useState<string[]>([]);
  const styles = useStyles();

  useEffect(() => {
    setInputValues(
      options.length < MAX_OPTION_LIMIT ? [...options, ""] : [...options],
    );
  }, [options]);

  const updateOptions = (newInputValues: string[]) => {
    const updatedOptions = newInputValues.filter(
      (option) => option.trim() !== "",
    );
    onOptionsChange(updatedOptions);

    if (
      updatedOptions.length < 10 &&
      newInputValues.length === updatedOptions.length
    ) {
      newInputValues.push("");
    }

    setInputValues(newInputValues);
  };

  const handleInputChange = (index: number, value: string) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    updateOptions(newInputValues);
  };

  const handleDelete = (index: number) => {
    const newInputValues = inputValues.filter((_, i) => i !== index);
    updateOptions(newInputValues);
  };

  return (
    <div className={styles.root}>
      <Typography variant="body1" className={styles.title}>
        {t("event:options")}
      </Typography>
      <div className={styles.options}>
        {inputValues.map((value, index) => (
          <div className={styles.option}>
            {multi ? (
              <CheckBoxOutlineBlank className={styles.icons} />
            ) : (
              <RadioButtonUnchecked className={styles.icons} />
            )}
            <Input
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              value={value}
              size="sm"
              fullWidth
              placeholder={t("event:answer", {
                number: index + 1,
              })}
              getRemainingCharsMessage={(key) =>
                t("common:character.remaining", { key })
              }
              onChange={(e) => handleInputChange(index, e.target.value)}
              inputProps={{
                maxLength: value.length > 90 ? 100 : undefined,
                "data-testid": `option-field-${index}`,
              }}
              theme="light"
            />
            <IconButtonWithTooltip
              title={t("event:poll.delete.option")}
              id="delete-option-button"
              size="small"
              disabled={inputValues.length === 1 || value.trim() === ""}
              onClick={() => handleDelete(index)}
              data-testid="delete-option-button"
            >
              <Delete fontSize="small" className={styles.deleteIcon} />
            </IconButtonWithTooltip>
          </div>
        ))}
      </div>
    </div>
  );
};
