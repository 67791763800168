import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "560px",

    padding: "8px",
  },
  icon: {
    marginBottom: "2rem",
  },
  title: {
    maxWidth: "32rem",
    fontWeight: 500,
    fontSize: "16px",
    color: "#0e1f3c",
  },
  list: {
    margin: 0,
    textAlign: "left",
  },
  content: {
    marginTop: "1rem",
    color: theme.palette.black,
  },
}));
