export enum ThirdPartyService {
  DATADOG = "DataDog",
  DATADOG_INTAKE = "DataDog Intake",
  FRESHCHAT = "Freshchat",
  MIRO = "Miro",
  SEGMENT = "Segment",
  SPLIT = "Split",
}

export enum ReachabilityTest {
  FEATURE_TOGGLES = "Feature Toggles",
  SUPPORT_TROUBLESHOOTING = "Support & Troubleshooting",
  USAGE_ANALYTICS = "Usage Analytics",
  WHITEBOARD = "Whiteboard",
}

export const THIRD_PARTY_REACHABILITY_URLS: Record<ThirdPartyService, string> =
  {
    [ThirdPartyService.DATADOG]: "https://api.datadoghq.com",
    [ThirdPartyService.DATADOG_INTAKE]:
      "https://logs.browser-intake-datadoghq.com",
    [ThirdPartyService.FRESHCHAT]: "https://wchat.freshchat.com",
    [ThirdPartyService.SPLIT]: "https://sdk.split.io",
    [ThirdPartyService.SEGMENT]: "https://api.segment.io",
    [ThirdPartyService.MIRO]: "https://miro.com",
  };

export const REACHABILITY_TESTS: Record<ReachabilityTest, ThirdPartyService[]> =
  {
    [ReachabilityTest.USAGE_ANALYTICS]: [ThirdPartyService.SEGMENT],
    [ReachabilityTest.SUPPORT_TROUBLESHOOTING]: [
      ThirdPartyService.DATADOG,
      ThirdPartyService.DATADOG_INTAKE,
      ThirdPartyService.FRESHCHAT,
    ],
    [ReachabilityTest.FEATURE_TOGGLES]: [ThirdPartyService.SPLIT],
    [ReachabilityTest.WHITEBOARD]: [ThirdPartyService.MIRO],
  };

export const REACHABILITY_TESTS_MESSAGES: Record<ReachabilityTest, string> = {
  [ReachabilityTest.USAGE_ANALYTICS]:
    "micCamCheck:reachability.usage.analytics",
  [ReachabilityTest.FEATURE_TOGGLES]:
    "micCamCheck:reachability.feature.toggles",
  [ReachabilityTest.SUPPORT_TROUBLESHOOTING]:
    "micCamCheck:reachability.support.troubleshooting",
  [ReachabilityTest.WHITEBOARD]: "micCamCheck:reachability.whiteboard",
};

export const FIREBASE_TEST = "firebase.test";
export const FIRESTORE_TEST = "firestore.test";
export const FIRESTORAGE_TEST = "firestorage.test";

const RETRY_COUNT = 2;

export const firestoreRetryMethods = {
  retry(failureCount: number): boolean {
    const noOfRetries = process.env.NODE_ENV === "test" ? 0 : RETRY_COUNT;

    return failureCount !== noOfRetries;
  },
};
