import { useContext, useMemo, useState } from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Loader } from "@remo-co/ui-core/src/components/Loader";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { useI18n } from "i18n";
import { getMapTemplateByType } from "modules/event/template";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { useEventContentActions } from "modules/eventContent/hooks/useEventContentActions";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { consolidateContentMapping, getDefaultMapping } from "../../utils";
import { ContentWithConsolidatedMapping } from "../../types";
import { useEventContentSubscription } from "../../hooks/useEventContentSubscription";
import { ContentListItem } from "../ContentListItem";
import { EventContentForm } from "../EventContentForm";
import { NoContentPlaceholder } from "../NoContentPlaceholder";
import { useStyles } from "./styles";

interface Props {
  eventId?: string;
  limit: number;
  unsavedCapacityChanges: boolean;
  hasSponsors: boolean;
}

export const EventContentList = ({
  eventId,
  limit,
  unsavedCapacityChanges,
  hasSponsors,
}: Props) => {
  const { state } = useContext(MANAGE_EVENT_CONTEXT);
  const { unlimitedEventsSettings } = useCompanyPlanSettings();
  const { eventData } = state ?? {};
  const { isUnlimitedEvent } = eventData ?? {};
  const contentLimit = isUnlimitedEvent
    ? unlimitedEventsSettings?.contents || 6
    : limit;
  const { data, loading } = useEventContentSubscription({ eventId });

  const theater = eventData?.theaters?.[0];
  const mapTemplate = getMapTemplateByType(theater?.template);
  const floors = useMemo(() => theater?.spaces ?? [], [theater?.spaces]);

  const { deleteEventContent } = useEventContentActions(eventData?.id);

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [preloadedContent, setPreloadedContent] =
    useState<ContentWithConsolidatedMapping | null>(null);
  const { t } = useI18n(["eventForm", "url"]);
  const styles = useStyles();

  const handleContentUpdate = (content: ContentWithConsolidatedMapping) => {
    // strip out the hideOpenInNewTabButton property if it's null
    if (content.hideOpenInNewTabButton === null) {
      const { hideOpenInNewTabButton: _, ...rest } = content;
      setPreloadedContent(rest);
    } else {
      setPreloadedContent(content);
    }

    setIsFormVisible(true);
  };

  const handleContentDelete = async (
    content: ContentWithConsolidatedMapping,
  ) => {
    await deleteEventContent(content.id);
  };

  const handleContentFormClose = () => {
    setPreloadedContent(null);
    setIsFormVisible(false);
  };

  const consolidatedContents = useMemo(() => {
    if (data?.eventContent.__typename === "GQLError") {
      return [];
    }
    const { content: contents = [] } = data ? data.eventContent : {};

    return consolidateContentMapping(contents);
  }, [data]);

  const defaultMapping = useMemo(() => {
    if (data?.eventContent.__typename === "GQLError") {
      return { floors, slot: "0" };
    }
    const { content: contents = [] } = data ? data.eventContent : {};

    return getDefaultMapping(
      contents,
      floors,
      mapTemplate?.sponsors || [],
      contentLimit - contents.length,
    );
  }, [data, floors, contentLimit, mapTemplate?.sponsors]);

  if (data?.eventContent.__typename === "GQLError") {
    return null;
  }

  const { content: contents = [] } = data ? data.eventContent : {};

  return (
    <Container>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={styles.header}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h5" className={styles.headerText}>
            {t("eventForm:content.pieces", {
              current: contents.length,
              limit: contentLimit,
            })}
          </Typography>
          <Tooltip
            placement="bottom"
            arrow
            title={`${t("eventForm:content.pieces.tooltip")} ${
              isUnlimitedEvent
                ? ""
                : t("eventForm:content.pieces.tooltip.contact")
            }`}
          >
            <Info className={styles.tooltipIcon} color="primary" />
          </Tooltip>
        </Box>
        {!loading && Boolean(contents.length) && (
          <Button
            variant="secondary"
            color="blue"
            size="sm"
            theme="light"
            disabled={
              contents.length >= contentLimit ||
              unsavedCapacityChanges ||
              hasSponsors
            }
            contentClassName={styles.addContentButtonContent}
            onClick={() => setIsFormVisible(true)}
            data-testid="add-content-button"
          >
            {t("eventForm:add.content")}
          </Button>
        )}
      </Box>
      <Container className={styles.contents}>
        {loading && (
          <div className={styles.loading}>
            <Loader />
          </div>
        )}
        {!loading && !contents.length && (
          <NoContentPlaceholder
            eventId={eventId}
            showForm={() => setIsFormVisible(true)}
            unsavedCapacityChanges={unsavedCapacityChanges}
            hasSponsors={hasSponsors}
          />
        )}
        {!loading && Boolean(consolidatedContents.length) && (
          <Box
            display="flex"
            flexDirection="column"
            className={styles.contentList}
          >
            {consolidatedContents.map((content) => (
              <ContentListItem
                content={content}
                onUpdateButtonClick={handleContentUpdate}
                onDeleteButtonClick={handleContentDelete}
                unsavedCapacityChanges={unsavedCapacityChanges}
                hasSponsors={hasSponsors}
              />
            ))}
          </Box>
        )}
      </Container>
      {isFormVisible && (
        <EventContentForm
          defaultMapping={defaultMapping}
          preloadedContent={preloadedContent}
          limit={contentLimit}
          existingContent={contents}
          close={handleContentFormClose}
        />
      )}
    </Container>
  );
};
