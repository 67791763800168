import { useI18n } from "i18n";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import EventAnalytics from "modules/eventAnalytics";
import { IEvent } from "modules/event/types";
import { ExportAttendees } from "../ExportAttendees";
import ExportQA from "../ExportQA";
import GuestsEventRating from "../GuestsEventRating";
import { RecordingsContainer } from "../RecordingsContainer";
import { ExportPolls } from "../ExportPolls";
import { useStyles } from "./styles";

interface Props {
  eventData: IEvent;
}

export const PostEvent = ({ eventData }: Props): JSX.Element => {
  const { t } = useI18n(["common", "eventForm"]);
  const { isUnlimitedEvent, id } = eventData;
  const styles = useStyles();

  return (
    <Box className={styles.container}>
      <Typography variant="h2" className="pad-bottom-20">
        {t("eventForm:post.event.title")}
      </Typography>
      <Box className={styles.contentContainer}>
        <GuestsEventRating eventId={id} />
        {!isUnlimitedEvent && (
          <EventAnalytics eventId={id} eventName={eventData.name} />
        )}
        <ExportAttendees eventName={eventData.name} eventId={eventData.id} />
        <ExportPolls eventData={eventData} />
        <ExportQA eventData={eventData} />
        {eventData && <RecordingsContainer event={eventData} />}
      </Box>
    </Box>
  );
};
