import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { useRecording } from "@daily-co/daily-react";
import { DailyEventObjectRecordingError } from "@daily-co/daily-js";
import actions, {
  hideInactivityNotice,
  showInactivityNotice,
} from "modules/recording/redux/actions";
import { selectIsLoading } from "modules/recording/redux/selectors";
import {
  focusLayoutConfig,
  gridLayoutConfig,
} from "modules/audioVideo/hooks/useLayout/dal";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";

type PromiseFunction = (value?: unknown) => void;

interface PromiseRef {
  resolve: PromiseFunction;
  reject: PromiseFunction;
}

export const useRecordingAsync = () => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectIsLoading);
  const isInBroadcast = useSelector(selectIsInBroadcast);

  const actionRef = useRef<PromiseRef>();
  const lastStreamIdsRef = useRef<string[] | null>(null);
  const retryCountRef = useRef<number>(0);
  const shouldRetryRef = useRef<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const resolveAction = () => {
    actionRef.current?.resolve();
    actionRef.current = undefined;
  };

  const handleOnRecordingStarted = () => {
    dispatch(actions.setIsLoading(false));
    dispatch(hideInactivityNotice());
    retryCountRef.current = 0;
    shouldRetryRef.current = false;
  };

  const handleOnRecordingStopped = () => {
    dispatch(actions.setIsLoading(false));
  };

  useEffect(() => {
    if (isLoading) return;
    resolveAction();
  }, [isLoading]);

  const handleOnRecordingError = (e: DailyEventObjectRecordingError) => {
    if (e.errorMsg === "idle-timeout") {
      dispatch(showInactivityNotice());
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    restartRecording();
    if (actionRef.current) {
      actionRef.current.reject();
      actionRef.current = undefined;
    }

    dispatch(actions.setIsLoading(false));
  };

  const {
    startRecording: startRecordingDaily,
    stopRecording: stopRecordingDaily,
    updateRecording: updateRecordingDaily,
    ...recordingState
  } = useRecording({
    onRecordingError: handleOnRecordingError,
    onRecordingStarted: handleOnRecordingStarted,
    onRecordingStopped: handleOnRecordingStopped,
  });

  const startRecording = useCallback(
    ({ streamIds }: { streamIds: string[] }) =>
      new Promise((resolve, reject) => {
        actionRef.current = { resolve, reject };
        lastStreamIdsRef.current = streamIds;
        const gridLayout = gridLayoutConfig(streamIds);
        startRecordingDaily({
          ...gridLayout,
          layout: {
            ...gridLayout.layout,
          },
        });
        dispatch(actions.setIsLoading(true));
      }),
    [dispatch, startRecordingDaily],
  );

  const restartRecording = () => {
    if (lastStreamIdsRef.current) {
      shouldRetryRef.current = true;
      if (retryCountRef.current < 3) {
        const delay = 1000 * 2 ** retryCountRef.current;
        timeoutRef.current = setTimeout(() => {
          if (
            shouldRetryRef.current &&
            lastStreamIdsRef.current &&
            isInBroadcast
          ) {
            startRecording({ streamIds: lastStreamIdsRef.current });
            retryCountRef.current += 1;
          }
        }, delay);
      } else {
        shouldRetryRef.current = false;
      }
    }
  };

  const stopRecording = useCallback(
    () =>
      new Promise((resolve, reject) => {
        actionRef.current = { resolve, reject };
        stopRecordingDaily();
        dispatch(actions.setIsLoading(true));
      }),
    [dispatch, stopRecordingDaily],
  );

  type UpdateRecordingRequest =
    | {
        mode: "grid";
        streamIds: string[];
      }
    | {
        mode: "focus";
        streamIds: string[];
        preferScreenShare: boolean;
        preferredParticipant: string;
      };

  const updateRecording = useCallback(
    async (request: UpdateRecordingRequest) => {
      switch (request.mode) {
        case "grid": {
          const layout = gridLayoutConfig(request.streamIds);
          updateRecordingDaily(layout);
          break;
        }
        case "focus": {
          const layout = focusLayoutConfig({
            streamIds: request.streamIds,
            preferredParticipant: request.preferredParticipant,
            preferScreenShare: request.preferScreenShare,
          });
          updateRecordingDaily(layout);
          break;
        }
        default: {
          break;
        }
      }
    },
    [updateRecordingDaily],
  );

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  return {
    ...recordingState,
    isLoading,
    stopRecording,
    startRecording,
    updateRecording,
  };
};
