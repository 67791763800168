import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  note: {
    border: `1px solid ${theme.palette.events.bluePrimary}`,
    padding: "10px",
    alignItems: "center",
    marginBottom: "40px",
    display: "flex",
    marginTop: "8px",
  },
  noteIcon: {
    marginRight: "1rem",
    color: theme.palette.events.bluePrimary,
  },
}));
