import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textContainer: {
    marginRight: "8px",
    maxWidth: "80%",
  },
}));
