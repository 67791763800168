import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  domainPreFix: {
    padding: "10px",
    borderRight: `1px solid ${theme.palette.gray.main}`,
  },
  container: {
    margin: "32px 0",
  },
}));
