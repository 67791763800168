import { useMemo, useState } from "react";
import fileDownload from "js-file-download";
import { exportChat } from "services/apiService/apis";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { updateActiveChannel } from "modules/chat/redux/actions";
import { selectUser } from "modules/auth/redux/selectors";
import { CHAT_TYPE } from "modules/chat/redux/constants";
import { makeSelectUsersById } from "modules/event/usersInEvent/selectors";
import { getChannelName, getNewMessagesCount } from "modules/chat/chatUtils";
import { useI18n } from "i18n";
import {
  selectCurrentEvent,
  selectIsEventManager,
} from "modules/event/selectors";
import { useDeleteMessagesInChannel } from "modules/chat/hooks/useDeleteMessages";
import { selectIsMobile } from "modules/deviceInfo";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { ChatMessagesHeader } from "../../components";
import {
  makeSelectActiveChannel,
  selectUnreadCount,
} from "../../redux/selectors";

const ChatMessagesHeaderContainer = (): JSX.Element | null => {
  const activeChannel = useSelector(makeSelectActiveChannel);
  const event = useSelector(selectCurrentEvent);
  const unreadCount = useSelector(selectUnreadCount);
  const { t } = useI18n(["common", "event", "server"]);
  const [inProgress, setInProgress] = useState(false);
  const { addInfoNotification } = useNotificationActions();
  const hasEventManagerAccess = useSelector(selectIsEventManager);
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const users = useSelector(
    makeSelectUsersById(Object.keys(activeChannel?.users || {})),
  );
  const { deleteChannel, isPending } = useDeleteMessagesInChannel();
  const isMobileView = useSelector(selectIsMobile);

  const teamName = useMemo(() => {
    if (!user) {
      return "";
    }

    return getChannelName(activeChannel, user, users);
  }, [activeChannel, user, users]);
  const count = useMemo(() => getNewMessagesCount(unreadCount), [unreadCount]);
  const userInConversation = useMemo(() => {
    if (!activeChannel?.users || !user) {
      return null;
    }

    return (
      Object.keys(activeChannel?.users).filter((id) => id !== user.id)[0] ??
      null
    );
  }, [activeChannel?.users, user]);

  const onBackClick = () => {
    dispatch(updateActiveChannel(null));
  };
  const exportMessages = async () => {
    setInProgress(true);
    const result = await exportChat({
      eventId: event?.id,
      roomId: activeChannel?.id,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    setInProgress(false);

    if (!result.data) {
      addInfoNotification({ message: "You have no messages!" });

      return;
    }
    fileDownload(result.data, "chat-messages.txt");
    setInProgress(false);
  };

  const isPrivate = !activeChannel?.name;

  const clearGeneralChat = () => {
    dispatch(
      addDialogNotification({
        message: t("event:affirm.delete.chat.messages"),
        title: t("event:delete.chat"),
        confirmText: t("button.delete"),
        confirmBtnId: "sc-header-delete-confirm",
        onConfirm: async () => {
          if (activeChannel?.id) {
            deleteChannel({ channelId: activeChannel.id });
          }
        },
      }),
    );
  };

  return (
    <ChatMessagesHeader
      newMessagesCount={count}
      title={teamName}
      showClearButton={
        hasEventManagerAccess && activeChannel?.type === CHAT_TYPE.THEATER
      }
      showExportButton={
        !isMobileView &&
        (isPrivate ||
          (hasEventManagerAccess && activeChannel?.type === CHAT_TYPE.THEATER))
      }
      isClearInProgress={isPending}
      isExportInProgress={inProgress}
      onBackClick={onBackClick}
      onClear={clearGeneralChat}
      onExport={exportMessages}
      userInConversation={userInConversation}
    />
  );
};

export default ChatMessagesHeaderContainer;
