import { SyntheticEvent, useEffect } from "react";
import { Badge } from "@remo-co/ui-core/src/components/Badge";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { Tab } from "@remo-co/ui-core/src/components/Tab";
import { Tabs } from "@remo-co/ui-core/src/components/Tabs";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { ArrowForward } from "@remo-co/ui-core/src/icons/ArrowForward";
import { useQAActions } from "modules/qa";
import { useI18n } from "i18n";
import { updateActiveChannel } from "modules/chat/redux/actions";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import { selectIsBroadcasting } from "modules/broadcast/redux/selectors";
import classNames from "classnames";
import useStyles from "./styles";
import { ITab } from "../RightPanel";

interface Props {
  theaterId: string;
  tabId: number;
  handleTabChange: (event: SyntheticEvent<Element, Event>, tab: number) => void;
  onHideRightPanel: () => void;
  tabs: ITab[];
  showNetworkingRecommendationsOptions?: boolean;
}

const Header = ({
  theaterId,
  tabId,
  handleTabChange,
  onHideRightPanel,
  tabs,
  showNetworkingRecommendationsOptions,
}: Props): JSX.Element => {
  const isBroadcasting = useSelector(selectIsBroadcasting);
  const dispatch = useAppDispatch();
  const { subscribe, unsubscribe } = useQAActions();
  const { t } = useI18n();
  const styles = useStyles();

  useEffect(() => {
    if (!theaterId) return undefined;

    subscribe(theaterId);
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theaterId]);

  const renderTabHeaders = () =>
    tabs.map((tab) => (
      <Tooltip key={tab.id} title={t(tab.label) ?? ""}>
        <Tab
          className={`${styles.tab} ${
            tabId !== tab.type ? styles.tabIcon : ""
          }`}
          label={
            <Badge color="error" badgeContent={tab.badgeContent}>
              {tab.icon && showNetworkingRecommendationsOptions ? (
                <span>{tab.icon}</span>
              ) : (
                <span>{t(tab.label)}</span>
              )}
            </Badge>
          }
          id={tab.id}
          data-testid={tab.id}
        />
      </Tooltip>
    ));

  const handleBack = () => {
    dispatch(updateActiveChannel(null));
    onHideRightPanel();
  };

  return (
    <Box display="flex" alignItems="center" height={80} position="relative">
      <IconButton
        className={classNames(styles.hidePanelButton, {
          [styles.hidePanelButtonLight]: !isBroadcasting,
          [styles.hidePanelButtonDark]: isBroadcasting,
        })}
        data-dd-action-name="Close button - Right Panel"
        data-testid="hide-panel-button"
        id="bc-hide-panel"
        onClick={handleBack}
        size="large"
      >
        <ArrowForward />
      </IconButton>
      <Tabs
        classes={{
          flexContainer: styles.tabs,
          indicator: styles.indicator,
          root: showNetworkingRecommendationsOptions ? styles.tabsRoot : "",
        }}
        value={tabId}
        textColor="primary"
        onChange={handleTabChange}
      >
        {renderTabHeaders()}
      </Tabs>
    </Box>
  );
};

export default Header;
