import React, { useCallback, useRef, useState } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { Prompt, useHistory } from "react-router-dom";
import { Location } from "history";
import { debounce } from "lodash";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { useI18n } from "i18n";
import { Box } from "@remo-co/ui-core/src/components/Box";
import {
  addDialogNotification,
  closeDialogNotification,
} from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useAppDispatch } from "store/hooks";
import { maxFileSize, maxPlanNameLength } from "./constants";
import "./CustomFloorPlanInputs.scss";
import { FloorPlanSelectTags } from "./components/FloorPlanSelectTags";

interface ICustomFloorPlanInputs {
  handleDeletePlan(): void;
  handleSavePlan(name: string, color: string): void;
  fileSize: number;
  isAPILoading: boolean;
  floorBackgroundColor: string;
  setFloorBackgroundColor: (color: string) => void;
  isFullscreen?: boolean;
}

const CustomFloorPlanInputs = ({
  handleDeletePlan,
  handleSavePlan,
  fileSize,
  isAPILoading,
  floorBackgroundColor,
  setFloorBackgroundColor,
  isFullscreen,
}: ICustomFloorPlanInputs) => {
  const [floorPlanName, setFloorPlanName] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(true);
  const { t } = useI18n(["common", "customFloorPlan"]);
  const [allowLeaving, setAllowLeaving] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const colorPickerRef = useRef<HTMLInputElement | null>(null);
  const handleFloorPlanNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFloorPlanName(e.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedStateUpdate = useCallback(
    debounce((value) => setFloorBackgroundColor(value), 300),
    [],
  );

  const handleBackgroundColorChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    debouncedStateUpdate(e.target.value);
  };

  const handleSave = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (isValidData()) {
      handleSavePlan(floorPlanName, floorBackgroundColor);
    }
  };

  const isValidData = () => {
    if (!floorPlanName || floorPlanName.length > maxPlanNameLength) {
      setIsValid(false);

      return false;
    }

    return true;
  };

  const isLeavingChanges = (next: Location) => {
    const promptToLeave =
      !allowLeaving && window.location.pathname !== next.pathname;

    if (promptToLeave) {
      dispatch(
        addDialogNotification({
          title: t("customFloorPlan:unsaved.changes"),
          message: t("customFloorPlan:leave.without.saving"),
          confirmText: t("customFloorPlan:dont.save"),
          dismissText: t("customFloorPlan:cancel"),
          hideCloseButton: true,
          onConfirm: () => {
            setAllowLeaving(true);
            setTimeout(() => {
              history.push(next.pathname);
            }, 100);
          },
          onDismiss: () => {
            dispatch(closeDialogNotification());
          },
        }),
      );
    }

    return !promptToLeave;
  };

  return (
    <>
      <Container
        justifyContent="space-between"
        fullWidth
        flex
        data-testid="cfp-validator-v2-inputs"
      >
        <Container
          flex
          alignItems="flex-start"
          justifyContent="space-between"
          className="input-group"
        >
          <Input
            id="floorPlanNameLabel"
            inputProps={{
              maxLength: `${maxPlanNameLength}`,
            }}
            value={floorPlanName}
            onChange={handleFloorPlanNameChange}
            label={t("customFloorPlan:floor.plan.name")}
            placeholder={t("customFloorPlan:floor.plan.name")}
            type="text"
            size="sm"
            className={`floorplan-name ${isValid ? "" : "error"}`}
            error={
              isValid
                ? ""
                : (t("customFloorPlan:error.invalid.floor.name") as string)
            }
            disabled={isFullscreen}
          />
          <Container
            className="color-picker"
            onClick={() =>
              colorPickerRef.current && colorPickerRef.current.click()
            }
          >
            <label className="color-picker-label" htmlFor="colorPicker">
              {t("customFloorPlan:floor.environment.color")}
            </label>
            <input
              id="colorPicker"
              value={floorBackgroundColor}
              type="color"
              ref={colorPickerRef}
              onChange={handleBackgroundColorChange}
              data-testid="cfp-floor-plan-bg-color-input"
              disabled={isFullscreen}
            />
            {floorBackgroundColor}
          </Container>
          <Box className="tags-box">
            <FloorPlanSelectTags
              allowCreate
              label={t("customFloorPlan:tags")}
              labelPosition="top"
              freeSolo
              isFullscreen={isFullscreen}
            />
          </Box>
        </Container>
        <Prompt message={isLeavingChanges} />
      </Container>
      <Container
        className="input-group-buttons"
        fullWidth={false}
        flex
        justifyContent="flex-start"
      >
        <Button
          variant="primary"
          onClick={handleSave}
          className="input-group-buttons-save"
          loading={isAPILoading}
          size="md"
          disableRipple
          disabled={fileSize > maxFileSize || isAPILoading || isFullscreen}
          color="blue"
          data-testid="cfp-inputs-save-btn"
        >
          {!isAPILoading
            ? t("customFloorPlan:save.floorplan.to.account")
            : t("customFloorPlan:upload.saving")}
        </Button>
        <Button
          variant="secondary"
          color="dark"
          size="md"
          onClick={handleDeletePlan}
          disabled={isAPILoading || isFullscreen}
          className="input-group-buttons-delete"
          data-testid="cfp-inputs-delete-btn"
        >
          <Delete fontSize="small" />
        </Button>
      </Container>
    </>
  );
};

export default CustomFloorPlanInputs;
