import { MouseEvent, memo } from "react";
import { ListItem } from "@remo-co/ui-core/src/components/ListItem";
import { ListItemText } from "@remo-co/ui-core/src/components/ListItemText";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useI18n } from "i18n";
import getEventLink from "modules/manageEvent/utils/getEventLink";
import { useStyles } from "./styles";

interface Props {
  pageId?: string;
  eventId?: string;
  title: string;
  linkPageId: string;
  onClick: (e: MouseEvent<HTMLAnchorElement>) => void;
  isHidden?: boolean;
  isDisabledInCreateEvent?: boolean;
}

const LeftMenuItem = memo<Props>(
  ({
    pageId,
    title,
    linkPageId,
    eventId,
    onClick,
    isHidden,
    isDisabledInCreateEvent,
  }) => {
    const styles = useStyles();
    const { t } = useI18n(["manageEvent"]);
    const isActivePage = pageId === linkPageId;
    const disabled = isDisabledInCreateEvent && !eventId;

    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (!disabled) {
        onClick(e);
      }
    };

    if (isHidden) {
      return null;
    }

    return (
      <Tooltip
        title={`${
          disabled
            ? t("manageEvent:menu.item.disable.text", {
                name: title,
              })
            : ""
        }`}
        placement="bottom"
        arrow
      >
        <ListItem
          onClick={handleClick}
          component={Link}
          to={getEventLink(linkPageId, eventId)}
          disabled={disabled}
          className={classNames({
            [styles.listItemHighlight]: isActivePage,
            [styles.leftMenuItemDisabled]: disabled,
          })}
        >
          <ListItemText
            primaryTypographyProps={{
              color: isActivePage ? "primary" : "textPrimary",
              className: classNames({
                [styles.listItemHighlightText]: isActivePage,
              }),
            }}
            primary={title}
          />
        </ListItem>
      </Tooltip>
    );
  },
);

export default LeftMenuItem;
