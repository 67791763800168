// eslint-disable-next-line max-classes-per-file
import React, { ChangeEvent } from "react";
import {
  CompositeDecorator,
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
} from "draft-js";
import anchorme from "anchorme";
import { debounce } from "lodash";
import type { DebouncedFunc } from "lodash";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { FormatListBulletedOutlined } from "@remo-co/ui-core/src/icons/FormatListBulletedOutlined";
import { FormatListNumberedOutlined } from "@remo-co/ui-core/src/icons/FormatListNumberedOutlined";
import { Translate } from "i18n";
import { Trans } from "react-i18next";
import { withStyles } from "@remo-co/ui-core/src/utils/withStyles";
import "../../Draft.css";
import "./styles.scss";
import { Theme } from "@remo-co/ui-core/src/types";

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getBlockStyle(block: any) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class StyleButton extends React.Component<any> {
  onToggle = (
    event:
      | React.MouseEvent<HTMLSpanElement>
      | React.KeyboardEvent<HTMLSpanElement>,
  ) => {
    const { onToggle, style } = this.props;

    event.preventDefault();
    onToggle(style);
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === "Enter") {
      this.onToggle(event);
    }
  };

  render() {
    const { active, label } = this.props;

    let className = "RichEditor-styleButton muiIcon";

    if (active) {
      className += " RichEditor-activeButton";
    }

    return (
      <span
        className={className}
        onMouseDown={this.onToggle}
        onKeyDown={this.onKeyDown}
        role="button"
        tabIndex={0}
      >
        {label === "UL" && <FormatListBulletedOutlined fontSize="small" />}
        {label === "OL" && <FormatListNumberedOutlined fontSize="small" />}
        {!["OL", "UL"].includes(label) && label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  { label: "Blockquote", style: "blockquote" },
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" },
  { label: "Link", style: "link" },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BlockStyleControls = (props: any) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div id="editor-header" className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          // eslint-disable-next-line react/destructuring-assignment
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" },
  { label: "Monospace", style: "CODE" },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InlineStyleControls = ({ editorState, onToggle }: any) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
const findLinkEntities = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contentBlock: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contentState: any,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();

    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Link = ({ contentState, entityKey, children }: any) => {
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <a href={url} style={{ textDecoration: "underline" }}>
      {children}
    </a>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class DescriptionEditor extends React.Component<any, any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private editorRef: React.RefObject<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private urlInputRef: React.RefObject<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateData: DebouncedFunc<(editorState: any) => void>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    let editorState;

    this.state = { html: "" };
    const decorator = new CompositeDecorator([
      {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        strategy: findLinkEntities,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        component: Link,
      },
    ]);

    if (props.content) {
      const blocksFromHTML = convertFromHTML({
        // eslint-disable-next-line consistent-return
        htmlToEntity: (nodeName, node, createEntity) => {
          if (nodeName === "a") {
            return createEntity("LINK", "MUTABLE", {
              url: (node as HTMLLinkElement).href,
            });
          }
        },
      })(props.content);

      editorState = EditorState.createWithContent(blocksFromHTML, decorator);
    } else {
      editorState = EditorState.createEmpty(decorator);
    }

    this.state = { editorState, showURLInput: false, urlValue: "" };

    this.editorRef = React.createRef();
    this.urlInputRef = React.createRef();
    this.updateData = debounce(() => {}, 250);
  }

  focus = () => this.editorRef.current.focus();

  // eslint-disable-next-line react/no-arrow-function-lifecycle
  componentDidMount = () => {
    const { onChange } = this.props;

    this.updateData = debounce(onChange, 250);
  };

  // eslint-disable-next-line react/no-arrow-function-lifecycle
  componentWillUnmount = () => {
    this.updateData.cancel();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange = (editorState: any) => {
    this.setState({ editorState });
    // const currentContent = editorState.getCurrentContent();
    // If we dont do the check, draftjs gives empty html P tag and that gives error when loading the edit modal again
    const html = convertToHTML({
      // eslint-disable-next-line consistent-return, react/no-unstable-nested-components
      blockToHTML: (block) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!(block as any).text) {
          return <br />;
        }
      },
      // eslint-disable-next-line react/no-unstable-nested-components
      entityToHTML: (entity, originalText) => {
        if (entity.type === "LINK") {
          return <a href={entity.data.url}>{originalText}</a>;
        }

        return originalText;
      },
    })(editorState.getCurrentContent());

    this.updateData(html);

    this.setState({ html });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleKeyCommand = (command: any, editorState: any) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onChange(newState);

      return true;
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapKeyToEditorCommand = (e: any) => {
    const { editorState } = this.state;

    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);

      if (newEditorState !== editorState) {
        this.onChange(newEditorState);
      }

      return;
    }

    // eslint-disable-next-line consistent-return
    return getDefaultKeyBinding(e);
  };

  toggleBlockType = (blockType: string) => {
    const { editorState } = this.state;

    if (blockType === "link") {
      this.promptForLink();
    } else {
      this.onChange(RichUtils.toggleBlockType(editorState, blockType));
    }
  };

  toggleInlineStyle = (inlineStyle: string) => {
    const { editorState } = this.state;

    this.onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  onURLChange = (e: ChangeEvent<HTMLInputElement>) =>
    this.setState({ urlValue: e.target.value });

  getUrlInput = () => {
    const { showURLInput, urlValue } = this.state;

    if (showURLInput) {
      return (
        <div style={{ marginBottom: 10 }}>
          <input
            onChange={this.onURLChange}
            ref={this.urlInputRef}
            style={{ marginRight: 10, padding: 3 }}
            type="text"
            value={urlValue}
            onKeyDown={this.onLinkInputKeyDown}
          />
          <button type="button" onMouseDown={this.confirmLink}>
            <Trans i18nKey="button.confirm" />
          </button>
        </div>
      );
    }

    return null;
  };

  promptForLink = () => {
    // e.preventDefault();
    const { editorState } = this.state;
    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let url = "";

      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);

        url = linkInstance.getData().url;
      }

      this.setState(
        {
          showURLInput: true,
          urlValue: url,
        },
        () => {
          setTimeout(() => this.urlInputRef.current.focus(), 0);
        },
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confirmLink = (e: any) => {
    e.preventDefault();
    const { editorState, urlValue } = this.state;
    const contentState = editorState.getCurrentContent();
    const anchoredVal = anchorme.list(urlValue);

    let url: string;

    if (anchoredVal && anchoredVal[0]) {
      if (anchoredVal[0].isURL) {
        url = anchoredVal[0].protocol
          ? anchoredVal[0].string
          : `https://${anchoredVal[0].string}`;
      } else if (anchoredVal[0].isEmail) {
        url = `mailto:${anchoredVal[0].string}`;
      } else {
        url = urlValue;
      }
    } else {
      url = urlValue;
    }

    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      {
        url,
      },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    this.setState(
      {
        editorState: RichUtils.toggleLink(
          newEditorState,
          newEditorState.getSelection(),
          entityKey,
        ),
        showURLInput: false,
        urlValue: "",
      },
      () => {
        setTimeout(() => this.editorRef.current.focus(), 0);
      },
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onLinkInputKeyDown = (e: any) => {
    if (e.which === 13) {
      this.confirmLink(e as unknown as ChangeEvent<HTMLInputElement>);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,  react/no-unused-class-component-methods
  removeLink = (e: any) => {
    e.preventDefault();
    const { editorState } = this.state;
    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      this.setState({
        editorState: RichUtils.toggleLink(editorState, selection, null),
      });
    }
  };

  render() {
    const { editorState, html } = this.state;
    const { onChange, classes } = this.props;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = "RichEditor-editor";

    const contentState = editorState.getCurrentContent();

    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
      }
    }

    return (
      <Box className={classes.beDesc}>
        <Typography variant="h5">
          <Translate message="eventForm:event.description.title" />
        </Typography>
        <div className={`RichEditor-root ${classes.richEditorRoot}`}>
          <BlockStyleControls
            editorState={editorState}
            onToggle={this.toggleBlockType}
          />
          <InlineStyleControls
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
          />

          {this.getUrlInput()}
          <div
            id="event-description"
            className={className}
            onClick={this.focus}
            role="presentation"
          >
            {
              // prettier-ignore
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              /* @ts-ignore-start */}
            <Editor
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              keyBindingFn={this.mapKeyToEditorCommand}
              onChange={this.onChange}
              placeholder={
                <Typography color="inherit">
                  <Translate message="eventForm:event.description.placeholder" />
                </Typography>
              }
              ref={this.editorRef}
              spellCheck
              onBlur={() => onChange(html)}
            />

            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              /* @ts-ignore-end */
            }
          </div>
        </div>
      </Box>
    );
  }
}

export default withStyles((theme: Theme) => ({
  richEditorRoot: {
    borderRadius: "5px !important",
    border: `1px solid ${theme.palette.events.grayPrimary} !important`,
    boxShadow: "4px 4px 4px 0px #0C38911A",

    "& .public-DraftEditorPlaceholder-inner": {
      color: theme.palette.events.grayTertiary,
    },
    "&:focus-within": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
  beDesc: {
    marginTop: "2.5rem",
    "& > h5": {
      marginBottom: 5,
    },
  },
}))(DescriptionEditor);
