import React from "react";
import MaterialTable from "material-table";
import { AddBox } from "@remo-co/ui-core/src/icons/AddBox";
import { KeyboardArrowDown } from "@remo-co/ui-core/src/icons/KeyboardArrowDown";
import { Check } from "@remo-co/ui-core/src/icons/Check";
import { ChevronLeft } from "@remo-co/ui-core/src/icons/ChevronLeft";
import { ChevronRight } from "@remo-co/ui-core/src/icons/ChevronRight";
import { Clear } from "@remo-co/ui-core/src/icons/Clear";
import { DeleteOutline } from "@remo-co/ui-core/src/icons/DeleteOutline";
import { Edit } from "@remo-co/ui-core/src/icons/Edit";
import { Email } from "@remo-co/ui-core/src/icons/Email";
import { FilterList } from "@remo-co/ui-core/src/icons/FilterList";
import { FirstPage } from "@remo-co/ui-core/src/icons/FirstPage";
import { LastPage } from "@remo-co/ui-core/src/icons/LastPage";
import { Remove } from "@remo-co/ui-core/src/icons/Remove";
import { SaveAlt } from "@remo-co/ui-core/src/icons/SaveAlt";
import { Search } from "@remo-co/ui-core/src/icons/Search";
import { ViewColumn } from "@remo-co/ui-core/src/icons/ViewColumn";

const tableIcons = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Email: React.forwardRef((props, ref: any) => <Email {...props} ref={ref} />),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Add: React.forwardRef((props, ref: any) => <AddBox {...props} ref={ref} />),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Check: React.forwardRef((props, ref: any) => <Check {...props} ref={ref} />),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Clear: React.forwardRef((props, ref: any) => <Clear {...props} ref={ref} />),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Delete: React.forwardRef((props, ref: any) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DetailPanel: React.forwardRef((props, ref: any) => (
    <ChevronRight {...props} ref={ref} />
  )),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Edit: React.forwardRef((props, ref: any) => <Edit {...props} ref={ref} />),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Export: React.forwardRef((props, ref: any) => (
    <SaveAlt {...props} className="export-button" ref={ref} />
  )),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Filter: React.forwardRef((props, ref: any) => (
    <FilterList {...props} ref={ref} />
  )),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FirstPage: React.forwardRef((props, ref: any) => (
    <FirstPage {...props} ref={ref} />
  )),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  LastPage: React.forwardRef((props, ref: any) => (
    <LastPage {...props} ref={ref} />
  )),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  NextPage: React.forwardRef((props, ref: any) => (
    <ChevronRight {...props} ref={ref} />
  )),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PreviousPage: React.forwardRef((props, ref: any) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ResetSearch: React.forwardRef((props, ref: any) => (
    <Clear {...props} ref={ref} />
  )),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Search: React.forwardRef((props, ref: any) => (
    <Search {...props} ref={ref} />
  )),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SortArrow: React.forwardRef((props, ref: any) => (
    <KeyboardArrowDown {...props} ref={ref} />
  )),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ThirdStateCheck: React.forwardRef((props, ref: any) => (
    <Remove {...props} ref={ref} />
  )),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ViewColumn: React.forwardRef((props, ref: any) => (
    <ViewColumn {...props} ref={ref} />
  )),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DataTable = ({ columns, data, ...rest }: any) => (
  <MaterialTable icons={tableIcons} columns={columns} data={data} {...rest} />
);

export default DataTable;
