import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: theme.spacing(62.625),
    borderRadius: "5px",
    padding: `${theme.spacing(1.25)} 0`,
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.events.blueSecondary,
  },
  header: {
    padding: "15px 24px",
  },
  promptToSave: {
    borderRadius: "5px",
    padding: "5px",
    background: theme.palette.events.blueAccent4,
    alignItems: "flex-start",
  },
  dialogContent: {
    padding: "10px 20px 20px",
  },
  promptIcon: {
    color: theme.palette.events.blueSecondary,
  },
  message: {
    marginLeft: "10px",
  },
  actions: {
    justifyContent: "space-between",
    padding: `${theme.spacing(0.5)} ${theme.spacing(2.5)}`,
  },
  infoFont: {
    fontSize: theme.spacing(1.5),
  },
  formInput: {
    margin: `${theme.spacing(2)} 0`,
  },
  inputClass: {
    "&::placeholder": {
      color: "#88909F",
    },
  },
  inputContainer: {
    padding: `0 ${theme.spacing(1)}`,
  },
}));
