import { memo, useCallback, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { useSendMessage } from "modules/chat";
import { CHAT_MESSAGE_TYPES, CHAT_TYPE } from "modules/chat/redux/constants";
import { updateActiveChannel } from "modules/chat/redux/actions";
import { IChannel, IChat } from "modules/chat/types";
import { getChannelName } from "modules/chat/chatUtils";
import classNames from "classnames";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { IUser } from "modules/app/types";
import useChatFirestore from "modules/chat/firestore/useChatFirestore";
import { useTheme } from "@remo-co/ui-core/src/hooks/useTheme";
import { ListItem } from "@remo-co/ui-core/src/components/ListItem";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { makeSelectUsersById } from "../../../event/usersInEvent/selectors";
import tableIcon from "../../assets/table-chat.png";
import Avatar from "../../../userAvatar/Avatar";
import { getUserIds } from "../../chatUtils";
import useStyles from "./styles";

interface Props {
  unread: number;
  channel: IChannel;
  user?: IUser;
  lastMessage?: IChat;
}

const ChatRow = ({ unread, channel, user, lastMessage }: Props) => {
  const theme = useTheme();
  const { startDirectMessage } = useSendMessage();

  const users = useSelector(makeSelectUsersById(getUserIds(channel)));
  const { getMoreChats } = useChatFirestore();
  const name = useMemo(
    () => getChannelName(channel, user, users),
    [channel, user, users],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tableUser = { profile: { picture: tableIcon } };
  const dispatch = useAppDispatch();
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const classes = useStyles({ isInBroadcast: Boolean(isInBroadcast) });

  useEffect(() => {
    // Get messages only for general chat
    if (channel.type === CHAT_TYPE.THEATER) {
      getMoreChats(channel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChannelClick = useCallback(() => {
    const channelUsers = getUserIds(channel);

    if (channel.newChannel) {
      startDirectMessage(channelUsers[0], channelUsers[1]);
    } else {
      dispatch(updateActiveChannel(channel.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, startDirectMessage]);

  /* This case will come when user has visited a room in past and his lastAccess time is saved
   * We dont save channel names in DB, so if current room is not part of his visited room in this session, we dont have channel names
   * So we dont need to show those channels in list.
   * When user visits the room, we will show all the data
   */

  const channelUser = useMemo(() => {
    if (channel.type === CHAT_TYPE.ROOM) {
      return tableUser as IUser;
    }

    return users ? users.find((chnlUser) => user?.id !== chnlUser?.id) : null;
  }, [channel.type, tableUser, user?.id, users]);

  if (!name) {
    return null;
  }

  const isPredefinedTable = !channelUser || channelUser === tableUser;

  return (
    <ListItem
      key={channel.id}
      button
      data-testid={name}
      onClick={onChannelClick}
      data-dd-action-name="Chat Channel"
      className={classes.row}
    >
      <Container fullWidth={false} right="xs">
        <Avatar
          className={classes.avatar}
          user={channelUser ?? undefined}
          alt={name}
          fallbackName={name[0]}
          size="sm"
          color={
            isPredefinedTable ? theme.palette.events.grayPrimary : undefined
          }
        />
      </Container>
      <div className={classes.textContainer}>
        <div className={classes.text}>
          <Typography>{name} </Typography>
        </div>
        {lastMessage && (
          <Typography
            component="div"
            className={classNames(classes.text, classes.message)}
          >
            {lastMessage.type === CHAT_MESSAGE_TYPES.GIF
              ? "GIF"
              : lastMessage.text}
          </Typography>
        )}
      </div>
      {unread > 0 && (
        <i
          className={classNames(classes.countBadge, {
            [classes.moreBadge]: unread > 99,
          })}
        >
          <Typography>{unread > 99 ? "99+" : unread}</Typography>
        </i>
      )}
    </ListItem>
  );
};

export default memo<Props>(ChatRow);
