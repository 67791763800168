import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  checkbox: {
    color: theme.palette.events.grayTertiary,
    "&.Mui-checked": {
      color: `${theme.palette.events.blueSecondary} !important`,
    },
  },
  lobbyAdmitButton: {
    color: theme.palette.white,
  },
}));
