import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  registerToEvent: {
    zIndex: 1100,
  },
  paperScrollPaper: {
    width: "450px",
  },
  dialogContent: {
    minHeight: "200px",
    textAlign: "center",
    position: "relative",
    overflowY: "visible",
    padding: "60px 50px 40px 50px",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 20px 32px 20px",
    },
  },
  closeButton: {
    position: "absolute",
    zIndex: 1,
    right: "20px",
    top: "20px",
    margin: "0",
    width: "36px",
    height: "36px",
    borderRadius: "8px",
    display: "flex",
    alignContent: "center",
    color: theme.palette.events.blueSecondary,
  },
}));
