import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  dialog: {
    width: "501px",
  },
  previewContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 0,
  },
  title: {
    textAlign: "center",
  },
  body: {
    textAlign: "center",
  },
}));
