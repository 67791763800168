import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 24,
    gap: 12,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 20,
  },
  message: {
    maxWidth: 290,
    textAlign: "center",
  },
}));
