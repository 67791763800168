import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  caption: {
    color: theme.palette.white,
  },
  uploadCaption: {
    fontSize: "12px",
    fontWeight: 400,
    color: theme.palette.events.bluePrimary,
    whiteSpace: "nowrap",
  },
}));
