import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { useStyles } from "./styles";

interface Props {
  text: string;
}

const FormInfo = ({ text }: Props): JSX.Element => {
  const styles = useStyles();

  return (
    <Box className={styles.note}>
      <Info className={styles.noteIcon} />
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
};

export default FormInfo;
