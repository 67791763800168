import { ChangeEvent, ReactElement, useEffect } from "react";
import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { RadioGroup } from "@remo-co/ui-core/src/components/RadioGroup";
import { Radio } from "@remo-co/ui-core/src/components/Radio";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { Checkbox } from "@remo-co/ui-core/src/components/Checkbox";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { useI18n } from "i18n";
import {
  MutationCreateEventPollArgs,
  EventPollType,
  EventPollOptionInput,
  EventPollResultDisplayMode,
} from "graphql/generated";
import { Add } from "@remo-co/ui-core/src/icons/Add";
import { maxBy } from "lodash";
import { useStyles } from "./styles";
import trash_bin from "../../assets/trash_bin.svg";

export const PollForm = <T extends Partial<MutationCreateEventPollArgs>, O>({
  Buttons,
  pollType,
  onPollStateChange,
  pollState,
  options,
  setOptions,
}: {
  options: Array<EventPollOptionInput & { index: number } & O>;
  setOptions: (
    options: Array<
      | (EventPollOptionInput & { index: number } & O)
      | (EventPollOptionInput & { index: number })
    >,
  ) => void;
  Buttons: ReactElement;
  pollState: Omit<T, "options" | "timer"> & {
    timer?: number;
  };
  onPollStateChange: (
    pollState: Omit<T, "options" | "timer"> & {
      timer?: number;
    },
  ) => void;
  pollType: EventPollType;
}): ReactElement => {
  const styles = useStyles();
  const { t } = useI18n(["event"]);
  useEffect(() => {
    if (pollState.type !== pollType) {
      onPollStateChange({ ...pollState, type: pollType });
    }
  }, [onPollStateChange, pollState, pollType]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onPollStateChange({
      ...pollState,
      [event.target.name]: event.target.value,
    });
  };

  const handlerTimerChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newTimer = parseInt(event.target.value, 10);
    if (Number.isNaN(newTimer)) {
      onPollStateChange({
        ...pollState,
        timer: undefined,
      });
    }
    onPollStateChange({
      ...pollState,
      timer: newTimer,
    });
  };

  const updateOption = (
    newOption: EventPollOptionInput & { index: number } & O,
  ) => {
    const newOptions = [
      ...options
        .filter((option) => newOption.index !== option.index)
        .map((option) => {
          if (newOption.correctAnswer) {
            return {
              ...option,
              correctAnswer: false,
            };
          }
          return option;
        }),
      newOption,
    ].sort((a, b) => a.index - b.index);
    setOptions(newOptions);
  };

  const addOption = () => {
    const tail = maxBy<{ index: number }>(options, "index");
    setOptions([
      ...options,
      {
        option: "",
        index: tail ? tail.index + 1 : 0,
      },
    ]);
  };

  const removeOption = (optionId: number) => {
    setOptions(options.filter((option) => optionId !== option.index));
  };

  return (
    <Container top="sm">
      <Typography variant="h5">
        {t(`${pollType.toLowerCase()}.question`)}
      </Typography>
      <Container bottom="sm">
        <Input
          fullWidth
          size="sm"
          placeholder={t("poll.placeholder.question")}
          onChange={handleChange}
          value={pollState.title}
          name="title"
        />
        <Container top="sm" bottom="sm">
          {options
            .sort((option) => option.index)
            .map((option) => (
              <Container key={option.index} flex>
                <Container flex>
                  {pollState.type === EventPollType.Quiz && (
                    <FormControlLabel
                      data-testid={`checkbox-${option.index}`}
                      checked={option.correctAnswer ?? false}
                      label=""
                      control={
                        <Checkbox
                          onChange={(ev) =>
                            updateOption({
                              ...option,
                              correctAnswer: ev.target.checked,
                            })
                          }
                        />
                      }
                    />
                  )}
                  <Input
                    fullWidth
                    size="sm"
                    placeholder={t("answer", { number: option.index + 1 })}
                    onChange={(ev) =>
                      updateOption({ ...option, option: ev.target.value })
                    }
                    data-testid={`option-${option.index}`}
                    value={option.option}
                  />
                </Container>
                <IconButtonWithTooltip
                  id="delete-option"
                  title={t("poll.delete.option")}
                  data-testid="delete-option"
                  onClick={() => removeOption(option.index)}
                  className={styles.deleteButton}
                >
                  <img src={trash_bin} alt="" />
                </IconButtonWithTooltip>
              </Container>
            ))}
        </Container>
        <Button
          onClick={addOption}
          startAdornment={<Add />}
          size="md"
          variant="secondary"
          color="blue"
        >
          {t("add.answer")}
        </Button>
        <Typography variant="h5" className={styles.displayModeTitle}>
          {t("polls:display.mode.title")}
        </Typography>
        <RadioGroup
          value={
            pollState.resultDisplayMode ||
            EventPollResultDisplayMode.AfterVoteOrTimerEnds
          }
          onChange={(e) => {
            onPollStateChange({
              ...pollState,
              resultDisplayMode: e.target.value,
            });
          }}
        >
          {Object.values(EventPollResultDisplayMode)
            .filter((mode) =>
              pollState.type === EventPollType.Quiz
                ? mode !== EventPollResultDisplayMode.AfterPollClosed
                : true,
            )
            .map((mode) => (
              <FormControlLabel
                key={mode}
                value={mode}
                control={<Radio color="primary" />}
                label={t(
                  `polls:display.mode.${pollState.type?.toLowerCase()}.${mode}`,
                )}
              />
            ))}
        </RadioGroup>
      </Container>
      {pollState.type === EventPollType.Quiz && (
        <>
          <Typography variant="h5"> {t("quiz.seconds.to.answer")}</Typography>
          <Input
            name="timer"
            onChange={handlerTimerChange}
            value={pollState.timer}
            size="sm"
            type="number"
          />
        </>
      )}
      <Divider />
      <Container
        padding="xs"
        flex
        justifyContent="space-between"
        className={styles.buttons}
      >
        {Buttons}
      </Container>
    </Container>
  );
};
