import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { IEvent } from "modules/event/types";
import { useI18n } from "i18n";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { hasTicketingAccess } from "modules/companyPlanSettings/utils/ticketing";
import { useCompanySettingsQuery } from "modules/company/hooks/useCompanySettingsQuery";
import { useSelector } from "react-redux";
import { selectCompanyId } from "modules/company/redux/selectors";
import { useMemo } from "react";
import useStyles from "./styles";
import { NoAccessState } from "../NoAccessState";
import { LoadingState } from "../LoadingState";
import { Tickets } from "../Tickets";

interface Props {
  eventData: IEvent;
}

export const TicketingTab = ({ eventData }: Props) => {
  const companyId = useSelector(selectCompanyId);
  const { myCompanyPlan } = useCompanyPlanSettings();
  const hasAccess = hasTicketingAccess(myCompanyPlan);
  const { t } = useI18n(["eventForm", "mangeEvent"]);

  const { companySettings, status: settingStatus } =
    useCompanySettingsQuery(companyId);
  const styles = useStyles();

  const requiresAccess = useMemo(
    () =>
      !hasAccess ||
      (settingStatus === "success" && !companySettings?.eventcubeSettings),
    [companySettings?.eventcubeSettings, hasAccess, settingStatus],
  );

  const isLoading = useMemo(() => settingStatus === "pending", [settingStatus]);

  return (
    <Box data-testid="ticketing">
      <div>
        <Typography variant="h2" className={styles.heading}>
          {t("manageEvent:ticketing")}
        </Typography>
        <Typography variant="body1" className={styles.description}>
          {t("eventForm:tickets.description")}
        </Typography>
        <Typography variant="body1" className={styles.description}>
          {t("eventForm:tickets.description.2")}
        </Typography>
        <Button<"a">
          variant="text"
          color="dark"
          size="sm"
          className={styles.helpLinkButton}
          href={t("url:ticketing.help.article")}
          target="_blank"
          rel="noreferrer"
          data-testid="ticketing-help-link"
        >
          {t("eventForm:ticketing.help.article.text")}
        </Button>
      </div>
      {isLoading && <LoadingState />}
      {!isLoading && (
        <>
          {requiresAccess && <NoAccessState companyId={companyId} />}
          {!requiresAccess && (
            <Tickets
              eventData={eventData}
              eventcubeSettings={companySettings?.eventcubeSettings}
              companyId={companyId}
            />
          )}
        </>
      )}
    </Box>
  );
};
