import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    color: theme.palette.blue.darkShade1,
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  helpLinkButton: {
    marginTop: "16px",
    fontSize: "16px",
    fontWeight: 700,
    color: theme.palette.events.blueSecondary,
    textDecoration: "none",
  },
  promptToSave: {
    border: `1px solid ${theme.palette.gray.light}`,
    borderRadius: "5px",
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    margin: "1rem 0",
    background: theme.palette.yellow.lightShade1,
  },
  promptIcon: {
    color: theme.palette.yellow.dark,
  },
  promptMessage: {
    marginLeft: "1rem",
  },
}));
