import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  messagesList: {
    overflowY: "auto",
    backgroundSize: "100%",
    padding: `${theme.spacing(2.5)} 0px ${theme.spacing(1.25)}`,
    flex: 2,
    marginTop: "8px",
  },
}));

export default useStyles;
