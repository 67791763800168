import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1.5),
  },
  optionSVG: {
    width: "100%",
    borderRadius: 6,
    cursor: "pointer",
    border: `1px solid ${theme.palette.events.textPrimary}`,
  },
  label: {
    margin: `${theme.spacing(1)} 0`,
  },
  selectedType: {
    border: `1px solid ${theme.palette.events.bluePrimary}`,
  },
}));
