import { SvgIcon, SvgIconProps } from "@remo-co/ui-core/src/components/SvgIcon";
import { useTheme } from "@remo-co/ui-core/src/hooks/useTheme";

interface IHelpChatIcon {
  darkMode: boolean;
}

export const HelpChatIcon = (props: SvgIconProps & IHelpChatIcon) => {
  const { darkMode = false, ...rest } = props;
  const theme = useTheme();

  return (
    <SvgIcon {...rest} viewBox="0 0 28 28">
      <g transform="translate(-1063.848 -1065.791)">
        <g transform="translate(1063.848 1065.791)">
          <path
            d="M1218.424,1211.294a9.089,9.089,0,0,0-4.984-8.093,12.36,12.36,0,0,1-12.17,12.17,9.064,9.064,0,0,0,12.173,4.014q.27-.136.53-.29l4.411,1.22-1.22-4.411a9.029,9.029,0,0,0,1.26-4.61Z"
            transform="translate(-1190.394 -1192.326)"
            fill={darkMode ? theme.palette.primary.dark : theme.palette.white}
            opacity="0.2"
          />
          <path
            d="M1083.769,1081.926a10.706,10.706,0,0,0,1.483-5.434h0a10.7,10.7,0,1,0-19.923,5.439l-1.445,5.225,5.222-1.445A10.706,10.706,0,0,0,1083.769,1081.926Z"
            transform="translate(-1063.848 -1065.791)"
            fill={darkMode ? theme.palette.blue.dark : theme.palette.white}
          />
        </g>
        <g transform="translate(1070.861 1069.859)">
          <path
            d="M1173.489,1250.12a1.39,1.39,0,1,0,1.39,1.39A1.389,1.389,0,0,0,1173.489,1250.12Z"
            transform="translate(-1170.169 -1239.599)"
            fill={darkMode ? theme.palette.white : "var(--EventsLightBlack)"}
          />
          <path
            d="M1151.08,1117.2c-2.436,0-3.555,1.442-3.555,2.416a1.035,1.035,0,0,0,1.083,1.028c.974,0,.578-1.389,2.416-1.389.9,0,1.624.4,1.624,1.227,0,.974-1.011,1.534-1.606,2.039a3.3,3.3,0,0,0-1.208,2.743c0,.938.253,1.208.993,1.208.884,0,1.065-.4,1.065-.74a2.266,2.266,0,0,1,1.01-2.256c.487-.379,2.021-1.606,2.021-3.3S1153.389,1117.2,1151.08,1117.2Z"
            transform="translate(-1147.525 -1117.2)"
            fill={darkMode ? theme.palette.white : "var(--EventsLightBlack)"}
          />
        </g>
      </g>
    </SvgIcon>
  );
};
