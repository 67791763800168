import { ComponentProps } from "react";
import { Theme } from "@mui/material/styles";
import { StyleRulesCallback } from "@mui/styles/withStyles";
import { DarkInput } from ".";

export const styles: StyleRulesCallback<
  Theme,
  ComponentProps<typeof DarkInput>
> = (theme: Theme) => ({
  container: (props) => ({
    width: props.fullWidth ? "100%" : "auto",
  }),
  root: {
    fontSize: 14,
    borderRadius: 5,
    overflow: "hidden",
    borderWidth: 1,
    borderStyle: "solid",
    position: "relative",
  },
  value: {
    borderColor: theme.palette.blue.dark,
  },
  input: {
    padding: "8px 15px",
    "&::placeholder": {
      color: theme.palette.events.grayTertiary,
    },
  },
  inputDisabled: {
    "&::placeholder": {
      color: theme.palette.blue.darkShade1,
      opacity: "100%",
    },
  },
  sm: {
    border: `1px solid ${theme.palette.events.grayPrimary}`,
    "&:hover,&:focus": {
      border: `1px solid ${theme.palette.events.blueAccent3}`,
    },
  },
  lg: {
    height: "50px",
    border: `1px solid ${theme.palette.border.default}`,
    "&:hover,&:focus": {
      border: `1px solid ${theme.palette.events.blueAccent3}`,
    },
  },
  error: {
    borderColor: theme.palette.red.main,
    "&:hover": {
      borderColor: theme.palette.red.main,
    },
  },
  label: {
    fontSize: 14,
    color: `${theme.palette.events.textPrimary} !important`,
    padding: 0,
    margin: 0,
    fontWeight: 500,
  },
  description: {
    fontSize: "10px !important",
    color: `${theme.palette.events.bluePrimary} !important`,
    padding: "0 !important",
    fontWeight: 500,
    marginTop: "10px",
  },
  errorDescription: {
    color: `${theme.palette.red.main} !important`,
  },
});
