import { EventsColors } from "@remo-co/ui-core/src/theme/colors";
import { ActivityStatus } from "./types";

export const ACTIVITY_STATUS_REF = "ActivityStatusUsers";
export const ACTIVITY_STATUS_EVENTS_REF = "ActivityStatusEvents";

export const ActivityStatusColors = {
  [ActivityStatus.ACTIVE]: EventsColors.SUCCESS_DARK,
  [ActivityStatus.AWAY]: EventsColors.WARNING_DARK,
  [ActivityStatus.FOCUSING]: EventsColors.ERROR_DARK,
};
