import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { DragIndicator } from "@remo-co/ui-core/src/icons/DragIndicator";
import { Edit } from "@remo-co/ui-core/src/icons/Edit";
import { ICustomRegistrationQuestion } from "modules/registration/types";
import { useDrag, useDrop } from "react-dnd";
import classNames from "classnames";
import { useI18n } from "i18n";
import { useStyles } from "./styles";
import { DRAGGABLE_QUESTION_TYPE } from "../../constants";
import { getQuestionIcon } from "../../utils";

interface Props {
  question: ICustomRegistrationQuestion;
  moveQuestion: (id: string, newOrder: number) => void;
  onOrderChange: () => void;
  onQuestionUpdate: (question: ICustomRegistrationQuestion) => void;
  onQuestionDelete: (id: string) => void;
}

export const QuestionItem = ({
  question,
  moveQuestion,
  onOrderChange,
  onQuestionUpdate,
  onQuestionDelete,
}: Props) => {
  const { t } = useI18n(["eventForm"]);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DRAGGABLE_QUESTION_TYPE,
      item: question,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (question, monitor) => {
        const { _id: droppedId, order } = question;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveQuestion(droppedId, order);
        }
      },
    }),
    [question, moveQuestion],
  );
  const [, drop] = useDrop(
    () => ({
      accept: DRAGGABLE_QUESTION_TYPE,
      hover({ _id: draggedId }: ICustomRegistrationQuestion) {
        if (draggedId !== question._id) {
          moveQuestion(draggedId, question.order);
        }
      },
      drop({ order }: ICustomRegistrationQuestion) {
        if (order !== question.order) {
          onOrderChange();
        }
      },
    }),
    [moveQuestion],
  );
  const styles = useStyles();

  return (
    <div
      ref={(node) => drag(drop(node))}
      className={classNames(styles.draggableItem, {
        [styles.isDragging]: isDragging,
      })}
      data-testid={`question-item-${question._id}`}
    >
      <DragIndicator className={styles.dragIcon} />
      <div
        className={classNames(styles.question, {
          [styles.isNetworkingRecommendationQuestion]:
            question?.isNetworkingQuestion,
        })}
      >
        <div className={styles.content}>
          <img src={getQuestionIcon(question.type)} alt={question.type} />
          <Typography variant="body1" className={styles.questionText}>
            {question.question}{" "}
            <i>{!question.isRequired ? `- ${t("optional.indicator")}` : ""}</i>
          </Typography>
        </div>
        {!question?.isNetworkingQuestion && (
          <div className={styles.actions}>
            <IconButtonWithTooltip
              title={t("edit.question")}
              id="edit-question-button"
              size="small"
              onClick={() => onQuestionUpdate(question)}
              data-testid="edit-question-button"
              className={styles.actionIcon}
            >
              <Edit fontSize="small" />
            </IconButtonWithTooltip>
            <IconButtonWithTooltip
              title={t("delete.question")}
              id="delete-question-button"
              size="small"
              onClick={() => onQuestionDelete(question._id)}
              data-testid="delete-question-button"
              className={styles.actionIcon}
            >
              <Delete fontSize="small" />
            </IconButtonWithTooltip>
          </div>
        )}
      </div>
    </div>
  );
};
