import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  recordingModuleCtn: {
    display: "flex",
    width: "210px",
  },
  recordTooltip: {
    maxWidth: "none",
    position: "relative",
    left: 2,
  },
  recordButton: {
    padding: "0",
    height: "46px",
    whiteSpace: "nowrap",
    color: theme.palette.white,
    backgroundColor: "var(--EventsLightBlack)",
    "&:hover": {
      backgroundColor: "#efefef26",
    },
  },
  recording: {
    alignItems: "center",
    display: "flex",
    color: "var(--White-op-090)",
    backgroundColor: "var(--EventsLightBlack)",
    margin: "0",
    borderRadius: "50px",
  },
  static: { display: "flex", alignItems: "center", padding: "0 14px" },
  recordIcon: {
    fontSize: "26px",
    height: "26px",
    width: "26px",
    marginRight: "10px",
    color: theme.palette.events.errorDark,
  },
  loadingIcon: {
    height: "20px !important",
    width: "20px !important",
    marginRight: "10px",
    color: "var(--White-op-090)",
  },
  stopButton: {
    height: "30px",
  },
  recordingTime: {
    width: "58px",
  },
}));
