import classNames from "classnames";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { ArrowForward } from "@remo-co/ui-core/src/icons/ArrowForward";
import { SeatingInfo } from "../../utils";
import useStyles from "./styles";

interface Props {
  floor: number;
  name?: string;
  onClick: () => void;
  seatingInfo: SeatingInfo;
  disabled: boolean;
}

const FloorInfoCard = ({
  floor,
  name,
  onClick,
  seatingInfo,
  disabled,
}: Props): JSX.Element => {
  const { t } = useI18n(["manageEvent"]);
  const styles = useStyles();

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <Box
      className={classNames(styles.container, {
        [styles.disabled]: disabled,
      })}
      onClick={handleClick}
      data-testid="floor-card"
    >
      <Box className={styles.floorCard}>
        <Container flex alignItems="center" justifyContent="flex-start">
          <Container flex alignItems="center" className={styles.floorNumber}>
            {floor}
          </Container>
          <Typography className={styles.name} variant="h6">
            {name ||
              t("floor.number", {
                number: floor,
              })}
          </Typography>
        </Container>
        {seatingInfo.tableCount ? (
          <Typography className={styles.description} variant="h6">
            {t("number.guests.assigned", {
              users: seatingInfo.totalUsers,
              tables: seatingInfo.tableCount,
            })}
          </Typography>
        ) : (
          <Typography className={styles.emptyDescription} variant="h6">
            {t("no.guests.assigned")}
          </Typography>
        )}
      </Box>

      <div className={styles.action}>
        <Typography className={styles.actionText} variant="body1">
          {t("manage")}
        </Typography>
        <ArrowForward className={styles.actionIcon} />
      </div>
    </Box>
  );
};

export default FloorInfoCard;
