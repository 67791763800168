import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

interface Props {
  isAccountSettings?: boolean;
  allowDelete?: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  actions: {
    position: "absolute",
    width: "calc(100% - 28px)",
    height: "29px",
    display: "none",
    bottom: "-36px",
    background: theme.palette.white,
    left: "28px",
    transition: "bottom 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  actionButtons: {
    flex: 1,
    fontSize: "0.75rem",
    width: "100%",
    color: `${theme.palette.events.bluePrimary} !important`,
    height: "29px",
    border: "none",
    borderRadius: "0 5px 5px 0",
    "&:hover": {
      color: `${theme.palette.events.blueAccent3} !important`,
    },
  },
  actionButtonIcons: {
    fontSize: "1rem",
    marginRight: "2px",
    marginTop: "-2px",
  },
  templateImage: {
    opacity: 0.8,
    maxWidth: "100%",
    height: "100px",
    objectFit: "cover",
    willChange: "transform",
    backfaceVisibility: "hidden",
  },
  templateSelected: {
    borderColor: theme.palette.events.bluePrimary,
  },
  templateSelectedActions: {
    display: "flex",
    bottom: "5px",
    justifyContent: "center",
  },
  templateDisabled: {
    opacity: "0.5",
    "&:hover $actions": {
      display: "none",
    },
  },
  media: {
    border: "2px solid transparent",
    maxWidth: "100%",
    display: "flex",
    width: "136px",
    height: "136px",
    flexDirection: "column",
    transition: "border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12)`,
    borderRadius: "3px",

    "&:hover $templateImage": {
      opacity: 1,
    },
  },
  templateName: {
    padding: "4px 0",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingTop: "12px",
  },
  noAdminSeatsIcon: {
    background: "#ffeeb8",
    borderRadius: "50%",
    padding: "4px",
    border: "1px solid #d6a400",
    fill: "#f6b604",
    position: "absolute",
    right: "20px",
    top: "20px",
  },
  container: {
    textAlign: "center",
    paddingBottom: "4px !important",
    cursor: "pointer",
    position: "relative",
    maxWidth: "160px",

    "&:hover $actions": {
      display: "flex",
      bottom: "5px",
    },

    "&:hover $media": {
      borderColor: theme.palette.events.blueAccent3,
    },
  },
  floorPlanWarning: {
    position: "absolute",
    top: "15px",
    right: "5px",
  },
  floorPlanTooltip: {
    marginTop: "-10px",
    background: "transparent !important",
  },
  viewActionButton: ({ allowDelete, isAccountSettings }) => ({
    width: allowDelete && !isAccountSettings ? "33%" : "50%",
  }),
  selectActionButton: ({ allowDelete }) => ({
    width: allowDelete ? "33%" : "50%",
  }),
  deleteActionButton: ({ isAccountSettings }) => ({
    width: isAccountSettings ? "50%" : "33%",
  }),
  themeImage: {
    objectFit: "cover",
  },
  isSelected: {
    background: `${theme.palette.events.bluePrimary} !important`,
    color: `${theme.palette.white} !important`,
    "&:hover": {
      background: `${theme.palette.events.bluePrimary} !important`,
      color: `${theme.palette.white} !important`,
      cursor: "default !important",
    },
  },
}));
