import { forwardRef, Ref } from "react";
import MuiMenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useStyles } from "./styles";

export interface IMenuItemProps extends MenuItemProps {
  icon?: React.ReactNode;
  text?: string;
  href?: string;
  target?: "_blank";
  textClassName?: string;
}

const MenuItem = forwardRef(
  (props: IMenuItemProps, ref?: Ref<HTMLLIElement>) => {
    const { icon, text, children, textClassName, ...rest } = props;
    const classes = useStyles();

    return (
      <MuiMenuItem
        className={classes.menuItem}
        disableGutters
        ref={ref}
        {...rest}
      >
        {icon && (
          <ListItemIcon
            style={{
              minWidth: "25px",
            }}
          >
            {icon}
          </ListItemIcon>
        )}
        {text && (
          <ListItemText
            color="textPrimary"
            primary={text}
            classes={{
              primary: textClassName,
            }}
          />
        )}
        {children}
      </MuiMenuItem>
    );
  },
);

export default MenuItem;
