import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { useI18n } from "i18n";
import { useCheckEventStatus } from "modules/event/hooks/useCheckEventStatus";
import {
  selectCurrentEvent,
  selectCurrentEventIsLobbyActive,
} from "modules/event/selectors";
import React from "react";
import { useSelector } from "react-redux";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useAppDispatch } from "store/hooks";
import { Checkbox } from "@remo-co/ui-core/src/components/Checkbox";
import { Switch } from "@remo-co/ui-core/src/components/Switch";
import { selectSortedWaitingGuests } from "./redux/selectors";
import { useStyles } from "./styles";

interface Props {
  isTogglingLobby: boolean;
  updateEvent: (isChecked: boolean) => void;
  label: string;
  overrideToggleStatus?: boolean;
  control?: "switch" | "checkbox";
}

interface ControlProps {
  isTogglingLobby: boolean;
  updateEvent: (isChecked: boolean) => void;
  overrideToggleStatus?: boolean;
  control: "switch" | "checkbox";
}

const ControlComponent = ({
  isTogglingLobby,
  updateEvent,
  overrideToggleStatus,
  control = "checkbox",
}: ControlProps) => {
  const currentEvent = useSelector(selectCurrentEvent);
  const isLobbyActive = useSelector(selectCurrentEventIsLobbyActive);
  const { t } = useI18n();
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { checkIfEventHappeningNow } = useCheckEventStatus();
  const users = useSelector(selectSortedWaitingGuests);

  const onLobbyToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    if (!isChecked) {
      // If no users, we dont need to show notification
      if (
        currentEvent &&
        checkIfEventHappeningNow(currentEvent) &&
        users.length
      ) {
        dispatch(
          addDialogNotification({
            title: t("common:are.you.sure"),
            message: t("disable.lobby.text"),
            confirmText: t("disable"),
            dismissText: t("no"),
            hideCloseButton: true,
            dismissBtnId: "lb-cancel",
            confirmBtnId: "lb-confirm",
            onConfirm: () => {
              updateEvent(isChecked);
            },
          }),
        );

        return;
      }
    }
    updateEvent(isChecked);
  };

  const status =
    overrideToggleStatus !== undefined ? overrideToggleStatus : isLobbyActive;

  if (control === "switch")
    return (
      <Switch
        className={styles.checkbox}
        disabled={isTogglingLobby}
        checked={status}
        onChange={onLobbyToggle}
      />
    );
  return (
    <Checkbox
      className={styles.checkbox}
      disabled={isTogglingLobby}
      checked={status}
      onChange={onLobbyToggle}
    />
  );
};

const LobbyToggle = ({
  isTogglingLobby,
  updateEvent,
  label,
  overrideToggleStatus,
  control = "checkbox",
}: Props): JSX.Element => (
  <FormControlLabel
    label={label}
    labelPlacement={control === "checkbox" ? "end" : "start"}
    data-testid="lobby-toggle"
    control={
      <ControlComponent
        isTogglingLobby={isTogglingLobby}
        updateEvent={updateEvent}
        overrideToggleStatus={overrideToggleStatus}
        control={control}
      />
    }
    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
  />
);

export default LobbyToggle;
