import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles((theme) => ({
  innerMenuCollapse: {
    paddingLeft: "36px",
  },
  innerMenuList: {
    padding: 0,
  },
  expandButton: {
    color: theme.palette.white,
  },
  divider: {
    borderColor: theme.palette.white,
    marginBottom: 2,
  },
}));

export default useStyles;
