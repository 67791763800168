import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(0.5),
  },
  iconButton: {
    color: theme.palette.events.lightBlack,
  },
}));
