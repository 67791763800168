import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { useI18n } from "i18n";
import { useStyles } from "./styles";

interface Props {
  eventId?: string;
  showForm: () => void;
  unsavedCapacityChanges: boolean;
  hasSponsors: boolean;
}

export const NoContentPlaceholder = ({
  eventId,
  showForm,
  unsavedCapacityChanges,
  hasSponsors,
}: Props) => {
  const { t } = useI18n(["eventForm", "manageEvent"]);
  const styles = useStyles();

  const disableButton = !eventId || unsavedCapacityChanges || hasSponsors;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={styles.root}
    >
      <Typography className={styles.placeholder}>
        {t("eventForm:event.content.placeholder")}
      </Typography>
      <Tooltip
        placement="bottom"
        arrow
        title={!eventId ? t("eventForm:no.event.content.tooltip") : ""}
      >
        <span>
          <Button
            variant="secondary"
            size="sm"
            className={styles.addContentButton}
            disabled={disableButton}
            contentClassName={styles.addContentButtonContent}
            onClick={showForm}
            data-testid="add-content-button"
            theme="light"
          >
            {t("eventForm:add.content")}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};
