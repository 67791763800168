import { useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { getCurrentEventId } from "helpers/reduxHelper";
import { addUserPreferencesInSpace } from "modules/userPreferences/redux/actions";
import logger from "logging/logger";
import { useAppDispatch } from "store/hooks";
import { IUserPreferences } from "modules/userPreferences/redux/types";
import { selectUserId } from "modules/auth/redux/selectors";
import { useSelector } from "react-redux";
import { database } from "./firebaseConfig";

const USER_PREFERENCES_PATH = "UserPreferences";

export const UserPreferencesSubscriber = () => {
  const dispatch = useAppDispatch();
  const userId = useSelector(selectUserId);
  const eventId = getCurrentEventId();

  useEffect(() => {
    if (!eventId && !userId) {
      return;
    }

    const dbRef = ref(
      database,
      `Event/${eventId}/${USER_PREFERENCES_PATH}/${userId}`,
    );

    const unsubscribe = onValue(
      dbRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const userPrefs: IUserPreferences = snapshot.val();
          dispatch(addUserPreferencesInSpace(userId ?? "", userPrefs));
        } else {
          logger.warn(
            `[UserPreferencesSubscriber] No user preferences found for userId: ${userId}`,
          );
        }
      },
      (error) => {
        logger.error(
          `[UserPreferencesSubscriber] Error while listening to user preferences: ${error}`,
        );
      },
    );

    // eslint-disable-next-line consistent-return
    return unsubscribe;
  }, [userId, eventId, dispatch]);

  return null;
};
