import React from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { CircularProgress } from "@remo-co/ui-core/src/components/CircularProgress";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import fileDownload from "js-file-download";
import { useI18n } from "i18n";
import { IEvent } from "modules/event/types";
import { exportQuestions } from "../../../services/apiService/apis";
import useNotificationActions from "../../notification/hooks/useNotificationActions";

export const BOM_FOR_EXCEL = "\uFEFF";

const ExportQA = ({ eventData }: { eventData: IEvent }) => {
  const [inProgress, setInProgress] = React.useState(false);
  const { addInfoNotification, addErrorNotification } =
    useNotificationActions();
  const theaterData = eventData.theaters ? eventData.theaters[0] : null;
  const theaterId = theaterData ? theaterData.id : null;
  const { t } = useI18n(["common", "eventForm"]);
  const isNew = !theaterId;

  const handleExport = async (theaterIdParam: string) => {
    setInProgress(true);
    addInfoNotification({
      message: t("eventForm:download.event.text"),
      position: "tc",
      autoDismiss: 5,
    });
    const result = await exportQuestions(theaterIdParam, eventData.id);

    if (result.valid) {
      fileDownload(result.data, "question-list.csv", "text/csv", BOM_FOR_EXCEL);
      setInProgress(false);

      return;
    }
    addErrorNotification({
      message: t("something.wrong"),
      position: "tc",
      autoDismiss: 5,
    });
    setInProgress(false);
  };

  return (
    <div
      style={{
        marginBottom: 20,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h4">
        {t("eventForm:submit.questions.title")}
      </Typography>

      <Tooltip
        title={isNew ? t<string>("eventForm:submit.questions.info") : ""}
        placement="top"
      >
        <span>
          <Button
            variant="secondary"
            color="blue"
            onClick={theaterId ? () => handleExport(theaterId) : undefined}
            disabled={isNew}
            theme="light"
          >
            {!inProgress && t("export.questions")}{" "}
            {inProgress && <CircularProgress size={24} />}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};

export default ExportQA;
