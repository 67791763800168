import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.blue.dark,
    textAlign: "left",
    marginTop: theme.spacing(1.25),
  },
  preview: {
    height: "250px",
    marginTop: theme.spacing(1.25),
    border: `1px solid ${theme.palette.blue.dark}`,
    borderRadius: "5px",
    cursor: "not-allowed",
    userSelect: "none",
  },
  previewIframe: {
    height: "100%",
    width: "100%",
    borderRadius: "5px",
    pointerEvents: "none",
  },
  imageMediaUploader: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  imageMediaUploaderContent: {
    padding: 0,
  },
  imageMediaUploaderPreview: {
    width: 440,
  },
}));
