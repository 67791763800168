import { useCallback, useRef } from "react";
import {
  DatabaseReference,
  DataSnapshot,
  off,
  onValue,
} from "firebase/database";
import { useAppDispatch } from "store/hooks";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import useEventTracking from "modules/eventTracking/hooks/useEventTracking";
import { Errors, trackError } from "modules/monitoring";
import { useI18n } from "i18n";
import actions from "../../redux/actions";
import broadcasterFirebase from "../../broadcaster.firebase";

// eslint-disable-next-line max-lines-per-function
export const useBroadcasterActions = () => {
  const dispatch = useAppDispatch();
  const { addInfoNotification } = useNotificationActions();
  const { trackGetAttendeeOnStage } = useEventTracking();
  const docRef = useRef<DatabaseReference>();
  const { t } = useI18n();

  const giveBroadcastRight = useCallback(
    async ({
      theaterId,
      userId,
      userName,
    }: {
      theaterId: string;
      userId: string;
      userName?: string;
    }) => {
      try {
        await broadcasterFirebase.giveBroadcastRight(theaterId, userId);
      } catch (error) {
        trackError(error, {
          label: Errors.PRESENTATION_MODE_GET_ATTENDEE_ON_STAGE,
        });
      }

      if (userName) {
        addInfoNotification({
          message: t("event:allowed.to.present", {
            key: userName,
          }),
          autoDismiss: 4,
          position: "tr",
        });
      }
      trackGetAttendeeOnStage();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const takeBroadcastRight = useCallback(
    async ({
      theaterId,
      userId,
      userName,
    }: {
      theaterId: string;
      userId: string;
      userName?: string;
    }) => {
      await broadcasterFirebase.takeBroadcastRight(theaterId, userId);

      if (userName) {
        addInfoNotification({
          message: t("event:disallowed.to.present", {
            key: userName,
          }),
          autoDismiss: 4,
          position: "tr",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const resetBroadcaster = useCallback(
    async ({ theaterId }: { theaterId: string }) => {
      await broadcasterFirebase.removeBroadcaster(theaterId);
    },
    [],
  );

  const subscribe = useCallback(
    async ({ theaterId }: { theaterId: string }) => {
      docRef.current = broadcasterFirebase.getBroadcasterDocRef(theaterId);

      off(docRef.current);
      onValue(docRef.current, (snap: DataSnapshot) => {
        const broadcasters = snap.val();

        if (broadcasters) {
          dispatch(actions.updateBroadcasters(broadcasters));
        } else {
          dispatch(actions.reset());
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const unsubscribe = useCallback(() => {
    if (docRef.current) {
      off(docRef.current);
    }
  }, []);

  return {
    subscribe,
    unsubscribe,
    giveBroadcastRight,
    takeBroadcastRight,
    resetBroadcaster,
  };
};

export default useBroadcasterActions;
