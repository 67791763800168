import React from "react";
import classNames from "classnames";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { FormHelperText } from "@remo-co/ui-core/src/components/FormHelperText";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { CloudUpload } from "@remo-co/ui-core/src/icons/CloudUpload";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { useI18n } from "i18n";
import { ReactComponent as PictureIcon } from "../assets/picture.inline.svg";
import { useStyles } from "./styles";

interface ISponsorUploadAndPreviewProps {
  onFileInputClick?: (key: string) => void;
  onDelete: (key: string) => void;
  dataKey: string;
  uploadedImage?: string;
  helpText: React.ReactNode;
  disabled?: boolean;
  className?: string;
}

const SponsorUploadAndPreview = ({
  disabled,
  onFileInputClick,
  dataKey,
  uploadedImage,
  onDelete,
  helpText,
  className,
}: ISponsorUploadAndPreviewProps) => {
  const imageName = uploadedImage ? uploadedImage.replace(/^.*[\\/]/, "") : "";
  const onDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onDelete(dataKey);
  };
  const { t } = useI18n(["common", "eventForm"]);

  const onClick = () => {
    if (onFileInputClick) {
      onFileInputClick(dataKey);
    }
  };

  const styles = useStyles();

  return (
    <Box className={classNames(styles.wrap, className)}>
      <Box className={styles.upload} onClick={onClick}>
        <PictureIcon className={classNames(styles.icon, styles.pictureIcon)} />
        <CloudUpload className={classNames(styles.icon, styles.uploadIcon)} />
        <span className={styles.label}>
          {uploadedImage ? imageName : t("eventForm:upload.image")}
        </span>
        {uploadedImage && (
          <IconButtonWithTooltip
            id="delete-button"
            title={t("eventForm:delete.this.image")}
            onClick={onDeleteClick}
          >
            <Delete className={styles.deleteIcon} />
          </IconButtonWithTooltip>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box className={styles.preview}>
          <PictureIcon className={styles.icon} />
          {uploadedImage && (
            <img
              className={styles.uploadedImage}
              src={uploadedImage}
              alt={imageName}
            />
          )}
        </Box>
        {uploadedImage && (
          <FormHelperText className={styles.hoverViewHelperText}>
            {t("eventForm:hover.view")}
          </FormHelperText>
        )}
      </Box>
      {helpText}
      {disabled && <div className={styles.disabled} />}
    </Box>
  );
};

export default SponsorUploadAndPreview;
