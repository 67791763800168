import { ChangeEvent, memo } from "react";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { Search as SearchIcon } from "@remo-co/ui-core/src/icons/Search";
import { useI18n } from "i18n";
import { InputAdornment } from "@remo-co/ui-core/src/components/InputAdornment";
import useStyles from "./styles";

interface Props {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBox = memo(({ value, onChange }: Props) => {
  const classes = useStyles();
  const { t } = useI18n(["micCamCheck"]);

  return (
    <Input
      inputEndAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
      placeholder={t("micCamCheck:search.name")}
      onChange={onChange}
      value={value}
      fullWidth
      className={classes.searchInput}
      inputClassName={classes.inputClass}
      data-testid="chat-search"
    />
  );
});
