import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "32px",
  },
  helpIcon: {
    fontSize: "16px",
    marginLeft: theme.spacing(0.5),
    color: theme.palette.events.blueSecondary,
  },
  addChannelIcon: {
    fontSize: "16px",
    marginRight: theme.spacing(1.25),
  },
  addChannelButtonContent: {
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    textDecoration: "none",
  },
  addChannelButton: {
    marginTop: "20px",
  },
}));
