import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Link } from "@remo-co/ui-core/src/components/Link";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { useI18n } from "i18n";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { eventContentLimit } from "modules/companyPlanSettings/utils/eventContent";
import { getMapTemplateByType } from "modules/event/template";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import useFloorsUtils from "modules/manageEvent/hooks/useFloorsUtils";
import { useContext } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { EventContentList } from "../EventContentList";
import { useStyles } from "./styles";

interface Props {
  eventId?: string;
}

export const EventContentSettings = ({ eventId }: Props) => {
  const { state } = useContext(MANAGE_EVENT_CONTEXT);
  const { myCompanyPlan } = useCompanyPlanSettings();
  const { t } = useI18n(["eventForm", "url", "manageEvent"]);
  const styles = useStyles();

  const { eventData } = state ?? {};
  const { spaces = [], capacity, template } = eventData?.theaters?.[0] ?? {};
  const eventTemplate = getMapTemplateByType(template);
  const { noOfFloors } = useFloorsUtils(capacity, eventTemplate);
  const unsavedCapacityChanges = noOfFloors !== spaces.length;

  return (
    <div className={styles.root}>
      <Typography variant="body1" className={styles.subtitle}>
        {t("eventForm:manage.event.content.subtitle")}
      </Typography>
      <Button<typeof Link>
        className={styles.helpLinkButton}
        href={t("url:event.content.help")}
        target="_blank"
        rel="noreferrer"
        variant="text"
      >
        {t("eventForm:event.content.help.link")}
      </Button>
      {eventData?.id && unsavedCapacityChanges && (
        <Container flex alignItems="center" className={styles.promptToSave}>
          <Info className={styles.promptIcon} />
          <Typography variant="body2" className={styles.promptMessage}>
            {t("manageEvent:floor.content.prompt.to.save")}
          </Typography>
        </Container>
      )}
      {eventData?.id &&
        Boolean(state?.sponsors?.length) &&
        !unsavedCapacityChanges && (
          <Container flex alignItems="center" className={styles.promptToSave}>
            <Info className={styles.promptIcon} />
            <Typography variant="body2" className={styles.promptMessage}>
              {t("manageEvent:prompt.to.delete.sponsors")}
            </Typography>
          </Container>
        )}
      <EventContentList
        eventId={eventId}
        limit={eventContentLimit(myCompanyPlan)}
        unsavedCapacityChanges={unsavedCapacityChanges}
        hasSponsors={Boolean(state?.sponsors?.length)}
      />
    </div>
  );
};
