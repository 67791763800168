import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  dialog: {
    position: "relative !important" as "relative",
    height: "100%",
    width: "100%",
    pointerEvents: "all",
  },
  dialogContainer: {
    display: "block",
    position: "relative",
    width: "100%",
  },
  dialogPaper: {
    width: "100%",
    height: "100%",
    maxWidth: "unset",
    maxHeight: "unset",
    margin: 0,
  },
  dialogPaperMobile: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    margin: 0,
    width: `calc(100% - ${theme.spacing(2)}) !important`,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0.75),
    top: "4px",
  },
  header: {
    padding: theme.spacing(1.25),
    height: "41px",
    alignContent: "center",
  },
  title: {
    textAlign: "center",
  },
  content: {
    position: "relative",
    padding: 0,
    display: "flex",
  },
  actions: {
    padding: `${theme.spacing(1.25)} ${theme.spacing(4)}`,
  },
  openInNewTabButton: {
    minWidth: 0,
    position: "absolute",
    top: "10px",
  },
  openInNewTabButtonContent: {
    display: "flex",
    alignItems: "center",
  },
  openInNewTabButtonText: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "inline",
    },
  },
  openInNewTabButtonIcon: {
    fontSize: "16px",
    display: "inline",
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(0.5),
    },
  },
  ctaButton: {
    color: theme.palette.white,
  },
}));
