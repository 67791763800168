import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

interface Props {
  isOpen: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  cameraButton: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  container: ({ isOpen }) => ({
    "& svg": {
      "& path": {
        fill: isOpen ? theme.palette.events.bluePrimary : "",
      },
    },
  }),
  popoverContent: {
    boxShadow: "3px 3px 5px 0px rgba(181,179,181,1)",
    backgroundColor: "#fff",
    padding: "11px 0px 6px 0px",
    borderRadius: "10px",
  },
}));

export default useStyles;
