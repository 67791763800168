import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { useStyles } from "./styles";

export enum BestUploadSizes {
  badge = "135x57",
  big = "1080x1080",
  favicon = "16x16",
  landscape = "1920x1080",
  portrait = "780x490",
  small = "480x480",
}

export enum MaxUploadSizes {
  big = "2 MB",
  biggest = "3 MB",
  medium = "1 MB",
  small = "512 KB",
}

interface Props {
  allowGif?: boolean;
  allowIco?: boolean;
  maxSize?: MaxUploadSizes | string;
  bestSize?: BestUploadSizes | string;
}

const UploadCaption = ({
  allowGif = false,
  allowIco = false,
  maxSize = MaxUploadSizes.medium,
  bestSize,
}: Props) => {
  const { t } = useI18n(["common"]);
  const styles = useStyles();

  const keys = {
    // eslint-disable-next-line no-nested-ternary
    key0: allowGif ? "/GIF" : allowIco ? "/ICO" : "",
    key1: maxSize,
    key2: bestSize,
  };

  return (
    <Typography variant="caption" className={styles.uploadCaption}>
      {bestSize
        ? t("image.upload.format", keys)
        : t("image.upload.format.no.best.size", keys)}
    </Typography>
  );
};

export default UploadCaption;
