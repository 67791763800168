import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  description: {
    color: theme.palette.events.bluePrimary,
    fontSize: "11px",
    fontWeight: 900,
    margin: "0",
    marginTop: "5px",
  },
  errorDescription: {
    color: `${theme.palette.red.main}`,
  },
}));
