import isometricPNG from "../../assets/isometric-icon.png";
import modernPNG from "../../assets/modern-icon.png";
import realisticPNG from "../../assets/realistic-icon.png";
import classicPNG from "../../assets/classic-icon.png";

export const getThemeIcon = (theme: string) => {
  switch (theme) {
    case "REALISTIC":
      return realisticPNG;
    case "ISOMETRIC":
      return isometricPNG;
    case "MODERN":
      return modernPNG;
    case "CLASSIC":
    default:
      return classicPNG;
  }
};
