import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: theme.palette.events.graySecondary,
    marginTop: theme.spacing(2),
    borderRadius: "10px",
    gap: "20px",
  },
  text: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    maxWidth: "435px",
    color: theme.palette.events.lightBlack,
  },
  button: {
    marginTop: theme.spacing(3),
  },
  icon: {
    fontSize: "14px",
  },
}));
