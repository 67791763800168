import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import React, { forwardRef } from "react";
import classnames from "classnames";
import { useStyles } from "./styles";
import { IButtonProps } from "../types";

export const ButtonLight = forwardRef(
  (props: IButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const {
      variant = "primary",
      disabled = false,
      fullWidth = false,
      loading = false,
      children,
      component = "button",
      rootClassName,
      sx,
      startAdornment,
      endAdornment,
      tabIndex,
      contentClassName = "",
      startAdornmentClassName = "",
      endAdornmentClassName = "",
      contentWrapperClassName = "",
      size = "md",
      ...rest
    } = props;

    const classes = useStyles(props);
    const rootClass = classnames(
      {
        [classes.fullWidth]: fullWidth,
        [classes.disabled]: loading,
        disabled,
      },
      classes.root,
      classes[variant],
      rootClassName,
    );

    const content = (
      <Typography
        variant="button"
        component="div"
        className={classnames(classes.contentWrapper, classes[size], {
          [contentWrapperClassName]: contentWrapperClassName,
        })}
      >
        {startAdornment && (
          <div
            className={classnames(classes.startAdornment, {
              [startAdornmentClassName]: startAdornmentClassName,
            })}
          >
            {startAdornment}
          </div>
        )}
        <div
          className={classnames(classes.content, {
            [contentClassName]: contentClassName,
          })}
        >
          {children}
        </div>
        {endAdornment && (
          <div
            className={classnames(classes.endAdornment, {
              [endAdornmentClassName]: endAdornmentClassName,
            })}
          >
            {endAdornment}
          </div>
        )}
      </Typography>
    );

    return (
      <ButtonBase
        classes={{
          root: rootClass,
        }}
        component={component as React.ElementType}
        disabled={disabled || loading}
        ref={ref}
        sx={sx}
        tabIndex={tabIndex}
        {...rest}
      >
        {loading ? (
          <>
            <div className={classes.loading}>{content}</div>
            <div data-testid="loading" className={classes.progressContainer}>
              <CircularProgress size={25} thickness={5} color="inherit" />
            </div>
          </>
        ) : (
          content
        )}
      </ButtonBase>
    );
  },
);
