import { ThemeOptions } from "@mui/material/styles";

export const baseTheme: Partial<ThemeOptions> = {
  components: {
    MuiModal: {
      defaultProps: {
        disableEnforceFocus: true,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 720,
      lg: 960,
      xl: 1140,
    },
  },
};
