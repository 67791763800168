import zIndexes from "zIndexes.module.scss";

export const styles = {
  root: {
    zIndex: zIndexes.helpChatZIndex,
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    "&.open": {
      transform: "translateX(15rem)",
    },
  },
  fab: {
    color: "white",
    backgroundColor: "events.bluePrimary",

    "&:hover": {
      color: "white",
      backgroundColor: "events.bluePrimary",
    },
  },
  tooltip: {
    pointerEvents: "none",
    position: "absolute",
    left: 0,
    top: "calc(-100% - 12px)",
    padding: "6px 0",
    boxSizing: "border-box",
    textAlign: "center",
    width: "5.5rem",
    backgroundColor: "events.graySecondary",
    color: "events.textPrimary",
    borderRadius: "6px",
    lineHeight: "1.2rem",
    fontSize: "0.85rem",
    boxShadow: "0px 2px 2px 0 rgb(0 0 0 / 40%)",
    cursor: "pointer",
    "@media (max-width: 600px)": {
      display: "none",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      width: 0,
      height: 0,
      marginLeft: "-0.5em",
      bottom: "-12px",
      left: "26px",
      boxSizing: "border-box",
      border: "6px solid white",
      borderColor: "transparent transparent white white",
      transformOrigin: "0 0",
      transform: "rotate(-45deg)",
      boxShadow: "-2px 2px 3px 0 rgb(0 0 0 / 40%)",
    },
  },
  smallDesktopBg: {
    "&.open": {
      display: "none",
    },
  },
  supportImg: {
    width: "26px",
    position: "relative",
    "@media (max-width: 600px)": {
      width: "30px",
    },
  },
};
