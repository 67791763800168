import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  floors: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "45px",
    height: "45px",
    borderRadius: "50%",
    fontWeight: 700,
    fontSize: "1.4rem",
    margin: `${theme.spacing(0.5)} 0`,
    background: theme.palette.events.bluePrimary,
    color: theme.palette.white,
  },
  error: {
    borderColor: "#f44336",
  },
  floorLimitError: {
    color: theme.palette.blue.darkShade1,
  },
  formHelperText: {
    color: theme.palette.events.bluePrimary,
  },
}));
