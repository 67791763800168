import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  heading: {
    marginTop: theme.spacing(4),
  },
  title: {
    display: "inline",
  },
  inputLabelClass: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.events.lightBlack,
  },
  inputContainerClass: {
    maxHeight: "100px",
  },
  textAreaRoot: {
    "& .MuiInputBase-root": {
      boxShadow: " 4px 4px 4px 0px #0C38911A",
      border: `1px solid ${theme.palette.events.grayPrimary}`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      "&:hover,&:active,&:focus": {
        border: theme.palette.events.bluePrimary,
      },
    },
  },
  enableMessage: {
    paddingRight: "16px",
  },
}));

export default useStyles;
