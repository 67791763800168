import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  welcomeMessage: {
    marginTop: "32px",
  },
  switchInput: {
    color: theme.palette.events.grayTertiary,
    "&.Mui-checked": {
      color: `${theme.palette.events.blueSecondary} !important`,
    },
  },
  heading: {
    marginTop: theme.spacing(4),
  },
  title: {
    display: "inline",
  },
  uploader: {
    position: "relative",
    "& .MuiButtonBase-root": {
      textDecoration: "none",
    },
  },
  imageUploaderContainer: {
    position: "relative",
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  inputLabelClass: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.events.lightBlack,
  },
  inputContainerClass: {
    maxHeight: "100px",
  },
  textAreaRoot: {
    "& .MuiInputBase-root": {
      boxShadow: " 4px 4px 4px 0px #0C38911A",
      border: `1px solid ${theme.palette.events.grayPrimary}`,
    },
  },
  useWelcomeMessageSwitch: {
    marginTop: "30px",
  },
  useWelcomeMessageSwitchInput: {
    color: theme.palette.events.grayTertiary,
  },
  imageUploader: {
    width: "779px",
  },
  uploadCaption: {
    color: theme.palette.events.bluePrimary,
  },
  radioButton: {
    color: `${theme.palette.events.grayTertiary} !important`,
    "&.Mui-checked": {
      color: `${theme.palette.events.blueSecondary} !important`,
    },
  },
}));
