export const styles = {
  headerStyle: {
    padding: "16px",
    whiteSpace: "nowrap",
    background: "rgb(12, 56, 145)",
    color: "white",
    fontSize: "16px",
  },
  searchFieldStyle: {
    height: "30px",
    zIndex: 2,
    boxShadow: "4px 4px 4px 0px #0C38911A",
  },
};
