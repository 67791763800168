import React from "react";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { Switch } from "@remo-co/ui-core/src/components/Switch";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { useI18n } from "i18n";
import { FeatureItem } from "modules/manageEvent/hooks/types";
import { useStyles } from "./styles";

interface IFeatureToggleProps extends FeatureItem {
  testId: string;
  onToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FeatureToggle = ({
  label,
  checked,
  testId,
  name,
  onToggle,
  disabledAtCompanyLevel,
}: IFeatureToggleProps) => {
  const { t } = useI18n(["eventForm", "manageEvent"]);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Tooltip
        placement="top"
        title={
          disabledAtCompanyLevel
            ? t("manageEvent:feature.disabled.company.level")
            : ""
        }
      >
        <FormControlLabel
          label={<div className="feature-label">{label}</div>}
          labelPlacement="start"
          data-testid={testId}
          className={classes.label}
          disabled={disabledAtCompanyLevel}
          control={
            <Switch
              color="primary"
              checked={checked && !disabledAtCompanyLevel}
              onChange={onToggle}
              name={name}
            />
          }
        />
      </Tooltip>
    </div>
  );
};

export default FeatureToggle;
