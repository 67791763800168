import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  dialogContent: {
    padding: `0 !important`,

    "& .mat-content": {
      width: "100%",
    },
  },
}));
