import { ComponentProps } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { CircularProgress as LoadingIcon } from "@remo-co/ui-core/src/components/CircularProgress";
import { FiberManualRecord as RecordIcon } from "@remo-co/ui-core/src/icons/FiberManualRecord";
import { useI18n } from "i18n";
import { useStyles } from "./styles";

const RecordButton = ({
  isProcessing = false,
  ...props
}: ComponentProps<typeof Button> & { isProcessing?: boolean }) => {
  const { t } = useI18n();
  const styles = useStyles();

  return (
    <Button
      {...props}
      id="ctrl-recording"
      data-testid="start-recording-btn"
      className={styles.recordButton}
      size="md"
      disabled={isProcessing}
      variant="ghost"
      color="dark"
      contentWrapperClassName={styles.recordButtonContentWrapper}
      contentClassName={styles.recordButtonContent}
      startAdornment={
        isProcessing ? (
          <LoadingIcon className={styles.loadingIcon} />
        ) : (
          <RecordIcon className={styles.recordIcon} data-testid="record-icon" />
        )
      }
    >
      {isProcessing ? t("processing") : t("recording.start")}
    </Button>
  );
};

export default RecordButton;
