import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { Theme } from "@remo-co/ui-core/src/types";

interface Props {
  isInBroadcast: boolean | null;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  fileInput: {
    top: "48px",
  },
  iconButton: ({ isInBroadcast }) => ({
    color: isInBroadcast
      ? theme.palette.white
      : theme.palette.events.blueSecondary,
  }),
}));

export default useStyles;
