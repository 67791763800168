import { ReactElement } from "react";
import { Cancel as IncorrectIcon } from "@remo-co/ui-core/src/icons/Cancel";
import { CheckCircle as CorrectIcon } from "@remo-co/ui-core/src/icons/CheckCircle";
import classNames from "classnames";
import Avatar from "modules/userAvatar/Avatar";
import { EventPoll, EventPollOption } from "graphql/generated";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { useTheme } from "@remo-co/ui-core/src/hooks/useTheme";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useStyles } from "../Poll/styles";
import { VoteGraph } from "../VoteGraph";

type Props = Pick<EventPoll, "selectedAnswer"> &
  Omit<EventPollOption, "__typename" | "selectedAnswer"> & {
    totalVotes: number;
    isAnswer: boolean;
    reveal: boolean;
    isUserSelection: boolean;
    isHost: boolean;
    displayResult: boolean;
  };

const Result = ({
  id,
  option,
  isAnswer,
  isUserSelection,
  votes,
  totalVotes,
  reveal,
  isHost,
  displayResult,
}: Props): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Container flex fullWidth className={classes.resultContainer}>
      {reveal &&
        (isAnswer ? (
          <CorrectIcon className={classes.correctAnswerIcon} />
        ) : (
          <IncorrectIcon className={classes.incorrectAnswerIcon} />
        ))}
      <div
        id={`${id}`}
        className={classNames(classes.pollAnswer, {
          [classes.activePollOption]: isUserSelection && !reveal,
          [classes.correctAnswerValue]:
            isUserSelection && reveal && isAnswer && !isHost,
          [classes.incorrectAnswerValue]:
            isUserSelection && reveal && !isAnswer && !isHost,
        })}
      >
        <VoteGraph
          votes={votes}
          totalVotes={totalVotes}
          hidden={!reveal || !isHost}
          backgroundColor={
            isAnswer
              ? theme.palette.polls.correctAnswer
              : theme.palette.polls.incorrectAnswer
          }
          displayResult={displayResult}
        >
          <Container
            flex
            justifyContent="space-between"
            className={classes.answerValueContainer}
          >
            <Container flex justifyContent="flex-start">
              {isUserSelection && (
                <Avatar className={classes.userAvatar} size="xs" />
              )}
              <Typography variant="body1">{option}</Typography>
            </Container>
          </Container>
        </VoteGraph>
      </div>
    </Container>
  );
};

export default Result;
