import { createTheme } from "@mui/material/styles";
import { lightPalette } from "./palettes";
import { overrides } from "./overrides";
import { baseTheme } from "./baseTheme";
import { typography } from "./typography";

export const lightTheme = createTheme({
  ...baseTheme,
  palette: lightPalette,
  components: overrides,
  typography,
});
