import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme, { isInBroadcast: boolean }>((theme) => ({
  groupHeadingContainer: ({ isInBroadcast }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.background.default,
    borderBottom: isInBroadcast
      ? `1px solid ${theme.palette.events.lightBlack}`
      : "none",
  }),
  groupHeading: ({ isInBroadcast }) => ({
    margin: "8px 16px",
    textTransform: "capitalize",
    color: isInBroadcast
      ? theme.palette.white
      : theme.palette.events.blueSecondary,
  }),
  moreButton: {
    "& .MuiButtonBase-root": {
      marginRight: "16px",
      color: "var(--White-op-090)",
      "&:hover": {
        backgroundColor: "rgba(2, 2, 2, 0.14)",
      },
    },
  },
}));

export default useStyles;
