import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
    alignItems: "center",
  },
  header: {
    marginBottom: "16px",
  },
  description: {
    width: "540px",
  },
}));
