import { useI18n } from "i18n";
import {
  AttendeeListContainer,
  SpeakersListContainer,
} from "modules/eventMemberList";
import { IEvent } from "modules/event/types";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { FormInfo } from "..";

interface Props {
  eventData: IEvent;
}

const Invitations = ({ eventData }: Props): JSX.Element => {
  const { t } = useI18n(["micCamCheck", "event"]);
  const { isUnlimitedEvent } = eventData;

  return (
    <>
      {!isUnlimitedEvent && (
        <>
          <Typography variant="h2">
            {t("micCamCheck:invite.event.speakers")}
          </Typography>
          <Typography variant="body1" mb={2}>
            {t("micCamCheck:invite.event.speakers.body")}
          </Typography>
          {!eventData.id && <FormInfo text={t("event:email.auto.send")} />}
          <SpeakersListContainer eventData={eventData} />
        </>
      )}

      <Box marginTop={isUnlimitedEvent ? 0 : 8}>
        {!isUnlimitedEvent && <Divider />}
        <Box marginTop={4}>
          <AttendeeListContainer eventData={eventData} />
        </Box>
      </Box>
    </>
  );
};

export default Invitations;
