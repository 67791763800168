import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { ISelectProps } from "./types";

export const useSelectStyles = makeStyles<Theme, ISelectProps>((theme) => ({
  container: {
    borderWidth: "1px",
    borderStyle: "solid",
    fontSize: 14,
    backgroundColor: theme.palette.white,
    margin: "0",

    borderColor: (props) =>
      props.open ? theme.palette.blue.main : theme.palette.events.grayPrimary,
    borderRadius: (props) => (props.open ? "4px 4px 0 0" : "4px"),
    boxShadow: "4px 4px 4px 0px #0C38911A",

    border: `1px solid ${theme.palette.events.grayPrimary}`,
    "&:hover,&:focus": {
      border: `1px solid ${theme.palette.events.blueAccent3}`,
    },

    "& fieldset": {
      border: "none",
    },

    "& .MuiInputBase-root": {
      height: "100%",
    },

    "& .MuiSelect-select": {
      textAlign: "start",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      opacity: (props) =>
        props.placeholder && (!props.value || props.value.length === 0)
          ? "0.85"
          : "1",
    },

    "& .MuiSelect-select > *": {
      display: "inline-block",
    },

    "& .chevron-icon": {
      position: "absolute",
      right: "10px",
      pointerEvents: "none",
      cursor: "pointer",
    },
  },
  value: {
    borderColor: theme.palette.blue.dark,
  },
  disabled: {
    background: theme.palette.gray.disabledInput,
    borderColor: `${theme.palette.gray.light} !important`,
    color: `${theme.palette.blue.darkShade1}70 !important`,

    "&:hover": {
      cursor: "not-allowed",
      background: theme.palette.gray.disabledInput,
      borderColor: theme.palette.gray.light,
    },
  },
  error: {
    borderColor: (props) =>
      !props.open
        ? `${theme.palette.red.main} !important`
        : theme.palette.blue.main,
    "&:hover": {
      borderColor: (props) =>
        !props.open
          ? `${theme.palette.red.main} !important`
          : theme.palette.blue.main,
    },
  },
  small: {
    height: "40px",

    "& .MuiSelect-select": {
      padding: "11px 25px 11px 15px",
    },
  },
  medium: {
    height: "55px",
    "& .MuiSelect-select": {
      padding: "16px 25px 16px 15px",
    },
  },
  popover: {
    maxWidth: (props) => props.width,
    maxHeight: (props) => props.optionHeight,
  },
  popoverPaper: {
    borderRadius: "5px",
    marginLeft: "-1px",
    boxShadow: "4px 4px 4px 0px #0C38911A",
    border: `1px solid ${theme.palette.events.grayPrimary}`,
    maxWidth: (props) =>
      props.disableOptionMaxWidth ? "unset !important" : "calc(100% - 32px)",
    "& .MuiList-root": {
      padding: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: "6px",
    },
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.events.bluePrimary} !important`,
      color: `${theme.palette.white} !important`,
    },
    "& .MuiMenuItem-root": {
      height: "55px",
    },
  },
  errorDescription: {
    fontSize: "12px !important",
    color: `${theme.palette.red.main} !important`,
    padding: "0 !important",
    margin: "0 !important",
  },
  label: {
    fontSize: 14,
    padding: 0,
    margin: 0,
    lineHeight: "16px",
    fontWeight: 500,
    color: `${theme.palette.events.textPrimary} !important`,
  },
  selectOption: {
    display: "flex",
    justifyContent: "space-between",
  },
  selectText: {
    overflowX: "clip",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "14px",
    fontWeight: "400",
  },
  optionDescription: {
    color: theme.palette.blue.darkShade1,
  },
  option: {
    width: "100%",
  },
  listSubheader: {
    pointerEvents: "none",
  },
  checkIcon: {
    width: "14px",
    height: "14px",
  },
}));
