import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  link: {
    marginTop: theme.spacing(2.5),
    padding: "10px",
  },
  heading: {
    marginTop: theme.spacing(2.5),
  },
}));
