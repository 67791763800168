import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  contextMenuItem: {
    paddingTop: "15px",
    paddingRight: "13px",
    paddingBottom: "15px",
    paddingLeft: "13px",
    borderBottom: `1px solid ${theme.palette.border.default}`,
  },
}));
