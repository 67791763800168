import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  listItem: {
    display: "flex",
    alignItems: "center",
  },
  listItemIcon: {
    minWidth: theme.spacing(10),
    maxWidth: theme.spacing(10),
  },
  listItemFunctionIcons: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    gap: "30px",
  },
  listItemName: {
    fontSize: theme.spacing(2.25),
  },
  streamManageIcon: {
    cursor: "pointer",
  },
  functionButton: {
    border: "none",
    background: "none",
    color: theme.palette.events.lightBlack,
    "&:hover": {
      background: "none",
    },
    "&:focus": {
      background: "none",
    },
    "& svg": {
      fill: `${theme.palette.events.lightBlack} !important`,
    },
  },
}));
