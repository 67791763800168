import { useMemo } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import { RemoveRedEye } from "@remo-co/ui-core/src/icons/RemoveRedEye";
import { useI18n } from "i18n";
import classNames from "classnames";
import { IMapTemplate } from "types/theater";
import CustomFloorPlanResultsList from "./CustomFloorPlanResultsList";
import CustomFloorPlanOverlay from "./CustomFloorPlanOverlay";
import { useStyles } from "./Results.styles";
import "./CustomFloorPlanResults.scss";
import { getFullScreenScaleVector } from "./utils";
import SVGWrapper from "./validator/svgWrapper";
import { SVG_FULLSCREEN_TEST_ID, SVG_TEST_ID } from "./constants";

interface ICustomFloorPlanResults {
  localFileUrl: string;
  config?: Partial<IMapTemplate> | null;
  fileSize: number;
  toggleFullScreen(): void;
  isFullscreen: boolean;
  floorBackgroundColor: string;
  overlapCheckResult: string[] | null;
}

const CustomFloorPlanResults = ({
  localFileUrl,
  config,
  fileSize,
  toggleFullScreen,
  isFullscreen,
  floorBackgroundColor,
  overlapCheckResult,
}: ICustomFloorPlanResults) => {
  const { t } = useI18n(["common", "customFloorPlan"]);
  const styles = useStyles();
  const roomsCount = useMemo(
    () =>
      (config?.rooms && config?.rooms.filter((room) => !!room.order).length) ??
      0,
    [config?.rooms],
  );

  const cfArea = config && config.conferenceArea;

  const transformScale =
    config && isFullscreen ? getFullScreenScaleVector(config) : "";

  if (!config) {
    return null;
  }

  return (
    <Container
      style={{
        width: "100%",
        height: "100%",
        flexDirection: "row-reverse",
      }}
      flex
      justifyContent="space-between"
      alignItems="flex-start"
      data-testid="cfp-validator-v2-results"
    >
      {!isFullscreen && (
        <>
          <CustomFloorPlanResultsList
            config={config}
            roomsCount={roomsCount}
            fileSize={fileSize}
            overlapCheckResult={overlapCheckResult}
          />
          <div
            className={styles.svgContainer}
            style={{
              width: config.width,
            }}
          >
            <Button
              className={styles.seeDetails}
              data-testid="cfp-results-fullscreen-on-btn"
              onClick={toggleFullScreen}
              variant="secondary"
              size="sm"
            >
              <Typography variant="h6" className={styles.seeDetailsText}>
                {t("common:see.details")}
              </Typography>
              <RemoveRedEye />
            </Button>
            <SVGWrapper
              localFileUrl={localFileUrl}
              floorBackgroundColor={floorBackgroundColor}
              testId={SVG_TEST_ID}
            />
          </div>
        </>
      )}
      {isFullscreen && (
        <div
          className={classNames(
            styles.svgContainer,
            styles.fullScreenContainer,
          )}
          style={{
            width: config.width,
            height: config.height || 0,
            transform: transformScale,
          }}
        >
          <IconButton
            onClick={toggleFullScreen}
            className={styles.closeBtn}
            data-testid="cfp-results-fullscreen-off-btn"
            size="large"
          >
            <Close />
          </IconButton>
          <SVGWrapper
            localFileUrl={localFileUrl}
            floorBackgroundColor={floorBackgroundColor}
            testId={SVG_FULLSCREEN_TEST_ID}
          />
          <div className={styles.svgConfigWrap}>
            <div
              className={styles.svgConfArea}
              style={{
                width: cfArea?.width,
                height: cfArea?.height,
                left: cfArea?.x,
                top: cfArea?.y,
              }}
              data-testid="cfp-results-overlay"
            >
              <span className={styles.conferenceAreaLabel}>
                {t("customFloorPlan:conference.area.highlighted") as string}
              </span>
              <CustomFloorPlanOverlay
                config={config}
                overlapCheckResult={overlapCheckResult}
              />
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default CustomFloorPlanResults;
