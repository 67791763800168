import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { Theme } from "@remo-co/ui-core/src/types";

interface Props {
  isInBroadcast: boolean | null;
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    height: "calc(100% - 16px)",
  },
  promptText: {
    width: "60%",
  },
  pollIcon: ({ isInBroadcast }) => ({
    color: isInBroadcast
      ? theme.palette.white
      : theme.palette.events.blueSecondary,
  }),
}));
