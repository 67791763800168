import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.gray.light}`,
  },
  preview: {
    marginRight: theme.spacing(2),
  },
  contentName: {
    overflowWrap: "anywhere",
    marginRight: theme.spacing(2),
  },
  info: {
    marginLeft: "auto",
    minWidth: "160px",
    border: `1px solid ${theme.palette.gray.light}`,
    display: "flex",
    marginRight: theme.spacing(1.5),
    padding: theme.spacing(0.5),
    borderRadius: "5px",
  },
  infoHeaders: {
    marginRight: theme.spacing(1),
  },
  infoHeader: {
    fontSize: "12px",
    lineHeight: "26px",
    textTransform: "uppercase",
  },
  infoValues: {
    gap: theme.spacing(0.5),
  },
  infoValue: {
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: 900,
    color: theme.palette.blue.dark,
    background: "#EAEEF2",
    borderRadius: "100px",
    padding: `${theme.spacing(0.75)} ${theme.spacing(1.5)}`,
    alignSelf: "flex-start",
  },
  iconColor: {
    color: theme.palette.events.lightBlack,
  },
}));
