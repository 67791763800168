import React from "react";
import moment from "moment";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { ChevronLeft } from "@remo-co/ui-core/src/icons/ChevronLeft";
import { ChevronRight } from "@remo-co/ui-core/src/icons/ChevronRight";
import { EVENT_AGENDA_CONTEXT } from "./context/EventAgendaContext";
import "./EventDaysScroller.scss";

interface IEventDaysScroller {
  theme?: string;
}

const EventDaysScroller = ({ theme = "" }: IEventDaysScroller) => {
  const scrollRef = React.createRef<HTMLDivElement>();
  const [currentPage, setCurrentPage] = React.useState(0);
  const { eventData, state, actions } = React.useContext(EVENT_AGENDA_CONTEXT);
  const currentDate = state?.currentDate || null;

  // eslint-disable-next-line consistent-return
  const buildEventDays = () => {
    if (eventData) {
      let newStartDate = moment(eventData.startTime).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      const endDate = moment(eventData.endTime).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const items = [];

      while (newStartDate.diff(endDate) <= 0) {
        const dateStr = newStartDate.format("MMMM Do");
        const fullDate = newStartDate.format("YYYY-MM-DD");

        items.push(
          <Typography
            component="div"
            color="inherit"
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            onClick={() => setNewCurrentDate(fullDate)}
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            className={isSameDate(newStartDate) ? "active" : ""}
          >
            {dateStr}
          </Typography>,
        );
        newStartDate = moment(newStartDate).add(1, "day");
      }

      return items;
    }
  };

  // Get date index string comparing with start time (reset to 12AM midnight)
  const getDateString = (date: moment.Moment) =>
    eventData
      ? `day-${date.diff(
          moment(eventData.startTime).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          }),
          "days",
        )}`
      : "";

  const setNewCurrentDate = (date: string) => {
    const dateObj = moment(date);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (!isSameDate(dateObj)) {
      actions.setCurrentDate(getDateString(dateObj));
    }
  };

  const isSameDate = (date: moment.Moment) =>
    currentDate === getDateString(date);

  const noOfDays = React.useMemo(
    () =>
      eventData?.startTime && eventData?.endTime
        ? moment(eventData.endTime)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .diff(
              moment(eventData.startTime).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              }),
              "days",
            ) + 1
        : 0,
    [
      eventData,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      eventData && eventData.startTime,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      eventData && eventData.endTime,
    ],
  );

  const showPrev = () => {
    setCurrentPage(Math.max(0, currentPage - 1));
  };
  const showNext = () => {
    setCurrentPage(Math.min(currentPage + 1, Math.floor(noOfDays / 3)));
  };

  React.useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.style.transform = `translateX(-${currentPage * 100}%)`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <div className={`eah-scroller ${theme}`}>
      {noOfDays > 1 && (
        <IconButton
          disabled={currentPage === 0}
          className="left"
          onClick={showPrev}
          size="large"
        >
          <ChevronLeft />
        </IconButton>
      )}
      <div className="eah-items">
        <div ref={scrollRef}>{buildEventDays()}</div>
      </div>
      {noOfDays > 1 && (
        <IconButton
          disabled={currentPage >= Math.ceil(noOfDays / 3) - 1}
          className="right"
          onClick={showNext}
          size="large"
        >
          <ChevronRight />
        </IconButton>
      )}
    </div>
  );
};

export default EventDaysScroller;
