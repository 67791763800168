import classNames from "classnames";
import { useI18n } from "i18n";
import { useJoinTable } from "modules/joinTable";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import {
  selectCurrentRoomId,
  selectIsChangingRoom,
  selectJoiningRoom,
  setJoiningRoomId,
} from "store/rooms";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { selectIsMobile } from "modules/deviceInfo";
import { useCopyTableLink } from "modules/conversation/hooks/useCopyTableLink";
import { useStyles } from "./styles";

const JoinTableDialog = () => {
  const isChangingTable = useSelector(selectIsChangingRoom);
  const styles = useStyles({ isChangingTable });
  const isMobileView = useSelector(selectIsMobile);
  const { t } = useI18n(["conversation"]);
  const selectedTable = useSelector(selectJoiningRoom);
  const currentTable = useSelector(selectCurrentRoomId);
  const dispatch = useAppDispatch();
  const { switchTable } = useJoinTable();
  const { copyTableLink } = useCopyTableLink();

  const isOnSelectedTable = currentTable === selectedTable?.id;

  if (!selectedTable || isMobileView) {
    return null;
  }

  const handleJoinTable = () => {
    if (isChangingTable) return;

    switchTable({ tableId: selectedTable?.id || "" });
    dispatch(setJoiningRoomId(null));
  };

  const handleCancel = () => {
    dispatch(setJoiningRoomId(null));
  };

  return (
    <div
      className={styles.joinTableDialogContainer}
      data-testid="join-table-dialog-container"
    >
      <Typography className={styles.text} color="inherit">
        {t("conversation:selected.table", { tableName: selectedTable.name })}
      </Typography>
      <div className={styles.buttonsContainer}>
        {!isOnSelectedTable && (
          <Button
            className={classNames(styles.noRadius, styles.button)}
            variant="ghost"
            size="sm"
            onClick={handleJoinTable}
            contentClassName={styles.buttonContent}
            data-testid="join-table-dialog-join-button"
          >
            {t("conversation:join.table")}
          </Button>
        )}
        <Button
          className={classNames(styles.rightRadius, styles.button)}
          variant="ghost"
          size="sm"
          contentClassName={styles.buttonContent}
          onClick={handleCancel}
          data-testid="join-table-dialog-cancel-button"
        >
          {t("conversation:cancel")}
        </Button>
        <Button
          className={classNames(styles.rightRadius, styles.button)}
          variant="ghost"
          size="sm"
          contentClassName={styles.buttonContent}
          onClick={() => {
            copyTableLink(selectedTable.id);
            handleCancel();
          }}
          data-testid="join-table-dialog-copy-table-link"
        >
          {t("conversation:copy.table.link")}
        </Button>
      </div>
    </div>
  );
};

export default JoinTableDialog;
