import { ReactElement } from "react";
import classNames from "classnames";
import { EventPoll, EventPollOption } from "graphql/generated";
import { useTheme } from "@remo-co/ui-core/src/hooks/useTheme";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useStyles } from "./styles";
import { VoteGraph } from "../VoteGraph";

type Props = Pick<EventPoll, "selectedAnswer"> &
  Omit<EventPollOption, "__typename"> & {
    totalVotes: number;
    displayResult: boolean;
  };

export const Result = ({
  option,
  selectedAnswer,
  votes,
  totalVotes,
  id,
  displayResult,
}: Props): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();

  const isUserSelection = selectedAnswer === id;

  return (
    <div className={classNames(classes.pollAnswer, classes.votes)}>
      <VoteGraph
        totalVotes={totalVotes}
        votes={votes}
        hidden={false}
        backgroundColor={
          isUserSelection
            ? theme.palette.polls.selectedAnswer
            : theme.palette.polls.unselectedAnswer
        }
        displayResult={displayResult}
        isUserSelection={isUserSelection}
      >
        <Container
          flex
          justifyContent="flex-start"
          className={classes.pollAnswerContainer}
        >
          <Typography variant="body1" className={classes.pollAnswerValue}>
            {option}
          </Typography>
        </Container>
      </VoteGraph>
    </div>
  );
};

export default Result;
