import { useMutation } from "@apollo/client";
import { useI18n } from "i18n";
import { SetStateAction, Dispatch, useContext } from "react";
import { useSelector } from "react-redux";
import {
  DeleteEventPollMutation,
  DeleteEventPollMutationVariables,
  EventPollStatus,
  ChangePollStatusMutation,
  ChangePollStatusMutationVariables,
  EventPollType,
  EventPoll,
} from "graphql/generated";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Edit as EditIcon } from "@remo-co/ui-core/src/icons/Edit";
import { Delete as DeleteIcon } from "@remo-co/ui-core/src/icons/Delete";
import { CloudDownload as CloudDownloadIcon } from "@remo-co/ui-core/src/icons/CloudDownload";
import {
  selectCurrentEvent,
  selectCurrentEventId,
} from "modules/event/selectors";
import { CHANGE_POLL_STATUS, DELETE_POLL } from "modules/polls/graphql";
import { useAppDispatch } from "store/hooks";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { Actions, trackAction } from "modules/monitoring";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { selectLiveUserCount } from "modules/eventUserPresence/redux/selectors";
import { useAppMessage } from "@daily-co/daily-react";
import {
  CustomAppEvent,
  CustomAppEvents,
} from "modules/audioVideo/DailyEvents";
import { makeSelectUsersInEvent } from "modules/event/usersInEvent/selectors";
import { useStyles } from "./styles";
import utils from "../../utils";

interface Props {
  isQuiz?: boolean;
  status: EventPollStatus;
  poll: EventPoll;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  totalVotes?: number;
}

export const HostControls = ({
  poll,
  isQuiz = false,
  status,
  setIsEditing,
  totalVotes,
}: Props) => {
  const classes = useStyles();
  const { t } = useI18n(["event", "common", "polls"]);
  const eventId = useSelector(selectCurrentEventId);
  const usersInEvent = useSelector(makeSelectUsersInEvent);
  const [changePollStatus, { loading }] = useMutation<
    ChangePollStatusMutation,
    ChangePollStatusMutationVariables
  >(CHANGE_POLL_STATUS);
  const [deletePoll, deleteStatus] = useMutation<
    DeleteEventPollMutation,
    DeleteEventPollMutationVariables
  >(DELETE_POLL);
  const dispatch = useAppDispatch();
  const { track } = useContext(TRACKING_CONTEXT);
  const currentEvent = useSelector(selectCurrentEvent);
  const onlineUserCount = useSelector(selectLiveUserCount);
  const sendMessage = useAppMessage<CustomAppEvent>();

  const prefix = isQuiz ? "quiz" : "polls";

  if (!eventId) {
    return null;
  }

  const onButtonClick = (newStatus: EventPollStatus) => {
    trackAction(Actions.POLLS_CHANGE_STATUS, {
      status: newStatus,
    });
    const isOngoing = newStatus === EventPollStatus.Ongoing;
    const pollType = isQuiz ? EventPollType.Quiz : EventPollType.Poll;
    track(isOngoing ? Events.POLL_STARTED : Events.POLL_ENDED, {
      eventId: currentEvent?.id,
      eventType: currentEvent?.eventType,
      pollType,
      ...(!isOngoing && { totalVotes, onlineUserCount }),
    });

    if (isOngoing) {
      sendMessage({
        event: CustomAppEvents.POLL_QUIZ_LAUNCHED,
        pollType,
      });
    }

    changePollStatus({
      variables: {
        pollId: poll.id,
        pollStatus: newStatus,
        eventId,
      },
    });
  };

  const handleDeletePoll = () => {
    track(Events.POLL_DELETED, {
      eventId: currentEvent?.id,
      eventType: currentEvent?.eventType,
      pollType: isQuiz ? EventPollType.Quiz : EventPollType.Poll,
    });

    deletePoll({
      variables: {
        pollId: poll.id,
        eventId,
      },
    });
  };

  return (
    <Container
      flex
      justifyContent="space-between"
      className={classes.hostControls}
    >
      <div className={classes.hostControlsIcons}>
        {status !== EventPollStatus.Closed && (
          <Button
            onClick={() =>
              setIsEditing((isCurrentlyEditing: boolean) => !isCurrentlyEditing)
            }
            isIconButton
            variant="ghost"
            color="blue"
            data-testid={`${prefix}-edit`}
          >
            <EditIcon titleAccess={t(`polls:${prefix}.edit`)} />
          </Button>
        )}
        <Button
          loading={deleteStatus.loading}
          isIconButton
          variant="ghost"
          color="red"
          onClick={() => {
            trackAction(Actions.POLLS_CHANGE_STATUS, {
              pollId: poll.eventId,
            });
            dispatch(
              addDialogNotification({
                title: t("common:are.you.sure"),
                message: t(`${prefix}.confirm.delete`),
                confirmText: t("button.delete"),
                dismissText: t("cancel"),
                onConfirm: handleDeletePoll,
              }),
            );
          }}
          data-testid={`${prefix}-delete`}
        >
          <DeleteIcon titleAccess={t(`polls:${prefix}.delete`)} />
        </Button>
        {status === EventPollStatus.Closed && (
          <Button
            isIconButton
            variant="ghost"
            color="blue"
            onClick={() => {
              utils.downloadInEventCSV(
                `${poll.title}-results.csv`,
                poll,
                usersInEvent,
              );
            }}
            data-testid={`${prefix}-export`}
          >
            <CloudDownloadIcon titleAccess={t(`polls:${prefix}.export`)} />
          </Button>
        )}
      </div>
      {status === EventPollStatus.Draft && (
        <Button
          loading={loading}
          onClick={() => onButtonClick(EventPollStatus.Ongoing)}
          color="blue"
          size="md"
          variant="primary"
        >
          {t(`${prefix}.start`)}
        </Button>
      )}
      {status === EventPollStatus.Ongoing && (
        <Button
          loading={loading}
          onClick={() => {
            dispatch(
              addDialogNotification({
                title: t("common:are.you.sure"),
                message: t(`${prefix}.confirm.end.ongoing`),
                confirmText: t(`${prefix}.end`),
                dismissText: "Cancel",
                onConfirm: () => onButtonClick(EventPollStatus.Closed),
              }),
            );
          }}
          color="red"
          size="md"
          variant="primary"
        >
          {t(`${prefix}.end`)}
        </Button>
      )}
      {status === EventPollStatus.Closed && (
        <Button
          loading={loading}
          color="gray"
          size="md"
          disabled
          variant="primary"
        >
          {t(`${prefix}.ended`)}
        </Button>
      )}
    </Container>
  );
};
