import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  tab: {
    minWidth: "unset",
    fontSize: "14px",
    fontWeight: 400,
  },
  tabs: {
    justifyContent: "space-between",
    "& .Mui-selected": {
      borderBottom: `1.5px solid ${theme.palette.events.bluePrimary}`,
      color: theme.palette.text.primary,
    },
  },
  tabIcon: {
    transition: "transform 0.3s ease, color 0.3s ease",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  indicator: { display: "none" },
  hidePanelButton: {
    margin: theme.spacing(2),
    width: "30px",
    height: "30px",
    padding: 0,
    border: 0,
    background: theme.palette.events.grayPrimary,
    color: theme.palette.text.primary,
    cursor: "pointer",
    borderRadius: "8px",
    "&:focus": {
      outline: "none",
    },
  },
  hidePanelButtonLight: {
    background: theme.palette.events.grayPrimary,
    color: theme.palette.text.primary,
  },
  hidePanelButtonDark: {
    background: "#FFFFFF0D",
    color: theme.palette.events.graySecondary,
  },
  tabsRoot: {
    width: "80%",
  },
}));

export default useStyles;
