import { useContext } from "react";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { useI18n } from "i18n";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { ContactChannelTypes, IContactChannel } from "modules/eventForm/types";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { useStyles } from "./styles";
import { ContentChannelItem } from "../ContentChannelItem";

export const ContactChannelSection = () => {
  const { state, updateEventData } = useContext(MANAGE_EVENT_CONTEXT);
  const eventData = state?.eventData ?? undefined;
  const { t } = useI18n(["common", "eventForm"]);
  const styles = useStyles();

  const setContactChannels = (contactChannels: IContactChannel[]) => {
    updateEventData({ contactChannels });
  };

  const addBlankContactChannel = () => {
    const newContactChannels = eventData?.contactChannels
      ? [...eventData.contactChannels]
      : [];
    newContactChannels.push({ type: ContactChannelTypes.EMAIL, value: "" });
    setContactChannels(newContactChannels);
  };

  const handleContactChannelFieldChange = <K extends keyof IContactChannel>(
    index: number,
    field: K,
    value: K extends "type" ? ContactChannelTypes : string,
  ) => {
    const newContactChannels = [...(eventData?.contactChannels ?? [])];
    newContactChannels[index][field] = value as IContactChannel[K];

    setContactChannels(newContactChannels);
  };

  const handleContactChannelDelete = (index: number) => {
    const newContactChannels = [...(eventData?.contactChannels ?? [])];
    newContactChannels.splice(index, 1);

    setContactChannels(newContactChannels);
  };

  return (
    <Container
      className={styles.container}
      bottom="sm"
      data-testid="contact-channel-section"
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h5">{t("eventForm:contact.channel")} </Typography>
        <Tooltip
          placement="top"
          className={styles.helpIcon}
          title={t("eventForm:contact.channel.tooltip")}
        >
          <Info style={{ verticalAlign: "middle" }} color="primary" />
        </Tooltip>
      </Box>
      {eventData?.contactChannels?.map((channel, index) => (
        <ContentChannelItem
          handleChange={handleContactChannelFieldChange}
          handleDelete={handleContactChannelDelete}
          index={index}
          channel={channel}
        />
      ))}
      <Button
        color="blue"
        size="md"
        variant="secondary"
        disabled={
          eventData?.contactChannels && eventData.contactChannels.length >= 3
        }
        className={styles.addChannelButton}
        contentClassName={styles.addChannelButtonContent}
        onClick={addBlankContactChannel}
        data-testid="add-channel-button"
        theme="light"
      >
        {t("eventForm:add.contact.channel")}
      </Button>
    </Container>
  );
};
