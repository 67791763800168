import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { IAvatarProps } from "./Avatar";
import { getColor, IGetColorParams } from "./utils";

export const useStyles = makeStyles<Theme, IAvatarProps>((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    padding: 0,
    fontSize: "inherit",
    fontWeight: "normal",
  },
  admin: {
    position: "absolute",
    top: -6,
    left: -8,
  },
  micCamIssues: {
    position: "absolute",
    bottom: -1,
    right: -3,
  },
  badge: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    width: "115%",
    height: "32%",
    bottom: -9,
  },
  remoBadge: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    borderRadius: "10px",
    width: "115%",
    height: "32%",
    backgroundColor: theme.palette.white,
    bottom: "-6px",
    border: "1px solid",
    borderColor: theme.palette.grey[400],
  },
  avatar: {
    boxSizing: "border-box",
    color: (props: IGetColorParams) => {
      const color = getColor(props);

      return theme.palette.getContrastText(color);
    },
    backgroundColor: getColor,
    width: "100%",
    height: "100%",
    lineHeight: "initial",
    fontSize: "inherit",
  },
  xs: {
    width: 24,
    height: 24,
    fontSize: "0.8rem",
  },
  sm: {
    fontSize: "0.9rem",
    width: 32,
    height: 32,
  },
  md: {
    width: 40,
    height: 40,
    fontSize: "1.0rem",
  },
  lg: {
    width: 56,
    height: 56,
    fontSize: "1.3rem",
  },
  toolTip: {
    background: theme.palette.grey[200],
    boxShadow: "2px 2px 4px 0px rgba(181,179,181,1)",
    height: theme.spacing(5.5),
    width: theme.spacing(5.5),
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  toolTipArrow: {
    left: theme.spacing(2.7, "!important"),
    color: theme.palette.grey[200],
    boxShadow: "2px 2px 4px 0px rgba(181,179,181,1)",
  },
}));
