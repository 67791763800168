import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  formDescription: {
    fontSize: "10px",
    fontWeight: 900,
    color: theme.palette.events.bluePrimary,
    marginTop: "10px",
  },
}));
