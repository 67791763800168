import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

interface Props {
  isInBroadcast: boolean | null;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignContent: "space-between",
    boxSizing: "border-box",
    overflowY: "auto",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      background: "none",
    },
  },
  header: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "50px",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "#151515",
      color: "#ffffff",
      boxSizing: "border-box",
    },
  },
  headerText: {
    display: "flex",
    alignItems: "center",
    padding: "15px",
    "& > svg": {
      marginRight: "5px",
    },
  },
  headerButton: {
    display: "flex",
    justifyContent: "center",
    marginRight: "15px",
    "& > button": {
      color: "#fff",
    },
  },
  layoutContainer: {
    padding: theme.spacing(2),
    paddingTop: 0,
    maxWidth: "40rem",
    minWidth: "20rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
  },
  questionList: {
    marginTop: "8px",
    marginBottom: "8px",
    maxHeight: "calc(100% - 79px)",
    overflowY: "auto",
    height: "100%",
  },
  list: {
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "var(--White-op-030)",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#45475e",
      borderRadius: "5px",
    },
    scrollbarColor: "#45475e var(--White-op-030)",
  },
  questionListItem: {
    display: "flex",
    border: `1px solid ${theme.palette.border.default}`,
    color: theme.palette.text.primary,
    borderRadius: "6px",
    padding: "8px 14px",
    marginBottom: "10px",
    "&:lastChild": {
      marginBottom: 0,
    },
    "&:hover": {
      "& .answered": {
        visibility: "visible",
      },
    },
  },
  voteContainer: {
    width: "36px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    "& > *": {
      borderRadius: "5px",
    },
  },
  voteButton: ({ isInBroadcast }) => ({
    backgroundColor: isInBroadcast
      ? theme.palette.events.lightBlack
      : theme.palette.events.grayPrimary,
    borderRadius: "5px",
    width: "100%",
    marginBottom: "3px",
    "&:hover": {
      opacity: 0.75,
    },
    "& svg": {
      color: theme.palette.events.bluePrimary,
    },
  }),
  voted: {
    backgroundColor: `${theme.palette.events.bluePrimary} !important`,
    color: `${theme.palette.white} !important`,
    "&:hover": {
      opacity: 0.75,
      backgroundColor: theme.palette.events.bluePrimary,
    },
    "& svg": {
      color: `${theme.palette.white} !important`,
    },
  },
  voteCount: {
    textAlign: "center",
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
  voteText: {
    textAlign: "center",
    fontSize: "12px",
  },
  questionDescription: {
    minWidth: "10rem",
    marginLeft: "20px",
    wordWrap: "break-word",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  authorName: {
    color: `${theme.palette.text.primary}`,
    fontSize: "12px",
    paddingTop: "0.5rem",
  },
  question: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  answeredContainer: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  answered: {
    borderRadius: "4px",
    height: "24px",
    width: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiButtonBase-root": {
      padding: "0px",
      "& .MuiSvgIcon-root": {
        height: "20px",
        width: "20px",
        color: theme.palette.text.primary,
      },
    },
  },
  askQuestionContainer: {
    position: "relative",
    alignItems: "center",
    display: "flex",
    backgroundColor: theme.palette.textInput.backgroundColor,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.border.default}`,
    padding: "0px 10px",
    "& fieldset": {
      border: "none",
      height: "36px",
    },
    "& .MuiOutlinedInput-multiline": {
      paddingRight: "55px",
    },
    "& textarea": {
      minHeight: "20px",
      lineHeight: 1.5,
    },
  },
  questionTextField: {
    margin: 0,
    minHeight: "36px",
    lineHeight: "20px",
  },
  sendButton: {
    height: "28px",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    "&:hover": {
      filter: "brightness(1.2)",
    },
  },
  askButton: {
    padding: "0px 30px",
    textTransform: "none",
  },
  askText: {
    color: "inherit",
  },
}));

export default useStyles;
