import { useI18n } from "i18n";
import ModalWindow from "modules/common/modalWindow/ModalWindow";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { styles } from "./styles";

type Props = {
  open: boolean;
  onUpdate: () => void;
  onCancel: () => void;
};

const RollbackPrompt = ({ open, onUpdate, onCancel }: Props) => {
  const { t } = useI18n(["common"]);

  return (
    <ModalWindow open={open} maxWidth="xs" sx={styles.root} onClose={onCancel}>
      <Box data-testid="rollback-prompt" sx={styles.content}>
        <Box sx={styles.container}>
          <Typography variant="subtitle2">{t("rollback.newUpdate")}</Typography>
        </Box>
        <Box>
          <Typography variant="body1" sx={styles.title}>
            {t("rollback.title")}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="primary"
            color="blue"
            onClick={onUpdate}
            data-testid="update-button"
            sx={styles.button}
          >
            {t("rollback.cta")}
          </Button>
        </Box>
      </Box>
    </ModalWindow>
  );
};

export default RollbackPrompt;
