import { useI18n } from "i18n";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogContentText } from "@remo-co/ui-core/src/components/DialogContentText";
import { DialogActions } from "@remo-co/ui-core/src/components/DialogActions";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useStyles } from "./styles";

export interface ISendEmailConfirmationPopup {
  onClose: (sendInvite: boolean) => void;
}

const SendEmailConfirmationPopup = ({
  onClose,
}: ISendEmailConfirmationPopup): JSX.Element | null => {
  const styles = useStyles();
  const { t } = useI18n(["common"]);
  return (
    <Dialog
      open
      maxWidth={false}
      id="email-confirm-dialog"
      data-testid="broadcast-confirm-dialog"
    >
      <DialogTitle className={styles.dialogTitle}>
        <Typography variant="h3">{t("send.invitations")}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("dialog.message.invite.guest")}
        </DialogContentText>
      </DialogContent>
      <Box>
        <DialogActions
          style={{
            justifyContent: "center",
          }}
        >
          <Button
            variant="primary"
            color="blue"
            onClick={() => onClose(true)}
            size="md"
            data-testid="confirm-invite-guest"
            contentWrapperClassName={styles.buttonWrapper}
            theme="light"
          >
            {t("dialog.confirm.invite.guest")}
          </Button>
          <Button
            onClick={() => onClose(false)}
            variant="tertiary"
            color="dark"
            size="md"
            data-testid="cancel-invite-guest"
            contentWrapperClassName={styles.buttonWrapper}
            theme="light"
          >
            {t("dialog.cancel.invite.guest")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default SendEmailConfirmationPopup;
