import { useSelector } from "react-redux";
import { selectIsBroadcasting } from "modules/broadcast/redux/selectors";
import { useI18n } from "i18n";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { UserItem } from "modules/participants/types";
import { memo } from "react";
import classNames from "classnames";
import { useStyles } from "./styles";
import locateIcon from "../assets/locate.svg";
import locateIconWhite from "../assets/locate-white.svg";
import { useLocateParticipant } from "../../hooks/useLocateParticipant";

interface Props {
  user: UserItem;
  isFromUserRow?: boolean;
  onLocate?: () => void;
}

export const LocateParticipantButton = memo(
  ({ user, onLocate, isFromUserRow }: Props) => {
    const { t } = useI18n(["participants"]);
    const isBroadcasting = useSelector(selectIsBroadcasting);
    const { locateParticipant } = useLocateParticipant(user);
    const styles = useStyles();

    if (isBroadcasting) {
      return null;
    }

    const handleLocateParticipant = () => {
      if (onLocate) {
        onLocate();
      }
      locateParticipant();
    };

    return (
      <IconButtonWithTooltip
        className={classNames(styles.locateButton, {
          [styles.background]: isFromUserRow,
        })}
        id="locate-participant"
        data-testid="locate-participant-button"
        title={t("participants:participant.locate")}
        onClick={handleLocateParticipant}
      >
        <img
          src={isFromUserRow ? locateIconWhite : locateIcon}
          alt={t("participants:participant.locate")}
        />
      </IconButtonWithTooltip>
    );
  },
);
