import { useI18n } from "i18n";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useState, useCallback } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { useCompanyPlanSettings } from "modules/companyPlanSettings/hooks/useCompanyPlanSettings";
import useCompanySplitTreatment from "modules/split/useCompanySplitTreatment";
import { CompanyFeatures } from "services/splitService/features";
import { useStyles } from "./styles";
import { View } from "../View";

interface Props {
  eventId: string;
  eventName?: string;
}

export const Analytics = ({
  eventId,
  eventName,
}: Props): JSX.Element | null => {
  const styles = useStyles();
  const { t } = useI18n(["event", "manageEvent", "url"]);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const { myCompanyPlan, isFreePlan } = useCompanyPlanSettings();

  const { featureEnabled } = useCompanySplitTreatment(
    CompanyFeatures.EVENT_ANALYTICS,
  );

  const getTooltipContent = () => {
    if (isFreePlan(myCompanyPlan)) {
      return t("manageEvent:feature.disabled");
    }
    return t("event:analytics.view");
  };

  const handleOpenDashboard = useCallback(() => {
    setIsViewOpen(true);
  }, []);

  const handleCloseDashboard = useCallback(() => {
    setIsViewOpen(false);
  }, []);

  if (!featureEnabled) {
    return (
      <Box className={styles.container}>
        <div className={styles.description}>
          <Typography variant="h4" className={styles.header}>
            {t("event:analytics")}
          </Typography>
          <Typography variant="body1">
            {t("event:analytics.description")}
          </Typography>
        </div>
        <Button<"a">
          variant="secondary"
          href={t("url:request.analytics.mailto")}
          color="blue"
          theme="light"
        >
          {t("event:analytics.button.text")}
        </Button>
      </Box>
    );
  }

  const isOpenViewButtonDisabled = isViewOpen || isFreePlan(myCompanyPlan);

  return (
    <Box className={styles.container}>
      <div className={styles.description}>
        <Typography variant="h4" className={styles.header}>
          {t("event:analytics")}
        </Typography>
        <Typography variant="body1">
          {t("event:analytics.description")}
        </Typography>
      </div>
      <div>
        <Tooltip title={getTooltipContent()} placement="top">
          <div>
            <Button
              onClick={handleOpenDashboard}
              disabled={isOpenViewButtonDisabled}
              data-testid="dashboard-btn"
              id={`ev-${eventId}-dashboard-btn`}
              variant="secondary"
              color="blue"
              theme="light"
            >
              {t("event:analytics.view")}
            </Button>
          </div>
        </Tooltip>
      </div>
      {isViewOpen && (
        <View
          eventId={eventId}
          eventName={eventName}
          onClose={handleCloseDashboard}
        />
      )}
    </Box>
  );
};

export default Analytics;
