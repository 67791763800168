import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  locateButton: {
    padding: 8,
    marginRight: 8,
  },
  background: {
    backgroundColor: theme.palette.events.blueSecondary,
    "&:hover": {
      background: theme.palette.events.blueSecondary,
    },
  },
}));
