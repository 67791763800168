import { ServiceName } from "./constants";

export const checkIfConsentGiven = async (serviceName: ServiceName) => {
  if (!window.__ucCmp) return true;

  const consentDetails = await window.__ucCmp.getConsentDetails();
  const segmentService = Object.values(consentDetails.services).find(
    (service) => service.name === serviceName,
  );

  return !!segmentService?.consent.given;
};
