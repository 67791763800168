import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: theme.palette.events.graySecondary,
    marginTop: theme.spacing(2),
    borderRadius: "10px",
  },
  text: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    maxWidth: "36rem",
    color: theme.palette.events.lightBlack,
    width: "415px",
  },
  button: {
    height: "unset",
    marginTop: theme.spacing(2),
  },
  content: {
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing(1.5)} ${theme.spacing(5)}`,
  },
  icon: {
    fontSize: "14px",
  },
}));
