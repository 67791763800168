import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "zIndexes.module.scss";

export const useStyles = makeStyles<Theme>((theme) => ({
  poll: {
    backgroundColor: theme.palette.polls.background,
    borderRadius: theme.shape.borderRadius * 2.5,
    padding: theme.spacing(2.5),
    position: "relative",
    boxSizing: "border-box",
    width: "90%",
    border: `1px solid ${theme.palette.polls.border}`,
    margin: "20px auto",
  },
  metadata: {
    marginBottom: theme.spacing(0.5),
  },
  createdData: {
    marginLeft: theme.spacing(0.5),
    maxWidth: 220,
    minWidth: 200,
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
    whiteSpace: "nowrap",
  },
  pill: {
    borderRadius: theme.shape.borderRadius * 25,
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1.5),
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(1.5),
    textTransform: "capitalize",
  },
  pillOngoing: {
    backgroundColor: theme.palette.polls.ongoing,
    color: theme.palette.blue.main,
  },
  pillDraft: {
    backgroundColor: theme.palette.polls.draft,
  },
  pillEnded: {
    backgroundColor: theme.palette.polls.closed,
  },
  question: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  pollOption: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.polls.otherAnswer,
    borderColor: theme.palette.border.default,
    borderRadius: "5px",
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1.5),
    wordBreak: "break-word",
    width: "100%",
    wordSpacing: 2,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.polls.selectedAnswer,
      borderColor: theme.palette.polls.selectedBorder,
    },
    "& > div": {
      "& > div": {
        padding: "0px 10px",
        textAlign: "left",
      },
    },
  },
  optionContentWrapper: {
    padding: "0 10px",
  },
  activePollOption: {
    backgroundColor: theme.palette.polls.selectedAnswer,
    borderColor: theme.palette.polls.selectedBorder,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius * 1.25,
    "&:focus": {
      backgroundColor: theme.palette.polls.selectedAnswer,
      borderColor: theme.palette.polls.selectedBorder,
      color: theme.palette.dark.main,
    },
  },
  pollAnswer: {
    boxSizing: "border-box",
    width: "100%",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius * 1.25,
    display: "flex",
    justifyContent: "flex-start",
    overflow: "hidden",
    position: "relative",
    wordSpacing: 2,
    height: "41px",
  },
  votes: {
    justifyContent: "space-between",
    padding: theme.spacing(1.5),
    marginBottom: "10px",
  },
  pollAnswerValue: {
    width: 190,
    zIndex: 1,
    wordWrap: "break-word",
  },
  pollAnswerValuePercentage: {
    textAlign: "center",
    minWidth: 70,
    maxWidth: 85,
    zIndex: 1,
  },
  answerValueContainer: {
    zIndex: 1,
    alignItems: "center",
    borderRadius: theme.shape.borderRadius * 1.25,
    display: "flex",
    height: "100%",
    paddingLeft: theme.spacing(1.5),
    width: "100%",
  },
  correctAnswerIcon: {
    zIndex: 1,
    fill: theme.palette.green.main,
    marginRight: theme.spacing(1),
  },
  incorrectAnswerIcon: {
    zIndex: 1,
    fill: theme.palette.status.error,
    marginRight: theme.spacing(1),
  },
  countDown: {
    marginBottom: theme.spacing(2.5),
  },
  incorrectAnswerMessage: {
    color: theme.palette.status.error,
  },
  correctAnswerMessage: {
    color: theme.palette.green.main,
  },
  userAvatar: {
    borderColor: theme.palette.green.main,
    borderRadius: "100%",
    borderStyle: "solid",
    borderWidth: 2,
    boxSizing: "border-box",
    marginRight: theme.spacing(1),
  },
  animationContainer: {
    pointerEvents: "none",
    left: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    zIndex: zIndexes.muiModalZIndex,
  },
  statsContainer: {
    marginTop: theme.spacing(1.5),
  },
  statsDivider: {
    marginTop: theme.spacing(2.5),
  },
  linearProgress: {
    height: 10,
    borderRadius: 5,
    marginTop: theme.spacing(0.5),
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.events.bluePrimary,
  },
  correctAnswerValue: {
    backgroundColor: theme.palette.polls.correctAnswer,
  },
  incorrectAnswerValue: {
    backgroundColor: theme.palette.polls.incorrectAnswer,
  },
  optionsDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  pollAnswerContainer: {
    padding: "10px 0",
  },
  resultContainer: {
    padding: "10px 0",
    height: "41px",
    marginBottom: "10px",
    alignItems: "center",
  },
}));
