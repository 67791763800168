import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { LabelPosition } from "./FloorPlanSelectTags";

interface Props {
  labelPosition?: LabelPosition;
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  filterText: {
    minWidth: 65,
    textAlign: "initial",
    marginBottom: "4px",
  },
  selectBox: ({ labelPosition }) => ({
    flexDirection: labelPosition === "top" ? "column" : "row",
    alignItems: labelPosition === "top" ? "left" : "center",
  }),
  selectCreateText: {
    paddingLeft: 10,
    paddingTop: 10,
    fontWeight: 600,
  },
  selectOption: {
    padding: 0,
    "& span": {
      margin: "0px !important",
    },
  },
  selectList: {
    padding: 0,
  },
  selectField: {
    minWidth: 280,
    border: `1px solid ${theme.palette.events.grayPrimary}`,
    boxShadow: " 4px 4px 4px 0px #0C38911A",
    "&:focus, &:hover": {
      borderColor: `${theme.palette.events.bluePrimary} !important`,
    },
  },
  tagsInput: {
    borderRadius: 5,
    minHeight: 40,
    paddingLeft: 10,
    paddingRight: "20px !important",
    "&:before, &:after": {
      border: "0 !important",
    },
    height: "50px",
  },
}));
