import React from "react";
import classNames from "classnames";
import { Theme } from "@remo-co/ui-core/src/types";
import { SxProps } from "@mui/material/styles";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogActions } from "@remo-co/ui-core/src/components/DialogActions";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { Slide } from "@remo-co/ui-core/src/components/Slide";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Close as CloseIcon } from "@remo-co/ui-core/src/icons/Close";
import "./ModalWindow.scss";
import { useI18n } from "i18n";

const Transition = (props: React.ComponentProps<typeof Slide>) => (
  <Slide direction="up" {...props} />
);

interface ModalWindowProps {
  absoluteHeader?: boolean;
  actionsComponent?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  disableCloseOnBackdrop?: boolean;
  fullScreen?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  onClose?: () => void;
  open?: boolean;
  shrinkHeader?: boolean;
  title?: string;
  sx?: SxProps<Theme>;
}

const ModalWindow = (props: ModalWindowProps): JSX.Element => {
  const {
    open = false,
    fullScreen,
    maxWidth,
    disableCloseOnBackdrop,
    onClose,
    title,
    children,
    actionsComponent,
    className,
    shrinkHeader,
    absoluteHeader,
    sx,
  } = props;

  const headerClasses = classNames({
    "shrink-header": shrinkHeader,
    "absolute-header": absoluteHeader,
  });
  const { t } = useI18n();

  return (
    <Dialog
      maxWidth={fullScreen ? undefined : maxWidth}
      fullWidth={!fullScreen}
      fullScreen={!!fullScreen}
      open={open}
      onClose={disableCloseOnBackdrop ? undefined : onClose}
      TransitionComponent={Transition}
      className={`model-window-container ${className}`}
      sx={sx}
    >
      <DialogTitle className={headerClasses}>
        {title && (
          <Typography display="inline" variant="h6" align="left">
            {title}
          </Typography>
        )}
        {onClose ? (
          <IconButton
            aria-label={t("modal.window.aria.label")}
            className="close-button"
            onClick={onClose}
            data-testid="modal-close-button"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actionsComponent && (
        <>
          <Divider />
          <DialogActions>{actionsComponent}</DialogActions>
        </>
      )}
    </Dialog>
  );
};

/** @deprecated use import { Dialog } from "@remo-co/ui-core" instead */
export default ModalWindow;
