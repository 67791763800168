export const styles = {
  header: {
    display: "-webkit-inline-box",
    backgroundColor: "#fff",
    mb: "1.875rem",
    overflow: "hidden",
    width: "800px",
  },
  timeContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginRight: "40px",
    alignItems: "center",
    justifyContent: "center",
  },
};
