import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  contextMenu: {
    "& .MuiPaper-root": {
      width: 300,
    },
    "& .MuiList-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));
