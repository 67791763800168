import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  container: {
    paddingBottom: "16px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
}));
