import { InfoRounded } from "@remo-co/ui-core/src/icons/InfoRounded";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Link } from "@remo-co/ui-core/src/components/Link";
import { ChangeEvent } from "react";
import { useI18n } from "i18n";
import { useAppDispatch } from "store/hooks";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useStyles } from "./styles";

interface Props {
  isNetworkingRecommendationsEnabled: boolean;
  disabled: boolean;
  handleConfirmation: (checked: boolean) => void;
}

export const NetworkingRecommendationsSwitch = ({
  isNetworkingRecommendationsEnabled,
  disabled,
  handleConfirmation,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useI18n(["eventForm", "common"]);

  const handleNetworkRecommendationSwitch = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = e.target;
    if (checked) {
      dispatch(
        addDialogNotification({
          title: t("common:important.info"),
          confirmText: t("common:i.understand"),
          dismissText: t("common:button.cancel"),
          onConfirm: () => handleConfirmation(checked),
          message: t("common:networking.on.notification"),
          hideCloseButton: true,
          confirmTestId: "show-recommendations-confirm-dialog",
        }),
      );
      return;
    }
    handleConfirmation(checked);
  };

  const styles = useStyles();

  return (
    <Box className={styles.switchBox}>
      <Typography variant="body1">
        {t("eventForm:networking.recommendations")}
      </Typography>
      <Tooltip
        title={
          <>
            <Typography variant="body1" className={styles.tooltipText}>
              {t("eventForm:networking.recommendations.info.tooltip")}
            </Typography>
            <Typography variant="body1" className={styles.tooltipLinkText}>
              <Link href={t("url:networking.recommendation")} openInNewTab>
                {t("eventForm:networking.recommendations.info.linkText")}
              </Link>
            </Typography>
          </>
        }
        arrow
        placement="bottom"
        disableHoverListener={false}
        classes={{ tooltip: styles.tooltip }}
      >
        <InfoRounded color="primary" fontSize="small" data-testid="info-icon" />
      </Tooltip>
      <input
        type="checkbox"
        onChange={handleNetworkRecommendationSwitch}
        checked={isNetworkingRecommendationsEnabled}
        disabled={disabled}
        data-testid="network-recommendations-switch"
        className={styles.checkbox}
      />
    </Box>
  );
};
