import { ChangeEvent, useContext, useMemo, useState } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Checkbox } from "@remo-co/ui-core/src/components/Checkbox";
import { Form } from "@remo-co/ui-core/src/components/Form";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Switch } from "@remo-co/ui-core/src/components/Switch";
import { Accordion } from "@remo-co/ui-core/src/components/Accordion";
import { AccordionSummary } from "@remo-co/ui-core/src/components/AccordionSummary";
import { AccordionDetails } from "@remo-co/ui-core/src/components/AccordionDetails";
import { lightTheme } from "@remo-co/ui-core/src/theme/lightTheme";
import { isFormDirty } from "helpers/formHelper";
import { useI18n } from "i18n";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useStyles } from "./styles";
import { EditAssignedGuests } from "../EditAssignedGuests/EditAssignedGuests";

export interface RoomEditForm {
  tableName: string;
  updateAllFloors: boolean;
  lockEntry: boolean;
  lockExit: boolean;
  assignedGuests: string[];
}

interface Props {
  onSubmit: (form: RoomEditForm) => void;
  onCancel: () => void;
  initialForm?: RoomEditForm;
  isLoading: boolean;
}

export const initialFormValue = {
  tableName: "",
  updateAllFloors: false,
  lockEntry: false,
  lockExit: false,
  assignedGuests: [],
};

const LOCK_EXIT_NAME = "lockExit";

export const TableEditMenu = ({
  onSubmit,
  onCancel,
  initialForm = initialFormValue,
  isLoading = false,
}: Props): JSX.Element => {
  const styles = useStyles();
  const { track } = useContext(TRACKING_CONTEXT);
  const [form, setForm] = useState<RoomEditForm>(initialForm);
  const { tableName, updateAllFloors, lockExit, lockEntry } = form;
  const isSettingsFormDirty = useMemo(
    () => isFormDirty(form, initialForm),
    [form, initialForm],
  );

  const { t } = useI18n(["manageEvent"]);

  const handleSubmit = () => {
    onSubmit(form);
  };

  const hasAssignedGuests = initialForm.assignedGuests.length > 0;

  const onAssignedGuestsChange = (newGuests: string[]) => {
    setForm((oldForm) => ({ ...oldForm, assignedGuests: newGuests }));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForm((oldForm) => ({
      ...oldForm,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    setForm((oldForm) => ({
      ...oldForm,
      [event.target.name]: event.target.checked,
    }));
    if (event.target.name === LOCK_EXIT_NAME && event.target.checked) {
      track(Events.LOCK_TABLES_GUESTS_LOCKED_IN);
    }
  };

  // When lockEntry is disabled, we need to
  // disable lockExit
  const handleLockEntry = (event: ChangeEvent<HTMLInputElement>) => {
    const newLockEntryValue = event.target.checked;
    setForm((oldForm) => ({
      ...oldForm,
      lockEntry: newLockEntryValue,
      lockExit: newLockEntryValue ? lockExit : false,
    }));
    if (newLockEntryValue) {
      track(Events.LOCK_TABLES_TABLE_LOCKED);
    } else {
      track(Events.LOCK_TABLES_TABLE_UNLOCKED);
    }
  };

  return (
    <Container border className={styles.root}>
      <Form initialValues={form} onSubmit={handleSubmit}>
        <Typography variant="h3">{t("table.settings")}</Typography>
        <Container top="lg">
          <Input
            inputProps={{
              "data-testid": "table-name-input",
              onKeyDown: (event) => {
                event.stopPropagation();
              },
            }}
            fullWidth
            size="sm"
            onChange={handleChange}
            value={tableName}
            label={t("table.name")}
            name="tableName"
          />
        </Container>
        <FormControlLabel
          control={
            <Checkbox
              data-testid="update-all-floors-checkbox"
              name="updateAllFloors"
              checked={updateAllFloors}
              onChange={handleCheckBox}
            />
          }
          label={t("update.table.name.for.each.floor")}
        />
        <Container border flex column>
          <Accordion className={styles.accordion} expanded={lockEntry}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Container
                padding="xs"
                flex
                fullWidth
                alignItems="center"
                justifyContent="space-between"
                data-testid="lock-entry-switch"
              >
                <Typography>{t("lock.tables")}</Typography>
                <Switch
                  name="lockEntry"
                  checked={lockEntry}
                  onChange={handleLockEntry}
                />
              </Container>
            </AccordionSummary>
            <AccordionDetails>
              <Container
                padding="xs"
                fullWidth
                backgroundColor={lightTheme.palette.gray.light}
                flex
                column
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <FormControlLabel
                  data-testid="lock-exit-checkbox"
                  control={
                    <Checkbox
                      name={LOCK_EXIT_NAME}
                      checked={lockExit}
                      onChange={handleCheckBox}
                    />
                  }
                  label={t("lock.movement")}
                />
                <Typography variant="subtitle2">
                  {t("prevents.guests.from.leaving.this.table")}
                </Typography>
              </Container>
            </AccordionDetails>
          </Accordion>
        </Container>
        {hasAssignedGuests && (
          <EditAssignedGuests
            assignedGuests={initialForm.assignedGuests}
            onChange={onAssignedGuestsChange}
          />
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          gap={1}
          marginTop={2}
        >
          <Button onClick={onCancel} size="lg" color="gray" variant="secondary">
            {t("cancel")}
          </Button>
          <Button
            data-testid="submit"
            loading={isLoading}
            disabled={!isSettingsFormDirty}
            size="lg"
            color="blue"
            variant="primary"
            type="submit"
          >
            {t("submit")}
          </Button>
        </Box>
      </Form>
    </Container>
  );
};
