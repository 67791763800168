import { Ref, ChangeEventHandler } from "react";
import { useI18n } from "i18n";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { InputAdornment } from "@remo-co/ui-core/src/components/InputAdornment";
import { Search as SearchIcon } from "@remo-co/ui-core/src/icons/Search";
import useStyles from "./styles";

interface Props {
  inputRef: Ref<HTMLInputElement>;
  query?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const Search = ({ inputRef, query, onChange }: Props): JSX.Element => {
  const styles = useStyles();
  const { t } = useI18n();

  return (
    <Input
      ref={inputRef}
      id="user-search"
      placeholder={t("search.people")}
      inputEndAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
      fullWidth
      value={query || ""}
      onChange={onChange}
      multiline={false}
      className={styles.input}
      inputClassName={styles.inputClass}
    />
  );
};

export default Search;
