import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useBroadcastVideoActions } from "modules/broadcast/hooks/useBroadcastVideoActions";
import { useI18n } from "i18n";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { selectVideoURL } from "modules/broadcast/redux/selectors";
import { ActionButton } from "modules/actionButton";
import { ReactComponent as YouTubeIcon } from "./icons/youtube-icon.inline.svg";

interface Props {
  onClick(isOpen: boolean): void;
}

const ShareVideoButton = ({ onClick }: Props): JSX.Element => {
  const videoURL = useSelector(selectVideoURL);

  const { updateVideoURL } = useBroadcastVideoActions();
  const { t } = useI18n();

  const handleButtonClick = useCallback(() => {
    if (videoURL) {
      updateVideoURL(null);
      onClick(false);
    } else {
      onClick(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClick, videoURL]);

  return (
    <ActionButton
      title={videoURL ? t("share.video.title.off") : t("share.video.title.on")}
      label={t("button.share.video")}
      onClick={handleButtonClick}
      isActive={Boolean(videoURL)}
      isPopoverButton
      icon={
        <Container flex style={{ padding: "0 5px", transform: "scale(0.9)" }}>
          <YouTubeIcon />
        </Container>
      }
      darkMode
      tooltipPlacement="left"
    />
  );
};

export default ShareVideoButton;
