import React from "react";
import { SvgIcon, SvgIconProps } from "@remo-co/ui-core/src/components/SvgIcon";

const SendIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fill="#00BCDA"
      fillRule="evenodd"
      d="M3.291 3.809a.75.75 0 0 0-.976.996l3.093 6.945H13a.75.75 0 1 1 0 1.5H5.408l-3.093 6.945a.75.75 0 0 0 .976.996l19-8a.75.75 0 0 0 0-1.382l-19-8Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default React.memo(SendIcon);
