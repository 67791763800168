import { useCallback, useEffect, useState } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { useI18n } from "i18n";
import { getMapTemplateByType } from "modules/event/template";
import { Select } from "@remo-co/ui-core/src/components/Select";
import {
  ACTIVE_THEMES,
  DEFAULT_FLOOR_PLAN,
  FLOOR_PLAN_THEMES,
} from "modules/customFloorPlan";
import { IMapTemplate, ITheater } from "types/theater";
import { getDefaultTheme } from "../../utils";
import { useStyles } from "./styles";
import { getThemeIcon } from "./utils";

interface Props {
  onChange: (template: IMapTemplate, theme: string) => void;
  setDisplayedTheme: (theme: string) => void;
  currentTheme: string;
  displayedTheme: string;
  theaterData: ITheater;
  templates: IMapTemplate[];
}

const EventThemeSelector = ({
  onChange,
  setDisplayedTheme,
  displayedTheme,
  currentTheme,
  theaterData,
  templates,
}: Props): JSX.Element | null => {
  const [isInitialThemeLoaded, setThemeLoaded] = useState(false);
  const theaterTemplateData = getMapTemplateByType(theaterData.template);

  const getSelectedTemplate = (templateCode: string) =>
    templates.find((template) => template.code === templateCode);
  const theaterCode = theaterTemplateData
    ? theaterTemplateData.code
    : DEFAULT_FLOOR_PLAN;
  const selectedTemplate = getSelectedTemplate(theaterCode);
  const { t } = useI18n(["common", "eventForm"]);
  const styles = useStyles();

  const getDefaultSelectTheme = () => {
    if (!currentTheme) {
      setThemeLoaded(true);

      return getDefaultTheme(selectedTemplate);
    }

    return currentTheme;
  };

  useEffect(() => {
    const template = selectedTemplate;
    const selectedTheme = getDefaultSelectTheme();

    if (template && !template.isPrivate) {
      onChange(template, selectedTheme);
      setDisplayedTheme(selectedTheme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialThemeLoaded, selectedTemplate]);

  const countTemplatesInTheme = useCallback(
    (themeToCheck: string) => {
      const filteredTemplates = templates.filter(
        (template) => template.floorPlanType === themeToCheck,
      );

      if (themeToCheck === FLOOR_PLAN_THEMES.CLASSIC) {
        return filteredTemplates.reduce(
          (runningCount, template) =>
            runningCount +
            (template.themes ? Object.keys(template.themes).length : 1),
          0,
        );
      }

      return filteredTemplates.length;
    },
    [templates],
  );

  return (
    <Box className={styles.container}>
      <Select
        fullWidth
        value={displayedTheme}
        onChange={setDisplayedTheme}
        name="theme"
        label={t("eventForm:floor.plan.theme")}
        options={ACTIVE_THEMES.map((theme: string) => ({
          label: t(`eventForm:type.${theme}`),
          description: t("eventForm:floor.plan.count", {
            key: countTemplatesInTheme(theme),
          }),
          leftAdornment: <img src={getThemeIcon(theme)} alt={theme} />,
          value: theme,
        }))}
        inputTestId="theme-select-field"
      />
    </Box>
  );
};

export default EventThemeSelector;
