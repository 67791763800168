import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  inputFields: {
    paddingTop: "1rem",
  },
  startTime: {
    flex: "1",
    paddingRight: "1.25rem",
  },
  endTime: {
    flex: "1",
  },
  dateTimePickerRow: {
    display: "flex",
  },
  dateTimePicker: {
    paddingBottom: "0",
    marginTop: "5px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.events.bluePrimary} !important`,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.events.bluePrimary} !important`,
        borderWidth: "1px",
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.events.grayPrimary} !important`,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "0 10px",
      height: "55px",
      "&:disabled": {
        color: "#9E9E9E",
        cursor: "not-allowed",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.events.grayPrimary}`,
      boxShadow: "4px 4px 4px 0px #0C38911A",
      transition: "all 0.2s ease-in-out",
    },
  },

  dateTimeDescriptionHelper: {
    marginTop: "10px",
  },
  dateTimeDescription: {
    color: theme.palette.events.bluePrimary,
    fontWeight: 500,
    fontSize: "10px",
  },
  eventImageContainer: {
    maxWidth: "400px",
    marginTop: "2.5rem",
    paddingBottom: "1rem",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: `${theme.palette.white} 0% 0% no-repeat padding-box`,
    boxShadow: "4px 4px 4px 0px #0C38911A",
    borderRadius: "10px",
  },
  eventImage: {
    width: "100%",
    flex: "1 0 100%",
    maxWidth: "780px",
    borderRadius: "10px 10px 0 0",
  },
  uploader: {
    flex: "1 0 45%",
    width: "120px",
    textAlign: "left",
    marginTop: "1.25rem",
    marginLeft: "1.25rem",
    color: theme.palette.events.blueSecondary,
  },
  uploadCaption: {
    flex: "1 0 45%",
    width: "120px",
    textAlign: "left",
    marginTop: "1.25rem",
    marginLeft: "1.25rem",
    maxWidth: "120px",
    marginRight: "1.25rem",
  },
  radioButton: {
    color: `${theme.palette.events.grayTertiary} !important`,
    "&.Mui-checked": {
      color: `${theme.palette.events.blueSecondary} !important`,
    },
  },
}));

export default useStyles;
