import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  searchInput: {
    backgroundColor: "var(--White-op-010)",
    color: theme.palette.text.primary,
    padding: "10px",
    boxShadow: "none",
    borderRadius: "5px",
    height: "40px !important",
    "& .MuiInputAdornment-root": {
      marginLeft: "10px",
      color: "var(--Grey)",
    },
    "& > div": {
      height: "100%",
    },
    "& fieldset": {
      borderRadius: "20px",
    },
  },
  inputClass: {
    margin: "8px",
    padding: 0,
  },
}));

export default useStyles;
