import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  menuItem: {
    whiteSpace: "normal",
    padding: "5px 10px",
    lineHeight: "1rem",
    fontWeight: 500,
    height: "auto",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    opacity: "1",
  },
  menuItemHeadline: {
    color: "#aaa",
    fontWeight: 400,
    fontSize: "0.8rem",
    maxWidth: "100%",
    minWidth: "15rem",
    display: "table-caption",
  },
  icon: {
    height: "24px",
    width: "24px",
  },
}));
