import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { Theme } from "@remo-co/ui-core/src/types";

export const useStyles = makeStyles<Theme>((theme) => ({
  filterTagsBox: {
    paddingBottom: 20,
    width: "50%",
  },
  activeTab: {
    background: theme.palette.events.bluePrimary,
    color: theme.palette.white,
  },
  remoFloorPlans: {
    borderRadius: "20px 0px 0px 20px",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: 400,
    boxShadow: "4px 4px 4px 0px #0C38911A",
  },
  customFloorPlans: {
    borderRadius: "0px 20px 20px 0px",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: 400,
    boxShadow: "4px 4px 4px 0px #0C38911A",
  },
  filterText: {
    textTransform: "uppercase",
    marginBottom: "10px",
    fontWeight: "500 !important",
    fontSize: "16px !important",
  },
  header: {
    marginBottom: "16px",
  },
  themeSelectorBox: {
    display: "flex",
    gap: "20px",
  },
}));

export default useStyles;
