import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1.5),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  selectedType: {
    border: `1px solid ${theme.palette.events.bluePrimary}`,
    background: theme.palette.events.bluePrimary,
    color: theme.palette.white,
  },
  type: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "225px",
    height: "38px",
    textTransform: "uppercase",
    border: `1px solid ${theme.palette.white}`,
    boxShadow: "4px 4px 4px 0px #0C38911A",
  },
  leftSideType: {
    borderRadius: "20px 0px 0px 20px",
  },
  rightSideType: {
    borderRadius: "0px 20px 20px 0px",
  },
  typeLabel: {
    marginLeft: theme.spacing(0.5),
  },
}));
